import { Component, Input } from '@angular/core';
import { Pagination } from '@app/data/models/pagination.model';

@Component({
  selector: 'ts-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent {
  @Input() pagination: Pagination;
  @Input() collectionSize: number;
  @Input() alwaysShow = false;

  constructor() { }

  get show(): boolean {
    return this.alwaysShow ? true : this.collectionSize > this.pagination.pageSize;
  }
}
