import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ResolveStart } from '@angular/router';
import { filter, map, mergeMap, delay } from 'rxjs/operators';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@env/environment';
import { Logger } from '@app/core';
import 'hammerjs';
import { SpinnerService } from './shared/ticket-spicket/spinner.service';
import { HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public spinnerMsg = 'Loading';
  public isLoading = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    // do not remove the analytics injection, even if the call in ngOnInit() is removed
    // this injection initializes page tracking through the router
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private cookieService: CookieService,
    public spinner: SpinnerService,
    public _modal: NgbModal,
    private loadingBar: LoadingBarService) {
    Sentry.configureScope(function (scope: Sentry.Scope) {
      scope.setTag('app-version', environment.version);
    });
    this.loadingBar.start();
    this.spinner.message.asObservable().pipe(
      delay(0)
    ).subscribe(msg => {
      this.spinnerMsg = msg;
    });
    this.spinner.loading.subscribe(res => {
      this.isLoading = res;
    });
  }
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this._modal.dismissAll();
  }
  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    this.angulartics2GoogleAnalytics.eventTrack('Launch', { category: 'App initialized', label: environment.version });

    const onResolveStart = this.router.events.pipe(filter(event => event instanceof ResolveStart));
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // subscribe the onResolveStart to start the spinner
    // NOTE: ActivatedRoute.data is not available here
    onResolveStart.subscribe(() => {
      this.spinner.show();
    });

    // once the navigation completes, check to see if the spinner should be stopped
    // if the Route data object does not include showSpinner: true, then stop the spinner
    onNavigationEnd.pipe(
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe((data) => {
      // Set deep link cookie
      if (
        this.activatedRoute.snapshot.queryParams['app'] === '1'
        && (
          this.activatedRoute.snapshot.queryParams['app_platform'] === 'ios'
          || this.activatedRoute.snapshot.queryParams['app_platform'] === 'android'
        )
      ) {
        this.cookieService.set('isMobileApp', 'true');
      }

      const showSpinner = data['showSpinner'] || false;
      if (!showSpinner) {
        this.spinner.hide();
      }
    });


    this.loadingBar.complete();

  }

}
