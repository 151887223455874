import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CartService } from '@app/data/services/cart.service';
import { Router } from '@angular/router';
import { NgxBraintreeComponent } from '@app/cart/braintree/ngx-braintree.component';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit, OnDestroy {

  @ViewChild(NgxBraintreeComponent, { static : false }) paymentMethod: NgxBraintreeComponent;

  enablePayButton = false;
  interval: any;

  constructor(
    public cart: CartService,
    private _router: Router,
    private _messageService: MessageService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.cart.expiration.extendExpiration();
    this.interval = setInterval(() => {
      if ( this.cart.countdown === '02:00' ) {
        this.promptForTime();
      } else if ( this.cart.countdown === '00:00' ) {
        this.activeModal.dismiss(false);
        this._router.navigate([this.cart.cartRoute]);
      }
    }, 1000);
  }


  ngOnDestroy() {
    clearInterval(this.interval);
  }

  onPaymentStatus () {
    this._router.navigate(['/cart/confirmation']);
  }

  onPayButtonStatus (event: boolean) {
    this.enablePayButton = event;
  }

  checkout() {
    this.paymentMethod.pay();
  }

  completePurchase() {

    this.cart.checkout(null).subscribe(() => {
      this._router.navigate(['/cart/confirmation']);
    }, (error) => console.error(error)
    );
  }

  public promptForTime() {
    if (this.cart.expiration.canExtend()) {
      this._messageService.ConfirmYesNo('Are you still entering your payment information? Click \'Yes\' to extend your session.').then(() => {
        this.cart.expiration.extendExpiration();
      });
    }
  }

  public close() {
    this.activeModal.dismiss(false);
  }

}
