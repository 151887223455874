import { IDeserializable } from '@app/data/models/deserializable.interface';

export class AccountSettings implements IDeserializable {

  facebookURI: string;
  instagramURI: string;
  snapchatURI: string;
  twitterURI: string;

  logoURI: string;
  logo2xURI: string;
  coverArtURI: string;

  primaryColor: string;
  primaryActive: string;
  primaryHover: string;
  primaryText: string;
  secondaryColor: string;
  secondaryActive: string;
  secondaryHover: string;
  secondaryText: string;

  timezone: string;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

}
