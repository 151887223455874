import { Component, OnInit, ViewChild, } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { AgencyService } from '@app/data/services/agency.service';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { Agency } from '@app/data/models/agency.model';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { RenewalService } from '@app/data/services/renewal.service';
import { PassService } from '@app/data/services/pass.service';
import { SeasonTicketExchange } from '@app/data/models/renewal/season-ticket-exchange';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgencySeasonTicketSeatmapComponent } from './seatmap/season-ticket-seatmap.component';
import { Event } from '@app/data/models/event.model';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { NgxBraintreeRenewalComponent } from '@app/cart/braintree/ngx-braintree-exchange.component';
import { ClerkService } from '@app/core/authentication/clerk.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-season-ticket-exchange',
  templateUrl: './season-ticket-exchange.component.html',
  styleUrls: ['./season-ticket-exchange.component.scss']
})
export class SeasonTicketExchangeComponent implements OnInit {

  @ViewChild(NgxBraintreeRenewalComponent, { static : false }) paymentMethod: NgxBraintreeRenewalComponent;

  public agency: Agency = new Agency();
  public pass: GatePass = new GatePass();
  public event: Event;

  public seats: SeasonTicketExchange[] = new Array<SeasonTicketExchange>();

  public isLoading = true;
  public gatePassUUID: string;
  public purchaseUrl: string;
  public eventsUrl: string = environment.eventsUrl; // This is the new Events URL on HTT
  public redirectUrl = '';

  enablePayButton = false;

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private _passService: PassService,
    public _agencyService: AgencyService,
    public renewalService: RenewalService,
    private _modal: NgbModal,
    private _toastr: ToastrService,
    private _messageService: MessageService,
    private _clerkService: ClerkService,
  ) {
    this.redirectUrl = location.href + this.router.url;
  }

  ngOnInit() {

    this._clerkService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        // If the user is not authenticated, send them to Unified login
        // with a redirect back to here
        const encodedRedirectUrl = encodeURIComponent(this.redirectUrl);
        const loginUrl = `${this.eventsUrl}/sign-in#/?redirect_url=${encodedRedirectUrl}`;
        window.location.href = loginUrl;
      }
    });

    this.agency = this._agencyService.agency;

    this.route.params.pipe (
      map((params) => this.gatePassUUID = params['pass-id']),
      switchMap((gatePassUUID) => this.renewalService.getSeasonTicketEvents(gatePassUUID)),
      map((events) => this.event = events[0]),
      switchMap(() => this._passService.getPasses())
    ).subscribe(() => {
      this.loadTickets();

      this.purchaseUrl = `/agency/${this.agency.uuid}/season/${this.gatePassUUID}`;

    }, () => {
    });

    this.isLoading = false;

  }

  public loadTickets() {
    this.renewalService.getSeasonTicketEvents(this.gatePassUUID).pipe (
      map((events) => this.event = events[0]),
      switchMap(() => this._passService.getPasses())
    ).subscribe((passes) => {
      const consumerPass = passes.find((pass) => pass.gatePass.uuid === this.gatePassUUID);
      this.pass = consumerPass.gatePass;
      this.seats = _.orderBy(consumerPass.consumers.map((pass) => new SeasonTicketExchange().deserialize(pass)), 'seatKey');
    }, () => {
    });
  }

  public showSeatMap() {
    const modalRef = this._modal.open(AgencySeasonTicketSeatmapComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.gatePass = this.pass;
    modalRef.componentInstance.ticketPrices = this.pass.getRESVTicketPrices();
  }

  public toggleSeat(seat: SeasonTicketExchange) {

    if (seat.isExchanged()) {
      this._messageService.ConfirmYesNo('You have already reserved a seat to exchange for this one.  This will release the seat for other people to select.  Are you sure?', 'Release Seat?').then(() => {
        this.renewalService.toggleSeat(seat);
      });
    } else {
      this.renewalService.toggleSeat(seat);
    }
  }

  onPaymentStatus () {
    this.renewalService.clearSelected();
    this.loadTickets();
    this._toastr.success('Your Seats have been exchanged!');
  }

  onPayButtonStatus (event: boolean) {
    this.enablePayButton = event;
  }


  public checkout() {

    this._messageService.ConfirmYesNo('Are you sure you want to upgrade these season tickets?  Clicking Yes will reserve your new seats and make your existing seats available for someone else to reserve!', 'Upgrade Seats?').then(() => {
      this.paymentMethod.pay(this.pass.uuid);
    });

  }

  public exchangeSeats() {

    this._messageService.ConfirmYesNo('Are you sure you want to exchange these season tickets?  Clicking Yes will reserve your new seats and make your existing seats available for someone else to reserve!', 'Exchange Seats?').then(() => {
      this.renewalService.exchangeSeats(this.gatePassUUID).subscribe(() => {
        this.renewalService.clearSelected();
        this.loadTickets();
        this._toastr.success('Your Seats have been exchanged!');
      }, () => {
        this.loadTickets();
        this._toastr.error('An error occurred, please try again.  If this error persiste, please contact customer support.');
      });
    });

  }

  public allowPurchase(): boolean {
    return !(this.gatePassUUID === '0f5f33fc-0f9a-40e6-a20c-d38e9c188ce2' || this.gatePassUUID === 'dbe8e320-f378-454c-ae96-6bca6d2455cd');
  }

  public getTotalPaid(): number {
    return this.renewalService.getTotalPaid();
  }

  public getTotalExchange(): number {
    return this.renewalService.getTotalExchange();
  }

  public getUpgradePrice(): number {
    return this.renewalService.getUpgradePrice();
  }

  public canExchangeSeats(): boolean {
    return this.renewalService.hasExchangedSeats() && this.getUpgradePrice() <= 0;
  }

}
