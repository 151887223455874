import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    return new DefaultUrlSerializer().parse(url);
  }

  serialize(tree: UrlTree): any {
    let path = new DefaultUrlSerializer().serialize(tree);

    // Do not serialize square brackets in Clerk JWT transfer for dev instances
    path = path.replace(/(#__clerk_db_jwt)%5B(.+)%5D/g, '$1[$2]');

    return path;
  }
}
