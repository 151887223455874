<div style="overflow:hidden;" *ngIf="pass">
    <div class="container bg-white pb-4" style="position:relative;z-index:100;">
        <div class="coverArt view allAccess">
            <div class="colorOverlay"></div>
            <div class="details">
                <div class="row align-items-center">
                    <div class="col-12">
                        <div class="logo" [ngStyle]="{'background-image': 'url(' + pass.gatePass.agency.settings?.logoURI + ')'}"></div>
                        <div class="float-left">
                            <h5 class="name">{{ pass.gatePass.name }}</h5>
                            <p class="description">{{ pass.gatePass.description }}</p>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                </div>
            </div>
        </div>
        <nav aria-label="breadcrumb" class="d-none d-sm-block">
            <ol class="breadcrumb">
                <li class="breadcrumb-item font-sm">
                    <a href="{{eventsUrl}}/passes" routerLinkActive="active">All Passes</a>
                </li>
                <li class="breadcrumb-item active font-sm" aria-current="page">{{ pass.gatePass.name }}</li>
            </ol>
        </nav>
        <a href="{{eventsUrl}}/passes">
            <button class="btn btn-sm btn-light btn-block d-sm-none d-block text-center my-3">
                <i class="fa fa-arrow-left mr-2"></i>Back to All Passes
            </button>
        </a>
            <mat-tab-group class="d-block d-md-none" mat-align-tabs="center">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        <i class="fa fa-fw mr-2 fa-calendar"></i>Eligible Events
                    </div>
                </ng-template>
                <button mdbRippleRadius type="button" class="btn btn-outline-secondary btn-sm mt-2 text-center w-100" [ngClass]="{'active': filter.hasActivityFilter() || filter.hasAgencyFilter()}" (click)="showFilter()">
                    Filter Events
                    <i class="fa fa-filter"></i>
                    <i class="fa fa-xs pt-1 fa-check" style="left: -5px; position: relative; margin-right: -5px;" *ngIf="filter.hasActivityFilter() || filter.hasAgencyFilter()"></i>
                </button>
                <div class="mt-2 mb-3">
                    <div class="font-sm text-muted pb-3">Tap on an event below to view available ticket options.</div>
                    <ts-ticket-legend></ts-ticket-legend>
                    <ts-ticket-list-item [ticket]="ticket" *ngFor="let ticket of filteredEvents$ | async"></ts-ticket-list-item>
                    <ts-no-results *ngIf="(filteredEvents$ | async).length === 0" message="eligible events"></ts-no-results>
                </div>
            </mat-tab>
            <mat-tab *ngIf="!pass.gatePass.isPunchPass">
                <ng-template mat-tab-label>
                    <div>
                        <i class="fas fa-fw mr-2 fa-users"></i>Pass Holders
                    </div>
                </ng-template>
                <div class="mt-2 mb-3">
                    <div class="font-sm text-muted pb-3">Tap on a pass holder below to assign a name or transfer (if available) each pass.</div>
                    <div *ngFor="let consumer of pass.consumers;">
                        <ts-consumer-pass-list-item [consumer]="consumer"></ts-consumer-pass-list-item>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div class="d-none d-md-block">
            <div class="mb-3" *ngIf="!pass.gatePass.isPunchPass">
                <ts-section-header>Pass Holders</ts-section-header>
                <div class="font-sm text-muted pb-3">Tap on a pass holder below to assign a name or transfer (if available) each pass.</div>
                <!-- <div class="font-sm text-muted pb-3">Tap on a pass holder below to assign a name to view pass holder details, each pass, or to make a one time transfer.</div> -->
                <div class="row">
                    <div class="col-12 col-md-4" *ngFor="let consumer of pass.consumers;">
                        <ts-consumer-pass-list-item [consumer]="consumer"></ts-consumer-pass-list-item>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <ts-section-header>
                    Eligible Events
                    <div class="actions">
                        <div class="float-right">
                            <button mdbRippleRadius type="button" class="btn btn-outline-secondary btn-sm" [ngClass]="{'active': filter.hasActivityFilter() || filter.hasAgencyFilter()}" (click)="showFilter()">
                                Filter Events
                                <i class="fa fa-filter"></i>
                                <i class="fa fa-xs pt-1 fa-check" style="left: -5px; position: relative; margin-right: -5px;" *ngIf="filter.hasActivityFilter() || filter.hasAgencyFilter()"></i>
                            </button>
                        </div>
                    </div>
                </ts-section-header>
                <div class="font-sm text-muted pb-3">Tap on an event below to view available ticket options.</div>
                <ts-ticket-legend></ts-ticket-legend>
                <ts-ticket-list-item [ticket]="ticket" *ngFor="let ticket of filteredEvents$ | async"></ts-ticket-list-item>
                <ts-no-results *ngIf="(filteredEvents$ | async).length === 0" message="eligible events"></ts-no-results>
            </div>
        </div>
        <ts-agency-style [settings]="pass.gatePass.agency.settings"></ts-agency-style>
    </div>
</div>
