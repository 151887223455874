/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

const version = environment.version;

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = window.console.warn = window.console.info = window.console.error = function () { };
  }
}

Sentry.init({
  release: 'fan-web@' + version,
  environment: environment.production ? 'production' : 'dev',
  dsn: 'https://4158fb897cf24a21aab1e9499981f605@o1116650.ingest.sentry.io/6150345',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost'
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.production ? 0.05 : 1,
  sampleRate: environment.production ? 0.05 : 1,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
