import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TransferService } from '@app/data/services/transfer.service';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'ts-incoming-transfer-bottom-sheet',
  templateUrl: './incoming-transfer-bottom-sheet.component.html'
})
export class IncomingTransferBottomSheet {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<IncomingTransferBottomSheet>,
    private _transferService: TransferService,
    private _userAdmissionService: UserAdmissionService
  ) {}

  public acceptAllTransfers() {
    const transfer$: Observable<any>[] = new Array<Observable<any>>();

    this._transferService.getTransfers().subscribe(() => {
      const transfers = this._transferService.currentTransfers.incoming;
      transfers.forEach((transfer) => {
        transfer$.push(this._transferService.acceptTransfer(transfer));
        // .subscribe(() => {
        // });
      });

      forkJoin(transfer$).subscribe(() => {
        this._userAdmissionService.loadAdmission().subscribe();
        this.bottomSheetRef.dismiss('accepted');
      }, () => {
        this.bottomSheetRef.dismiss('error');
      });

    });
  }

  public declineAllTransfers() {
    const transfer$: Observable<any>[] = new Array<Observable<any>>();
    this._transferService.getTransfers().subscribe(() => {
      const transfers = this._transferService.currentTransfers.incoming;
      transfers.forEach((transfer) => {
        transfer$.push(this._transferService.declineTransfer(transfer));
        // .subscribe(() => {
        // });
      });

      forkJoin(transfer$).subscribe(() => {
        this._userAdmissionService.loadAdmission().subscribe();
        this.bottomSheetRef.dismiss('declined');
      }, () => {
        this.bottomSheetRef.dismiss('error');
      });

    });
  }

  openLink(event: MouseEvent, value: string) {
    this.bottomSheetRef.dismiss(value);
  }

}
