import { Event } from '@app/data/models/event.model';
import * as _ from 'lodash';
import * as moment from 'moment';

export class EventFilter {

  private _events: Array<Event> = new Array<Event>();

  // public activities: Array<Activity> = new Array<Activity>();
  // public selectedActivities: Array<Activity> = new Array<Activity>();

  public agencies: Array<{ name: string; uuid: string }> = new Array<{ name: string; uuid: string }>();
  public activities: Array<string> = new Array<string>();
  public selectedActivities: Array<string> = new Array<string>();
  public selectedAgencies: Array<{ name: string; uuid: string }> = new Array<{ name: string; uuid: string }>();
  public startDate: Date = null;
  public endDate: Date = null;

  public isLoaded = false;

  constructor() {
  }

  /**
   * setEvents
   *
   * this sets the _events array and then uses that array to extrac the unique set of activities for this
   * list of events.  By default, it selects all of the activities so that the getEvents returns all of the events
   *
   * @param events Array of Event objects
   *
   */
  public setEvents(events: Event[]) {

    this.isLoaded = false;
    this._events = events;

    // sets the activities based on the activities in the events collection
    this.activities = _.uniq(this._events.map((event) => event.activity.displayName));
    this.activities = _.orderBy(this.activities);

    this.agencies = _.uniqBy(this._events.map((event) => ({ name: event.agency.name, uuid: event.agency.uuid })), 'uuid');
    this.agencies = _.orderBy(this.agencies, ['name']);

    this.isLoaded = true;

  }

  private _filterByActivity(): Event[] {
    return this._events.filter((event) => {

      if (this.hasActivityFilter()) {
        return this.selectedActivities.find((activity) => activity === event.activity.displayName);
      } else {
        return event;
      }

    });
  }

  /**
   * getEvents
   *
   * @returns array of events based on the selected activities, start date and end date specified in the filter
   *
   */
  public getEvents(): Event[] {

    return this._events
      .filter((event) => {
        if (this.hasAgencyFilter()) {
          return this.selectedAgencies.find((agency) => agency.uuid === event.agency.uuid);
        } else {
          return event;
        }
      })
      .filter((event) => {

        if (this.hasActivityFilter()) {
          return this.selectedActivities.find((activity) => activity === event.activity.displayName);
        } else {
          return event;
        }

      }).filter((event) => {

        if (this.hasDateFilter()) {
          // if both dates are filled in, check to see if the start date is between the filter
          if (this.startDate !== null && this.endDate !== null) {
            return moment(event.dateStart).isBetween(moment(this.startDate).startOf('day'), moment(this.endDate).endOf('day'));
          } else {

            // if only the start date is provided, event start date is after the filter start date
            if (this.startDate !== null) {
              return moment(event.dateStart).isAfter(moment(this.startDate).startOf('day'));
            }

            // if only the end date is provided, event start date is before the filter end date
            // * using the end of day to include the entire day
            if (this.endDate !== null) {
              return moment(event.dateStart).isBefore(moment(this.endDate).endOf('day'));
            }
          }
        }

        return event;


      });

  }

  public getEventDates(): Date[] {
    return _.uniq(this._filterByActivity().map((event) => moment(event.dateStart).startOf('day').toDate()));
  }

  /**
   * _getActivityIndex
   *
   * @param selected: Activity
   * @returns number - index for the given activity in the selectedActivities collection
   *
   */
  private _getActivityIndex(selected: string): number {
    return this.selectedActivities.findIndex((activity) => activity === selected);
  }

  /**
   *
   * isActivitySelected
   *
   * @param activity
   * @returns boolean - true if the given activity has been selected
   *
   */
  public isActivitySelected(activity: string): boolean {
    return this._getActivityIndex(activity) >= 0;
  }

  /**
   * toggleActivity - adds/removes the given activity from the selectedActivities collection
   *
   * @param activity
   *
   */
  public toggleActivity(activity: string) {

    // first, check to see if the activity are in the selected collection
    const index = this._getActivityIndex(activity);

    if (index >= 0) {
      // if the activity was found, remove it
      this.selectedActivities.splice(index, 1);
    } else {
      // Activity was not already in the collection, so add it
      this.selectedActivities.push(activity);
    }

  }

  public toggleAgency(agency: { name: string; uuid: string }) {
    const index = this.selectedAgencies.findIndex(a => a.uuid === agency.uuid);
    if (index >= 0) {
      this.selectedAgencies.splice(index, 1);
    } else {
      this.selectedAgencies.push(agency);
    }
  }

  public isAgencySelected(agency: { name: string; uuid: string }): boolean {
    return this.selectedAgencies.findIndex(a => a.uuid === agency.uuid) >= 0;
  }

  /**
   * selectAllActivities()
   *
   * removes all of the selected activities
   */
  public selectAllActivities() {
    this.selectedActivities = new Array<string>();
  }

  public isAllActivitiesSelected(): boolean {
    return this.selectedActivities.length === 0;
  }

  public selectAllAgencies() {
    this.selectedAgencies = new Array<{ name: string; uuid: string }>();
  }

  public isAllAgenciesSelected(): boolean {
    return this.selectedAgencies.length === 0;
  }

  public setStartDate(date: any = null) {
    if (date === null) {
      this.startDate = null;
    }
    this.startDate = moment(date).startOf('day').toDate();
  }

  public setEndDate(date: any = null) {
    if (date === null) {
      this.endDate = null;
    }
    this.endDate = moment(date).endOf('day').toDate();
  }

  public clearStartDate() {
    this.setStartDate();
  }

  public clearEndDate() {
    this.setEndDate();
  }

  /**
   * hasActivityFilter()
   *
   * @returns true if the selected activities collection is populated
   */
  public hasActivityFilter(): boolean {
    return this.selectedActivities.length > 0;
  }

  /**
   * hasDateFilter()
   *
   * @returns true if the date filter attribute is set
   */
  public hasDateFilter(): boolean {
    return this.startDate !== null || this.endDate !== null;
  }

  /**
   * hasFilter()
   *
   * @returns boolean - true if any of the filter attributes have values
   *
   */
  public hasFilter(): boolean {
    return this.hasActivityFilter() || this.hasAgencyFilter();
  }

  /**
   * hasAgencyFilter()
   *
   * @returns true if the selected agencies collection is populated
   */
  public hasAgencyFilter(): boolean {
    return this.selectedAgencies.length > 0;
  }

  public clearFilters() {
    this.selectAllActivities();
    this.selectAllAgencies();
  }
}
