import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as Sentry from '@sentry/angular';
import { ClerkService } from './clerk.service';

export interface Credentials {
  username?: string;
  token?: string;
}

export interface LoginContext {
  email: string;
  password: string;
  remember?: boolean;
}

export interface ResetContext {
  email: string;
}

export interface RegistrationContext {
  email: string;
  nameFirst: string;
  nameLast: string;
  password: string;
  passwordConfirm: string;
}

export interface SignUpContext {
  email: string;
  nameFirst: string;
  nameLast: string;
  password: string;
  passwordConfirm: string;
  verification_confirmation: string;
}

export interface ChangePasswordContext {
  token: string;
  password: string;
  passwordConfirm: string;
}

const credentialsKey = 'credentials';
const jwtHelper = new JwtHelperService();

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  public _credentials: Credentials | null;
  public httpOptions = {};

  constructor(
    private _clerkService: ClerkService

  ) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);

    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      // remove the token if it has expired
      if (jwtHelper.isTokenExpired(this._credentials.token)) {
        this._credentials.token = null;
      }
    }
  }



  /**
   * returns the authentication from the credentials object, which is used to pass to the
   */
  static getToken(): string {
    const savedCredentials: any = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      return JSON.parse(savedCredentials).token;
    }
    return null;
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this._clerkService.signOut();
    Sentry.configureScope(scope => scope.setUser(null));
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {

    const loggedIn = this._clerkService.user$ !== null || this._clerkService.user$ !== undefined;
    return loggedIn;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {

    return this._credentials;
  }

}
