import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Agency } from '@app/data/models/agency.model';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { Event } from '@app/data/models/event.model';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { AgencySearchResult } from '@app/data/models/agency-search.model';
import * as _ from 'lodash';
import { AgencyGeneric } from '@app/data/models/agency-generic.model';
import { EventFilter } from '../models/events/event-filter.model';
import { HttpFanwebClient } from '@app/core/http/http-nest.service';
import { Bundle } from '../models/bundle.model';

@Injectable()
export class AgencyService {

  public agency: Agency;
  public isLoading = true;
  public embedded = false;

  // This is used to store and share the last selected agency or generic school name
  public agencyName: string;

  public basePath = 'agencies';

  public eventFilter: EventFilter = new EventFilter();

  constructor (
    private _http: HttpClient,
    private _httpFanweb: HttpFanwebClient,
  ) {
    this.agency = new Agency();
  }

  /**
   * searches for agencies that match the given text string
   *
   * @param text : the search string
   * @returns Observable array of Agency objects
   */
  searchAgency(text: string): Observable<AgencySearchResult[]> {
    const url = `${this.basePath}/search`;
    const params = new HttpParams().append('existing', 'true').append('name', text);
    return this._http.get<AgencySearchResult[]>(url, { params: params }).pipe(
      map((agencies) => agencies.map((agency) => new AgencySearchResult().deserialize(agency)))
    );
  }

  /**
   * returns a list of Agency objects
   *
   * @returns Observable array of Agency objects
   */
  getAgencies(): Observable<Agency[]> {
    return this._http.get<Agency[]>(this.basePath).pipe(
      map((agencies) => agencies.map((agency) => new Agency().deserialize(agency)))
    );
  }

  /**
   * returns a single Agency based on the provided UUID
   *
   * @param uuid : the UUID for a single Agency
   * @returns Observable array of Agency objects
   *
   */
  getAgency(uuid: string): Observable<Agency> {
    this.isLoading = true;

    // reset the agency object first
    this.agency = new Agency();

    const url = `${this.basePath}/${uuid}`;

    return this._http.get<Agency>(url).pipe(
      map((agency) => {
        this.eventFilter = new EventFilter();
        return this.agency = new Agency().deserialize(agency);
      }),
      tap((agency) => {
        localStorage.setItem('lastAgencyUUID', agency.uuid);
        localStorage.setItem('lastAgencyName', agency.name);
        this.agencyName = agency.name;
      }),
      tap(() => this.isLoading = false)
    );

  }

  /**
   * returns a single Agency based on the provided UUID
   *
   * @param uuid : the UUID for a single Agency
   * @returns Observable array of Agency objects
   *
   */
  getGenericSchool(id: string): Observable<AgencyGeneric> {

    const url = `${this.basePath}/search/${id}`;

    return this._http.get<AgencyGeneric>(url).pipe(
      map((agency) => new AgencyGeneric().deserialize(agency)),
      tap((agency) => {
        localStorage.setItem('lastAgencyUUID', agency.uuid);
        localStorage.setItem('lastAgencyName', agency.name);
        this.agencyName = agency.name;
      }),
    );

  }

  /**
   * Returns the list of Events for a given Agency
   *
   * @param uuid : the UUID for a single Agency
   * @returns Observable array of Event objects
   */
  getEvents(uuid: string): Observable<Event[]> {

    const url = `fans/${this.basePath}/${uuid}/events`;

    // if there are already events under the agency, the we're not loading
    if (!this.hasEvents()) {
      this.agency.isLoadingEvents = true;
    }
    return this._http.get<Event[]>(url).pipe(
      map((events) => this.agency.events = _.sortBy(events.map((event) => new Event().deserialize(event)), ['dateStart'])),
      tap((events) => this.eventFilter.setEvents(events)),
      tap(() => this.agency.isLoadingEvents = false)
    );

  }

  hasEvents(): boolean {
    return this.agency.events.length > 0;
  }

  hasFilteredEvents(): boolean {
    return this.filterEvents().length > 0;
  }

  filterEvents(): Event[] {
    return this.eventFilter.getEvents();
    // if (!this.hasEventFilter()) {
    //     return events;
    // } else {
    //     return events.filter((event) => this.eventFilter.findIndex((activity) => activity === event.activity.displayName) >= 0);
    // }
  }

  hasEventFilter(): boolean {
    return this.eventFilter.hasActivityFilter();
  }

  clearEventFilter() {
    this.eventFilter.selectAllActivities();
  }

  /**
   * Returns the list of Events for a given Agency
   *
   * @param uuid : the UUID for a single Agency
   * @param eventUUID: the UUID for a given event
   * @returns Observable array of Event objects
   */
  getEvent(uuid: string, eventUUID: string): Observable<Event> {

    const url = `fans/${this.basePath}/${uuid}/events/${eventUUID}`;

    return this._http.get<Event>(url).pipe(
      map((event) => new Event().deserialize(event))
    );

  }

  /**
   * Returns the list of Gate Passes for a given Agency
   *
   * @param uuid : the UUID for a single Agency
   * @returns Observable array of Event objects
   */
  getPasses(uuid: string): Observable<GatePass[]> {

    const url = `fans/${this.basePath}/${uuid}/passes`;

    return this._http.get<GatePass[]>(url).pipe(
      map((passes) => this.agency.passes = _.sortBy(passes, ['rank', 'name']).map((pass) => new GatePass().deserialize(pass)))
    );

  }

  /**
   * Returns the list of Gate Passes for a given Agency
   *
   * @param uuid : the UUID for a single Agency
   * @returns Observable array of Event objects
   */
  getPass(uuid: string, id: number): Observable<GatePass> {

    const url = `fans/${this.basePath}/${uuid}/passes/${id}`;

    return this._http.get<GatePass>(url).pipe(
      map((pass) => new GatePass().deserialize(pass))
    );

  }


  getSeasonTicket(uuid: string): Observable<GatePass> {

    const url = `fans/${this.basePath}/${this.agency.uuid}/season/${uuid}`;

    return this._http.get<GatePass>(url).pipe(
      map((pass) => new GatePass().deserialize(pass))
    );

  }

  getSeasonTicketEvents(uuid: string): Observable<Event[]> {

    const url = `fans/${this.basePath}/${this.agency.uuid}/season/${uuid}/events`;

    return this._http.get<Event[]>(url).pipe(
      catchError(() => of(new Array<Event>())
      ),
      map((events) => events.map((event) => new Event().deserialize(event)))
    );

  }

  public getBundle(id: number): Observable<Bundle> {

    const url = `bundle/${id}`;

    return this._httpFanweb.get<Bundle>(url).pipe(
      map((bundle) => new Bundle().deserialize(bundle))
    );

  }
}
