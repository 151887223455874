import { Component, Input, OnInit } from '@angular/core';
import { ISchool } from '@app/data/models/school.interface';
import tinycolor from 'tinycolor2';

@Component({
  selector: 'ts-agency-cover',
  templateUrl: './agency-cover.component.html',
  styleUrls: ['./agency-cover.component.scss']
})

export class AgencyCoverComponent implements OnInit {

  @Input() agency: ISchool;
  @Input('for-card') forCard = false;

  constructor () { }

  ngOnInit() {
  }

  getCoverArtContainerStyles() {
    const lineColor = tinycolor(this.agency.settings?.primaryColor);
    const colorIsTooDark = lineColor.getLuminance() < 0.1;

    const backgroundGradient = `
            repeating-linear-gradient(
                135deg,
                transparent,
                transparent 5px,
                ${ this.agency.settings?.primaryColor } 5px,
                ${ this.agency.settings?.primaryColor } 10px
            ),
            linear-gradient(
                to bottom,
                ${ colorIsTooDark ? lineColor.lighten(10) : lineColor.darken(10) },
                ${ this.agency.settings?.primaryColor } 85%,
                ${ this.agency.settings?.primaryColor }
            )
        `;

    return {
      background: backgroundGradient,
    };
  }

  getCoverArtTextStyles() {
    return {
      background: `radial-gradient(${ this.agency.settings?.primaryColor } 0, ${ tinycolor(this.agency.settings?.primaryColor).setAlpha(0.4) } 80%, transparent)`,
      color: this.agency.settings?.primaryText,
    };
  }
}
