import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Agency } from '@app/data/models/agency.model';

@Component({
  selector: 'app-agency-list-modal',
  templateUrl: './agency-list.component.html'
})
export class AgencyListModalComponent implements OnInit {
  @Input() agencies: Agency[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
