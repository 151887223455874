import { Component, Input } from '@angular/core';
import { ConsumerPass } from '@app/data/models/passes/consumer-pass.model';

@Component({
  selector: 'ts-consumer-pass-list',
  templateUrl: './consumer-pass-list.component.html',
  styleUrls: ['./consumer-pass-list.component.scss']
})

export class ConsumerPassListComponent {

  @Input() pass: ConsumerPass;

  constructor() {}

}
