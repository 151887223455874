import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventFilter } from '@app/data/models/events/event-filter.model';
import { EventFilterModalComponent } from '@app/agency/events/filter.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Event } from '@app/data/models/event.model';
import { from, Observable, BehaviorSubject } from 'rxjs';
import { map, distinct } from 'rxjs/operators';
import { TransferService } from '@app/data/services/transfer.service';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class TicketsListingComponent implements OnInit {

  public events: EventAdmission[] = new Array<EventAdmission>();

  public isLoading = true;

  public noResultsMessage = 'tickets available for this date range';

  public filter: EventFilter = new EventFilter();
  public startDate: Date = new Date();

  // array of dates that ticketed events occur on
  public dates: Date[] = new Array<Date>();

  public filteredEvents$ = new BehaviorSubject<EventAdmission[]>(new Array<EventAdmission>());

  constructor (
    private _userAdmissionService: UserAdmissionService,
    private _modalService: NgbModal,
    public transferService: TransferService,
    private _spinner: SpinnerService
  ) {
    this._setFilterDate(new Date());
    // this.transferService.getTransfers().subscribe();
    this._spinner.setMessage('Loading Tickets');

  }

  ngOnInit() {

    this.admission$.subscribe((admission) => {
      this.events = admission;
      // this.events = events;
      this.isLoading = false;
      this.filter.setEvents(this.events.map((event) => event.event));
      from(this.events).pipe(
        map((event) => moment(event.event.dateStart).startOf('day').toDate()),
        distinct()
      ).subscribe((date) => this.dates.push(date));
      if (this.dates.length > 0) {
        const minDate = _.min(this.dates);
        if (moment(this.filter.startDate).isSame(moment(minDate).startOf('week').toDate())) {
          // for initail page load
          this._setFilterDate(minDate);
        } else {
          // for punch pass claim reload - to keep it on same filter
          this._setFilterDate(this.filter.startDate);
        }
      }
      this._spinner.hide();
    });
    // need to load the Tickets, Claimable Events and Transfers
    this._userAdmissionService.loadAdmission().subscribe(() => {
      this._spinner.hide();
    });
  }

  public get admission$(): Observable<EventAdmission[]> {
    return this._userAdmissionService.admission$;
  }

  private _setFilterDate(date: Date) {
    this.filter.setStartDate(moment(date).startOf('week').toDate());
    this.filter.setEndDate(moment(date).endOf('week').toDate());
    this.filteredEvents$.next(this.getFilteredTickets());
  }

  private _isFilteredEvent(event: Event): boolean {
    return this.filter.getEvents().findIndex((filter) => filter.id === event.id) > -1;
  }

  public getFilteredTickets() {
    return _.orderBy(
      this.events.filter(
        (ticket) => this._isFilteredEvent(ticket.event)
      ),
      (admission: EventAdmission) => admission.event.dateStart
    );
  }

  public showFilter() {
    const modalRef = this._modalService.open(EventFilterModalComponent, { centered: true });
    modalRef.componentInstance.filter = this.filter;
    modalRef.componentInstance.showAgenciesFilter = true;
    modalRef.result.then(() => {
      this.filteredEvents$.next(this.getFilteredTickets());
    }, () => {
      this.filteredEvents$.next(this.getFilteredTickets());
    });
  }

  public addDay(count: number) {
    const dt: Date = moment(this.filter.startDate).add(count, 'day').toDate();
    this._setFilterDate(dt);
  }

  public onDateChange(event: any): void {
    const dt: Date = moment(event.target.value).startOf('week').toDate();
    this._setFilterDate(dt);
  }

  public applySpecialDateCSS = (d: Date) => {

    const classes: string[] = new Array<string>();

    const dt = moment(d);
    const sdt = moment(this.filter.startDate);
    const edt = moment(this.filter.endDate);

    // check to see if the date is in the filter date range
    if (dt.isSameOrAfter(sdt) && dt.isSameOrBefore(edt)) {
      classes.push('selected-week-class');
    }

    // check to see if the date is in the event dates array
    if (this.filter.getEventDates().findIndex((innerDt) => moment(d).isSame(moment(innerDt))) >= 0) {
      classes.push('custom-date-class');
    }

    return classes.join(' ');

  };

}
