import { IDeserializable } from '@app/data/models/deserializable.interface';
import * as moment from 'moment';

export class EventSchedule implements IDeserializable {

  dateStart: Date;
  description: string;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);
    this.dateStart = moment(input.dateStart).toDate();
    return this;
  }

}
