import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Ticket } from '@app/data/models/ticket.model';
import { TicketBundle } from '@app/data/models/ticket-bundle';
import { ITicketService } from '@app/shared/ticket-spicket/interfaces/ticket-service.interface';
import { APIServiceWithAuth } from '@app/core/http/token.interceptor';

@Injectable()
export class TicketService implements ITicketService {

  public tickets: Ticket[] = new Array<Ticket>();

  private _basePath = 'fans/tickets';

  private _http: HttpClient;

  constructor (
    APIService: APIServiceWithAuth
  ) {
    this._http = APIService.httpClient;
  }

  /**
   * returns a list of Agency objects
   *
   * @returns Observable array of Agency objects
   */
  public getTickets(): Observable<Ticket[]> {
    return this._http.get<Ticket[]>(this._basePath).pipe (
      map( (tickets) => this.tickets = tickets.map((ticket) => new Ticket().deserialize(ticket)) )
    );
  }

  /**
   * retrieves the tickets for a single event to make sure we've got the latest
   *
   */
  public getEventTickets(uuid: string): Observable<Ticket[]> {

    const url = `${this._basePath}/${uuid}`;

    return this._http.get<Ticket[]>(url).pipe (
      map( (tickets) => tickets.map((ticket) => new Ticket().deserialize(ticket)) )
    );


  }

  /**
   * returns a single Ticket object based on the provided UUID
   *
   * @param uuid
   * @returns Observable array of Agency objects
   *
   */
  getTicket(uuid: string): Observable<Ticket> {

    const url = `${this._basePath}/${uuid}`;

    return this._http.get<Ticket>(url).pipe(
      map( (ticket) => new Ticket().deserialize(ticket))
    );

  }

  /**
   * Checks in a bundle of tickets ()
   *
   */
  selfCheckIn(bundle: TicketBundle): Observable<Ticket[]> {

    const url = `${this._basePath}/checkin`;

    const ticketNumbers: string[] = bundle.tickets.map((ticket) => ticket.ticketNumber);

    const checkin: Object = {
      eventUUID: bundle.event.uuid,
      tickets: ticketNumbers
    };

    return this._http.post(url, checkin).pipe(
      switchMap(() => this.getTickets())
    );

  }

  /**
   * Calls the service API to send the ticket pdf via email
   *
   * @param uuid UUID for the Event
   */
  emailTickets(uuid: string): Observable<any> {
    const url = `${this._basePath}/${uuid}/email`;
    return this._http.post(url, {});
  }

}
