import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MessageService } from '@app/shared/ticket-spicket/message.service';

@Component({
  selector: 'app-ticket-renewal',
  templateUrl: './ticket-renewal.component.html',
  styleUrls: ['./ticket-renewal.component.scss']
})
export class TicketRenewalComponent implements OnInit, AfterViewInit {

  public code: any = {};

  public formCode: FormGroup = new FormGroup({});
  public formCodeFields: FormlyFieldConfig[] = [
    {
      key: 'text',
      type: 'input',
      templateOptions: {
        type: 'code',
        label: 'Renewal Code',
        placeholder: 'Enter the renewal code here',
        required: true,
        appearance: 'outline'
      }
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private _messageService: MessageService
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  public sendCode() {
    this._messageService.toast.success('Renewal Code Accepted.');
  }
}
