<div class="coverArt view allAccess {{ pass.activity?.typeActivity.iconClassName }} {{pass.season?.iconClassName}}">
    <div class="colorOverlay"></div>
    <div class="details">
        <div class="row align-items-center">
            <div class="col-12">
                <svg class="icon" *ngIf="pass.gatePassType?.value === 'All Access'">
                    <use xlink:href="#allAccess"></use>
                </svg>
                <svg class="icon" *ngIf="pass.gatePassType?.value === 'Season'">
                    <use attr.xlink:href="#{{pass.season.iconClassName}}"></use>
                </svg>
                <svg class="icon" *ngIf="pass.gatePassType?.value === 'Activity'">
                    <use attr.xlink:href="#{{pass.activity.typeActivity?.iconClassName}}"></use>
                </svg>
                <svg class="icon" *ngIf="pass.gatePassType?.value === 'Event' && pass.activity">
                    <use *ngIf="pass.activity" attr.xlink:href="#{{pass.activity.typeActivity?.iconClassName}}">
                    </use>
                </svg>
                <h5 class="name">{{ pass.name }}</h5>
                <p class="description">{{ pass.description }}</p>
            </div>
        </div>
    </div>
</div>