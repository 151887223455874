<div class="p-0" style="overflow:hidden;">

    <div class="row my-3">

        <div class="col-12 col-md-7">
            <ts-section-header>
                <i class="fa fa-fw fa-map mr-1"></i>Reserved Seating Map
            </ts-section-header>
            <div class="text-center mb-3">
                Use the seat map below to choose your seats for the season. When you are ready to complete your purchase, select the "Checkout" button.
            </div>
            <ts-seat-map [config]="config" mode="normal" (seatObjectSelected)='selectSeat($event)' (seatObjectDeselected)='deselectSeat($event)' (onRender)="setChart($event)" *ngIf="!isLoading"></ts-seat-map>
        </div>

        <div class="col-12 col-md-5">
            <ts-section-header>
                <i class="fas fa-fw fa-ticket-alt mr-1"></i>Selected Seats ({{ (reservedSeats$ | async).length }})
            </ts-section-header>
            <div class="my-4 text-center selection-empty pb-4" *ngIf="(reservedSeats$ | async).length === 0">
                No seats are currently selected.<br />
                Select a seat from the map.
            </div>

            <div class="p-2 checkout-button">
                <button mdbRippleRadius type="button" class="btn btn-secondary w-100" [routerLink]="cart.cartRoute" [disabled]="(reservedSeats$ | async).length === 0">
                    <i class="fa fa-credit-card mr-2"></i>Checkout
                </button>
            </div>

            <div class="text-center list">
                <div class="seat-selection" *ngFor="let item of reservedSeats$ | async">
                    <div class="seat-icon">
                        {{item.seat.section}}
                    </div>
                    <div class="details">
                        <div class="float-right text-right" style="line-height:40px;">
                            <strong>{{item.seat.price | currency}}</strong>
                        </div>
                        <div class="float-left text-left font-sm">
                            Row {{item.seat.row}}<br />
                            Seat {{item.seat.number}}
                        </div>
                        <div class="float-left text-center">
                            <div class="row">
                                <div class="col-6 offset-3 font-weight-bold">
                                    {{item.ticketPrice.displayName}}
                                </div>
                            </div>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                    <div class="remove">
                        <button type="button" class="btn btn-danger rounded-circle btn-sm" (click)="removeSeat(item.seat)">
                            <i class="fa fa-minus"></i>
                        </button>
                    </div>
                    <div style="clear:both;"></div>
                </div>
            </div>
        </div>

    </div>

</div>
