import { IDeserializable } from '@app/data/models/deserializable.interface';
import { ReservedSeatCategory } from './reserved/seat.model';

export class TicketLevel implements IDeserializable {

  id: number;
  title: string;
  value: string;
  className: string;
  rank: number;
  isActive: boolean;
  description: string;
  digitalCount: number;
  gateCount: number;

  isReserved = false;
  reservedCategory: ReservedSeatCategory;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);

    if (input.reservedCategory) {
      this.reservedCategory = new ReservedSeatCategory().deserialize(input.reservedCategory);
    }

    return this;
  }

}
