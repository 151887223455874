import { IDeserializable } from './deserializable.interface';
import { Address } from '@app/data/models/address.model';
import { AccountSettings } from '@app/data/models/settings.model';
import { Year } from '@app/data/models/year.model';
import { Activity } from '@app/data/models/activity.model';
import { ISchool } from '@app/data/models/school.interface';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { Event } from '@app/data/models/event.model';
import * as _ from 'lodash';

export class Agency implements IDeserializable, ISchool {

  id: number = null;
  uuid: string = null;
  name: string = null;
  version?: string = null;
  nameMascot: string = null;
  nameShort: string = null;
  phone: string;
  website: string;
  vanityURI?: string;
  chargeConvenienceFee?: boolean;
  allowSelfCheckin?: boolean;
  primaryAddress?: Address;
  parent?: Agency;
  settings?: AccountSettings;
  currentYear?: Year;

  activities: Activity[];

  children?: Agency[] = new Array<Agency>();

  public events: Event[] = new Array<Event>();
  public passes: GatePass[] = new Array<GatePass>();

  // boolean used to determine if we're currently loading events
  public isLoadingEvents = false;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);

    if (input.settings) {
      this.settings = new AccountSettings().deserialize(input.settings);
    }

    if (input.primaryAddress) {
      this.primaryAddress = new Address().deserialize(input.primaryAddress);
    }

    if (input.currentYear) {
      this.currentYear = new Year().deserialize(input.currentYear);
    }

    if (input.parent) {
      this.parent = new Agency().deserialize(input.parent);
    }

    if (input.activities) {
      this.activities = input.activities.map((activity: Activity) => new Activity().deserialize(activity));
    }

    if (input.accounts) {
      this.children = _.orderBy(input.accounts, ['name']).map((agency: Agency) => new Agency().deserialize(agency));
    }

    return this;
  }

  public hasChildren() {
    return this.children.length > 0;
  }

  getType(): string {
    return 'agency';
  }

  get abbreviation(): string {
    return this.nameMascot ? this.nameMascot : this.nameShort;
  }

}
