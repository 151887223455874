import { Component, Input, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TicketBottomSheet } from '../bottom-sheet/ticket-bottom-sheet/ticket-bottom-sheet.component';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';

@Component({
  selector: 'ts-ticket-list-item',
  templateUrl: './ticket-list-item.component.html',
  styleUrls: ['./ticket-list-item.component.scss']
})

export class TicketListItemComponent {

  @Input() ticket: EventAdmission;
  @ViewChild('myDrop', { static : false }) myDrop: any;

  public open = false;
  public direction = 'left';
  public animationMode = 'fling';
  public spin = true;
  public tooltipPosition: TooltipPosition = 'above';

  constructor (
    private bottomSheet: MatBottomSheet
  ) { }

  public openBottomSheet(ticket: EventAdmission): void {
    const bottomSheetRef = this.bottomSheet.open(TicketBottomSheet, {
      data: ticket,
    });
    bottomSheetRef.afterDismissed().subscribe();
  }

}
