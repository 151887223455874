<div style="overflow:hidden;">
    <div class="container bg-white pb-4" *ngIf="agency.events && agency" style="position:relative;z-index:100;">
        <ts-agency-cover [agency]="agency"></ts-agency-cover>
        <nav aria-label="breadcrumb" class="d-none d-sm-block">
            <ol class="breadcrumb">
                <li class="breadcrumb-item font-sm">
                    <a routerLink="..">{{ agency.name }}</a>
                </li>
                <li class="breadcrumb-item active font-sm" aria-current="page">All Events</li>
            </ol>
        </nav>
        <button class="btn btn-sm btn-light btn-block d-sm-none d-block text-center my-3" routerLink="../">
            <i class="fa fa-arrow-left mr-2"></i>Back to {{ agency.name }}
        </button>
        <div class="mb-5" *ngIf="isLoading">
            <ts-loading message="Hang tight, we're loading some awesome events..." [show]="isLoading"></ts-loading>
        </div>
        <app-agency-events-list *ngIf="!isLoading" style="position:relative;" [events]="agency.events" [agency]="agency"></app-agency-events-list>
    </div>
    <svg class="background">
        <use xlink:href="#background" x="0px" y="0px"></use> 
    </svg>
</div>