import { Component, Input } from '@angular/core';

@Component({
  selector: 'ts-no-results',
  templateUrl: './no-results.html',
  styleUrls: ['./no-results.scss']
})
export class NoResultsComponent {
  @Input() message: string;

  constructor() {}

}
