import * as moment from 'moment';
import { IDeserializable } from '@app/data/models/deserializable.interface';

export class Year implements IDeserializable {
  id: number;
  name: string;
  dateStart: Date;
  dateEnd: Date;
  isActive: boolean;
  current: boolean;
  version: string;

  constructor () {}

  deserialize(input: any) {
    Object.assign(this, input);
    this.setDateStart(input.dateStart);
    this.setDateEnd(input.dateEnd);
    return this;
  }

  setDateStart (dateString: string) {
    this.dateStart = moment(dateString).toDate();
  }

  setDateEnd (dateString: string) {
    this.dateEnd = moment(dateString).toDate();
  }

  get abbreviatedName(): string {
    const start = moment(this.dateStart);
    const end = moment(this.dateEnd);

    if (end.year() === start.year()) {
      return start.year().toString();
    } else {
      return `${start.format('YYYY')}-${end.format('YY')}`;
    }
  }
}
