import { IDeserializable } from '@app/data/models/deserializable.interface';

export class RenewalTicketHolderSeat implements IDeserializable {

  public id: number;
  public seatKey: string;
  public amountPrice: number;
  public amountPaid: number;
  public amountFee: number;
  public consumerPaymentId: number;

  // transient attribute used for exchange
  public exchangeSeatKey: string;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

}
