import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { ClerkService } from '@app/core/authentication/clerk.service';
import { AgencySearchResult } from '@app/data/models/agency-search.model';
import { Event } from '@app/data/models/event.model';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import { AgencyService } from '@app/data/services/agency.service';
import { CartService } from '@app/data/services/cart.service';
import { TicketService } from '@app/data/services/ticket.service';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public tickerItems: string[] = new Array<string>();
  public featuredEvents: Event[] = new Array<Event>();
  agency$: Observable<AgencySearchResult[]>;
  agencyLoading = false;
  agencyInput$ = new Subject<string>();
  selectedAgency: AgencySearchResult;




  constructor(
    private _agencyService: AgencyService,
    private _authService: AuthenticationService,
    private router: Router,
    public ticketService: TicketService,
    private _userAdmissionService: UserAdmissionService,
    public _cart: CartService,
    public _clerkService: ClerkService
  ) {
  }

  trackByFn(item: AgencySearchResult) {
    return item.uuid;
  }

  ngOnInit() {
    this.agency$ = this.agencyInput$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.agencyLoading = true),
      switchMap(term => {
        if (term === undefined || term === null || term === ''
            || term.length > 100 || (term && term.split(' ').length > 6)) {
          this.agencyLoading = false;
          return of([]);
        }

        return this._agencyService.searchAgency(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.agencyLoading = false)
        );
      })
    );

    // load ticker items
    this.tickerItems.push('Socorro High (El Paso, TX) sold the most tickets last week.');
    this.tickerItems.push('WCAC (Washington, D.C.) sold the most passes this year.');

    this.getEvents().subscribe();
  }

  /**
   * returns all of the events that the user has access to for the current date
   *
   */
  public getTodaysEvents(): EventAdmission[] {
    return this._userAdmissionService.getEventsByDate(new Date());
  }

  getEvents(): Observable<Event[]> {
    // let url = 'fans/';
    // if (this._authService.isAuthenticated()) {
    //   url += 'my-';
    // }
    // url += 'events';

    return of(new Array<Event>());
  }

  public isAuthenticated(): boolean {
    return this._authService.isAuthenticated();
  }

  onAgencySelection() {
    const uuid = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    if (uuid.test(this.selectedAgency.uuid)) {
      this.router.navigate(['/agency', this.selectedAgency.uuid]);
    } else {
      this.router.navigate(['/group', this.selectedAgency.uuid]);
    }
  }

}
