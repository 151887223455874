import { Address } from './address.model';

export class Location {

  id: number;
  name: string;
  capacity: number;

  address?: Address;

  version?: string;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);
    this.address = new Address().deserialize(input.address);
    return this;
  }

}
