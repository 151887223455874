// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpBackend } from '@angular/common/http';
import { Observable, filter, from, switchMap, take } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClerkService } from '../authentication/clerk.service';
import { ApiPrefixInterceptor } from './api-prefix.interceptor';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable(
  { providedIn: 'root' }
)
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthenticationService, private clerk: ClerkService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }
    return this.clerk.initialized$.pipe(
      filter(initialized => initialized),
      switchMap(() => this.clerk.session$),
      take(2),
      switchMap(session => {
        if (session) {
          return from(this.getToken(session)).pipe(
            switchMap(token => {
              const clonedReq = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`
                }
              });
              return next.handle(clonedReq);
            })
          );
        }
        return next.handle(request);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/typedef
  private async getToken(session): Promise<string> {
    const token = await session.getToken({ template: 'fan-api-access' });
    return token;
  }

}

class InterceptingHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) { }

  handle(req: HttpRequest<any>) {
    return this.interceptor.intercept(req, this.next);
  }
}

class HttpHandlerInterceptor implements HttpHandler {
  constructor(private next: HttpBackend, private interceptors: HttpInterceptor[]) { }

  handle(req: HttpRequest<any>) {
    const handler = this.interceptors.reduceRight(
      (next, interceptor) => new InterceptingHandler(next, interceptor),
      this.next
    );
    return handler.handle(req);
  }
}

@Injectable(
  { providedIn: 'root' }
)
export class APIServiceWithAuth {
  httpClient: HttpClient;

  constructor(private handler: HttpBackend, private interceptor: TokenInterceptor, private prefixInterceptor: ApiPrefixInterceptor) {
    this.httpClient = new HttpClient(new HttpHandlerInterceptor(this.handler, [this.interceptor, this.prefixInterceptor]));
  }
}
