<div class="modal-body p-0 pb-4 mb-3">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-header-color single">
        <div class="modal-agency-header">
            <div class="modal-agency-logos text-center">
                <div class="modal-agency-single">
                    <img [src]="agency.settings?.logo2xURI" alt=""/>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body pb-3">
        <div class="row">
            <div class="col-12 col-md-6 border-right mb-4">
                <ts-section-header>
                    <i class="fa fa-fw fa-tags mr-1"></i>Choose Your Passes
                </ts-section-header>
                <p style="font-size:0.7rem;">These passes are general admission and do not guarantee you admission into all events. Admission to an event is based upon capacity available at the time of entry for safety purposes.</p>
                <div *ngFor="let price of ticketPrices">
                  <div class="row align-items-center m-0 schedule pb-3">
                      <div class="col-8 text-left p-0">
                          <div class="price title">{{price.displayName}}</div>
                          <div class="discount-message" *ngIf="price.hasDiscount()">Discount valid until: {{ price.dateEnd | date: 'M/dd/yyyy @ h:mm a' }}</div>
                      </div>
                      <div class="col-4 text-right p-0">
                          <div class="price discount" [ngClass]="{'has-discount': price.hasDiscount()}" *ngIf="price.hasDiscount()"><span *ngIf="price.priceAmount > 0">{{ price.priceAmount | currency }}</span><span *ngIf="price.priceAmount === 0">FREE</span></div>
                          <div class="price" [ngClass]="{'has-discount': price.hasDiscount()}" ><div></div><span *ngIf="price.priceAmountOriginal > 0">{{ price.priceAmountOriginal | currency }}</span><span *ngIf="price.priceAmountOriginal === 0">FREE</span></div>
                      </div>
                      <div class="col-12 px-0 pt-2" *ngIf="product.isFamilyPass">
                          <select class="form-control w-100">
                              <option value=""> - Select an Option - </option>
                              <option *ngFor="let price of ticketPrices" [value]="price.id">
                                  {{ price.priceAmount | currency }} - {{price.displayName}}
                              </option>
                          </select>
                      </div>
                      <div class="col-12 px-0 pt-2" *ngIf="!product.isFamilyPass">
                          <div class="quantity-selector">
                              <div class="row align-items-center text-center m-0">
                                  <div class="col-4 p-0">
                                      <button mdbRippleRadius type="button" class="btn btn-light quantity-decrease border-0" 
                                      style="width:100%;height:100%;" (click)="removeTicket(price)"
                                      aria-label="Decrease Ticket Count">-</button>
                                  </div>
                                  <div class="col-4 border-right border-left">
                                      <div class="quantity" aria-label="Ticket Count" tabindex="0">{{ cartItems.getProductTicketPriceQty(product, price) | async }}</div>
                                  </div>
                                  <div class="col-4 p-0">
                                      <button mdbRippleRadius type="button" class="btn btn-light quantity-increase border-0" style="width:100%;height:100%;"
                                        (click)="addTicket(price)" [disabled]="cartItems.hasReachedMaxAvailableQty(product, price) | async"
                                        aria-label="Increase Ticket Count">
                                        +
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="align-items-center m-0 schedule pb-3" *ngIf="product.isFamilyPass">
                    <hr/>
                    <div class="row">
                        <div class="col-8 text-left">
                            <div class="price title"># OF FAMILY MEMBERS</div>
                        </div>
                        <div class="col-4 text-right">
                            <div class="price"> (up to {{product.ticketQuantity}})</div>
                        </div>
                        <div class="col-12 pt-2">
                            <p><small>Select the number of family members who will be named on this pass</small></p>
                            <div class="quantity-selector">
                                <div class="row align-items-center text-center m-0">
                                    <div class="col-4 p-0">
                                        <button mdbRippleRadius type="button" class="btn btn-light quantity-decrease border-0" style="width:100%;height:100%;" 
                                        (click)="updateSelectedQty(-1)" [disabled]="selectedQty === 1"
                                        aria-label="Decrease Ticket Count">-</button>
                                    </div>
                                    <div class="col-4 border-right border-left">
                                        <div class="quantity"  aria-label="Ticket Count" tabindex="0">{{ selectedQty }}</div>
                                    </div>
                                    <div class="col-4 p-0">
                                        <button mdbRippleRadius type="button" class="btn btn-light quantity-increase border-0" style="width:100%;height:100%;" 
                                        (click)="updateSelectedQty(1)" [disabled]="selectedQty === product.ticketQuantity"
                                        aria-label="Increase Ticket Count">+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <ts-section-header>
                    <i class="fa fa-fw fa-list-alt mr-1"></i>Selection Summary
                </ts-section-header>
                <div *ngFor="let price of product.prices">
                  <div class="row align-items-center" >
                      <div class="col-5 text-left">
                          <div class="price title">{{price.displayName}}</div>
                      </div>
                      <div class="col-7 text-right ml-auto">
                          <div class="d-inline-block">x</div>
                          <div class="d-inline-block" style="width:20px;">{{ cartItems.getProductTicketPriceQty(product, price) | async }}</div>
                          <div class="ml-2 d-inline-block">=</div>
                          <div class="d-inline-block" style="width:89px;">{{ cartItems.getProductTicketPriceTotalPrice(product, price) | async | currency }}</div>
                      </div>
                  </div>
                </div>
                <hr />
                <div class="text-right mb-3">
                    <!-- <div class="mb-2">
                        <strong>Convenience Fee:</strong>
                        <div class="d-inline-block text-right" style="width:89px;"><span *ngIf="!loading">{{cartItems.getTotalFees() | currency }}</span><span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></span></div>
                    </div> -->
                    <div class="mb-2">
                        <strong>Total Amount:</strong>
                        <div class="d-inline-block text-right" style="width:89px;"><span *ngIf="!loading">{{cartItems.getTotalPrice() | currency }}</span><span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></span></div>
                    </div>
                    <div class="mb-2 text-right text-muted">
                        (plus applicable fees)
                    </div>
                </div>
                <div class="row text-center my-3">
                    <div class="col-12">
                        <button mdbRippleRadius type="button" [disabled]="cartItems.isEmpty()" class="btn btn-secondary w-100" (click)="checkout()">
                            <i class="fa fa-credit-card mr-2"></i>Checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center modal-close-button">
    <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="close()">Done</button>
</div>
