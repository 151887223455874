import * as Sentry from '@sentry/angular';
import { isString } from '@sentry/utils';
import { HttpErrorResponse } from '@angular/common/http';

export const replacer = function (key: any, value: any) {
  if (value === null) {
    return undefined;
  } else if (value === 0) {
    return undefined;
  }
  return value;
};

export function SentryTransaction(operation: string, message: string, extra?: any) {
  const transaction = Sentry.startTransaction({
    op: operation,
    name: message,
  });
  if (extra) {
    transaction.setData('message.data', extra);
  }
  transaction.finish();
}

export function SentryMessage(message: string, extra?: any) {
  Sentry.withScope(scope => {
    scope.setExtras(extra);
    Sentry.captureMessage(message);
  });
}

export function SentryException(err: Error, extra?: any) {
  Sentry.withScope(scope => {
    scope.setExtras(extra);
    Sentry.captureException(err);
  });
}

/* --------------------------------------------------------------
 * The following is lifted from the latest sentry/angular error
 * handler, which accounts for non-error exceptions coming from
 * (most likely) the http module.  See
 * https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts
 * for the latest implementation (from which this was lifted) or
 * https://github.com/getsentry/sentry-javascript/blob/6.x/packages/angular/src/errorhandler.ts
 * for the v6 implementation (which this repo uses).
 * --------------------------------------------------------------
 */

interface ErrorCandidate {
  name?: unknown;
  message?: unknown;
  stack?: unknown;
}

function isErrorOrErrorLikeObject(value: unknown): value is Error {
  if (value instanceof Error) {
    return true;
  }

  if (value === null || typeof value !== 'object') {
    return false;
  }

  const candidate = value as ErrorCandidate;

  return (
    isString(candidate.name) &&
    isString(candidate.message) &&
    (undefined === candidate.stack || isString(candidate.stack))
  );
}

export function errorExtractor (errorCandidate: unknown): unknown {
  const error = tryToUnwrapZonejsError(errorCandidate);

  // If it's http module error, extract as much information from it as we can.
  if (error instanceof HttpErrorResponse) {
    return extractHttpModuleError(error);
  }

  // We can handle messages and Error objects directly.
  if (typeof error === 'string' || isErrorOrErrorLikeObject(error)) {
    return error;
  }

  // Nothing was extracted, fallback to default error message.
  return null;
}

function tryToUnwrapZonejsError(error: unknown): unknown | Error {
  // TODO: once Angular14 is the minimum requirement ERROR_ORIGINAL_ERROR and
  //  getOriginalError from error.ts can be used directly.
  return error && (error as { ngOriginalError: Error }).ngOriginalError
    ? (error as { ngOriginalError: Error }).ngOriginalError
    : error;
}

export function extractHttpModuleError(error: HttpErrorResponse): string | Error {
  // The `error` property of http exception can be either an `Error` object, which we can use directly...
  if (isErrorOrErrorLikeObject(error.error)) {
    return error.error;
  }

  // ... or an`ErrorEvent`, which can provide us with the message but no stack...
  if (error.error instanceof ErrorEvent && error.error.message) {
    return error.error.message;
  }

  // ...or the request body itself, which we can use as a message instead.
  if (typeof error.error === 'string') {
    return `Server returned code ${error.status} with body "${error.error}"`;
  }

  // If we don't have any detailed information, fallback to the request message itself.
  return error.message;
}
