<div class="coverArt">
    <div class="outer-container" [style]="getCoverArtContainerStyles()">
        <div class="text" [ngClass]="{ 'for-card': forCard }" [style]="getCoverArtTextStyles()">
            <div class="text-uppercase d-inline-block">
                <h3>{{ agency.name }}</h3>
                <h4 class="mascot" *ngIf="agency.nameMascot">{{ agency.nameMascot }}</h4>
                <h4 *ngIf="!agency.nameMascot">Events</h4>
            </div>
        </div>
        <div class="powered-by" *ngIf="!forCard">
            <span>Powered By</span>
            <div class="logo">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                    y="0px" viewBox="0 0 206 42" xml:space="preserve">
                    <path fill="#000" id="logo" class="st1"
                        d="M44,31.2c-1.2,0-2.3,0-3.5,0c-0.6,0-0.8-0.2-0.8-0.8c0-5.6,0-11.2,0-16.8c0-0.9,0-1.8,0-2.8
                        c0-0.5,0.1-0.6,0.6-0.6c0.9,0,1.8,0,2.7,0c1,0,1,0,1-1c0-3.1,0-6.2,0-9.3c-6.9,0-13.7,0-20.6,0c-0.1,0.2-0.1,0.5-0.1,0.7
                        c0,3,0,6.1,0,9.1c0,0.1,0,0.3,0,0.5c1.1,0,2.2,0,3.3,0c0.9,0,0.9-0.2,0.9,0.9c0,1.2,0,2.4,0,3.7c0,0.4-0.1,0.6-0.6,0.6
                        c-3.4,0-6.9,0-10.3,0c-0.4,0-0.5-0.2-0.5-0.5c0-1.3,0-2.7,0-4c0-0.5,0.2-0.7,0.7-0.7c0.9,0,1.9,0,2.8,0c0.8,0,1-0.2,1-1
                        c0-1.7,0-3.4,0-5.1c0-0.9,0-1.9,0-2.8c0-0.5-0.2-0.9-0.2-1.4C13.7,0,6.8,0,0,0c0,0.1,0,0.2,0,0.3c0,3.1,0,6.1,0,9.2
                        c0,0.2,0,0.5,0,0.8c1.2,0,2.2,0,3.3,0c0.9,0,0.9,0,0.9,0.8c0,5.9,0,11.8,0,17.7c0,0.6,0,1.2,0,1.9c0,0.4-0.1,0.6-0.6,0.6
                        c-0.9,0-1.8,0-2.7,0c-0.3,0-0.6,0-0.9,0.1c0,3.4,0,6.7,0,10c0.2,0,0.4,0.1,0.5,0.1c6.4,0,12.8,0,19.2,0c0.7,0,0.9-0.2,0.9-1
                        c0-1.8,0-3.6,0-5.3c0-1,0-2,0-3c0-0.5-0.2-0.9-0.8-0.9c-1,0-2.1-0.1-3.1-0.1c-0.4,0-0.5-0.1-0.5-0.5c0-1.3,0-2.7,0-4
                        c0-0.4,0.1-0.5,0.5-0.5c3.5,0,6.9,0,10.4,0c0.4,0,0.5,0.1,0.5,0.5c0,1.3,0,2.7,0,4c0,0.4-0.2,0.6-0.6,0.5c-0.9,0-1.8,0-2.7,0
                        c-1,0-1.1,0.1-1.1,1.1c0,2.7,0,5.5,0,8.2c0,0.8,0,0.9,0.9,0.9c0.2,0,0.3,0,0.5,0c5.6,0,11.2,0,16.9,0c0.6,0,1.2,0,1.8,0
                        c0.5,0,0.8-0.2,0.8-0.8C44.1,37.7,44.1,31.6,44,31.2z M40.6,38.8c-3.4,0-6.7,0-10.1,0c-1.2,0-2.4,0-3.6,0c-0.9,0-0.9-0.1-0.9-0.9
                        c0-1,0-2,0-3c0-0.6,0.3-0.9,0.9-0.9c0.9,0,1.9,0,2.8,0c0.6,0,0.9-0.2,0.9-0.9c0-2.9,0-5.8,0-8.7c0-0.8-0.2-1-1.1-1
                        c-4.9,0-9.8,0-14.7,0c-1.3,0-1.3,0-1.3,1.3c0,2.8,0,5.6,0,8.4c0,0.6,0.2,0.8,0.8,0.8c0.9,0,1.9,0.1,2.9,0c0.5,0,0.7,0.2,0.7,0.7
                        c0,1.1,0,2.3,0,3.4c0,0.5-0.2,0.7-0.7,0.7c-1.5,0-3.1,0-4.6,0c-3,0-6,0-9,0c-1,0-1.1-0.1-1-1.1c0.1-1,0.1-2.1,0.1-3.1
                        c0-0.4,0.1-0.6,0.5-0.6c1,0,2-0.1,3,0c0.6,0,0.8-0.2,0.8-0.8c0-8.3,0-16.7,0-25c0-0.5-0.2-0.7-0.7-0.7c-1.1,0-2.1,0-3.2,0
                        C2.7,7.5,2.5,7.4,2.5,7c0-1.4,0-2.8,0-4.2c0.9,0,1.6-0.1,2.3-0.1c3.9,0,7.8,0,11.7,0c1.4,0,1.4,0,1.4,1.4c0,0.9,0,1.8,0,2.7
                        c0,0.5-0.2,0.7-0.6,0.7c-0.9,0-1.8,0-2.7,0c-1.1,0.1-1.1,0.1-1.1,1.2c0,1.7,0,3.5,0,5.2c0,1.2,0,2.4,0,3.6c0,0.5,0.2,0.8,0.8,0.8
                        c0.9,0,1.8,0.1,2.7,0.1c4,0,8,0,12,0c1.6,0,1.6-0.1,1.6-1.6c0-2.7,0-5.3,0-8c0-1.1-0.1-1.2-1.2-1.3c-0.9,0-1.7,0-2.6,0
                        c-0.5,0-0.7-0.1-0.7-0.7c0-1,0-2.1,0-3.1c0-0.9,0.1-1,1-1c2.2,0,4.3,0,6.5,0c2.4,0,4.7,0,7.1,0c0.6,0,0.8,0.2,0.8,0.8
                        c0,1.1,0,2.3,0,3.4c0,0.4-0.1,0.6-0.5,0.6c-0.9,0-1.9,0-2.8,0c-0.9,0-1,0.1-1.1,1c0,0.6-0.1,1.2-0.1,1.7c0,7.2,0,14.3,0,21.5
                        c0,0.5,0.1,1,0,1.5c0,0.6,0.2,0.8,0.8,0.8c0.8,0,1.6,0,2.5,0c1.1,0,1.1,0,1.2,1.1c0,1,0,2,0,3C41.3,38.6,41.1,38.8,40.6,38.8z
                        M207,12.2c-2.2,0-4.3-0.1-6.5-0.1c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.3-0.4,0.4c0,0.7,0,1.5,0,2.3c0.8,0,1.5,0,2.1,0
                        c0,3,0,5.9,0,8.7c-0.3-0.3-0.4-0.6-0.6-0.9c-2.3-3.4-4.6-6.8-6.9-10.2c-0.1-0.2-0.4-0.4-0.7-0.4c-1.6,0-3.1,0-4.7,0
                        c-0.2,0-0.4,0.3-0.4,0.5c0,0.7,0,1.5,0,2.3c0.8,0,1.4,0,2.1,0c0,4,0,7.8,0,11.8c-0.7,0-1.3,0-2,0c0,0.9,0,1.7,0,2.6
                        c2.6,0,5.1,0,7.7,0c0-0.7,0-1.4,0-2c0-0.4-0.2-0.6-0.6-0.6c-0.5,0-1,0-1.6,0c0-2.9,0-5.7,0-8.7c0.3,0.5,0.5,0.8,0.8,1.2
                        c2.2,3.2,4.3,6.4,6.5,9.6c0.2,0.3,0.6,0.5,0.9,0.5c0.7,0.1,1.5,0,2.2,0c0.6,0,0.8-0.2,0.8-0.8c0-3.7,0-7.4,0-11c0-0.8,0-1.7,0-2.6
                        c0.7,0,1.3,0,1.9,0C207,14,207,13.1,207,12.2z M180.1,12.1c-0.1,0-0.4,0.3-0.4,0.4c0,0.6,0,1.2,0,1.9c0,0.4,0.2,0.5,0.6,0.5
                        c0.4,0,0.9,0,1.5,0c-0.8,3.3-1.6,6.5-2.4,9.9c-0.1-0.2-0.1-0.3-0.2-0.4c-0.9-3.5-1.8-7-2.8-10.5c-0.5-1.9-0.6-1.9-2.6-1.8
                        c-0.5,0-0.7,0.2-0.8,0.6c-0.6,2.4-1.3,4.8-2,7.2c-0.4,1.6-0.8,3.1-1.3,4.7c0,0-0.1,0-0.1,0c-0.8-3.2-1.5-6.5-2.2-9.6
                        c0.7-0.1,1.6-0.1,1.8-0.4c0.3-0.4,0.1-1.2,0.1-1.9c0-0.5-0.2-0.7-0.7-0.7c-1,0-2,0-3,0c-1,0-1.9,0-2.9,0c-0.3,0-0.6,0-0.6,0.4
                        c0,0.8,0,1.5,0,2.3c0.3,0,0.6,0,0.9,0c0.6-0.1,0.8,0.2,0.9,0.7c1.1,4.4,2.2,8.8,3.3,13.2c0.1,0.4,0.2,0.5,0.6,0.5c0.8,0,1.7,0,2.5,0
                        c0.6,0,0.9-0.2,1.1-0.8c0.9-3.2,1.7-6.4,2.6-9.6c0.1-0.2,0.1-0.5,0.2-0.7c0.2,0.4,0.2,0.7,0.3,1c0.9,3.2,1.8,6.4,2.8,9.6
                        c0.1,0.2,0.4,0.4,0.6,0.4c1,0,2,0,3,0c0.2,0,0.4-0.2,0.5-0.4c0.6-2.5,1.2-4.9,1.8-7.4c0.6-2.2,1.1-4.4,1.7-6.6c0.5,0,1,0,1.5,0
                        c0-0.9,0-1.8,0-2.8C184.5,12.1,182.3,12.1,180.1,12.1z M107.4,14.9c0.5,0,0.9,0,1.4,0c0.4,0,0.6-0.2,0.6-0.6c0-0.5,0-1,0-1.5
                        c0.1-0.5-0.2-0.7-0.7-0.7c-1.4,0-2.9,0-4.3,0c-0.6,0-0.9,0.2-1.1,0.7c-0.6,1.4-1.1,2.9-1.7,4.3c-0.7,1.8-1.5,3.7-2.3,5.6
                        c-0.2-0.7-0.4-1.2-0.6-1.8c-1.1-2.6-2.2-5.2-3.2-7.9c-0.3-0.8-0.7-1-1.4-0.9c-0.7,0-1.4,0-2.1,0c-0.8,0-1.6,0-2.3,0.1
                        c-0.2,0-0.4,0.2-0.4,0.4c0,0.6,0,1.3,0.1,1.9c0,0.2,0.3,0.4,0.5,0.4c0.5,0.1,0.9,0,1.5,0c-0.3,4-0.7,7.9-1,11.8c-0.6,0-1.1,0-1.6,0
                        c-0.2,0-0.4,0.2-0.4,0.3c0,0.7,0,1.5,0,2.3c2.5,0,4.8,0,7.1,0c0.2,0,0.4-0.3,0.4-0.4c0.1-0.5,0-1.1,0-1.6c0-0.4-0.2-0.7-0.7-0.6
                        c-0.4,0-0.8,0-1.2,0c0.2-2.9,0.4-5.7,0.6-8.7c0.1,0.2,0.2,0.3,0.2,0.4c1,2.3,1.9,4.5,2.9,6.8c0.3,0.7,0.7,1.1,1.5,1
                        c0.4-0.1,0.8,0,1.2,0c0.4,0,0.6-0.2,0.8-0.5c0.4-0.9,0.8-1.8,1.1-2.7c0.7-1.6,1.4-3.2,2.1-4.9c0,0,0.1,0,0.1,0
                        c0.2,2.8,0.4,5.6,0.6,8.5c-0.3,0-0.4,0-0.5,0c-1.5,0-1.5,0-1.5,1.5c0,1,0,1.1,1.1,1.1c2.1,0,4.2,0,6.4,0c0-0.9,0-1.7,0-2.6
                        c-0.7,0-1.4,0-2,0C108.1,22.7,107.8,18.9,107.4,14.9z M67.1,26.7c-0.5,0-1,0-1.5,0c0-4,0-7.9,0-11.8c0.5,0,0.9,0,1.3,0
                        c0.5,0,0.7-0.2,0.7-0.7c0-0.5,0-1.1,0-1.6c0-0.4-0.2-0.5-0.6-0.5c-0.7,0-1.3,0-2,0c-1.5,0-2.9,0-4.4,0c-0.2,0-0.6,0.2-0.6,0.3
                        c0,0.8,0,1.6,0,2.5c0.8,0,1.4,0,2,0c0,1.4,0,2.7,0,4c0,0.4-0.1,0.4-0.5,0.4c-2.1,0-4.1,0-6.2,0c-0.3,0-0.6,0-1,0c0-1.5,0-2.9,0-4.4
                        c0.5,0,1,0,1.5,0c0.5,0,0.7-0.2,0.7-0.7c0-0.5,0-1,0-1.5c0-0.5-0.2-0.6-0.6-0.6c-0.8,0-1.7,0-2.5,0c-1.3,0-2.6,0-3.9,0
                        c-0.5,0-0.8,0.2-0.8,0.8c0,0.5,0.1,1,0.1,1.5c0,0.4,0.2,0.5,0.6,0.5c0.5,0,0.9,0,1.4,0c0,4,0,7.9,0,11.8c-0.2,0-0.2,0-0.3,0
                        c-0.6,0-1.4-0.1-1.6,0.2c-0.3,0.4-0.1,1.1-0.2,1.7c0,0.5,0.2,0.7,0.7,0.7c1.8,0,3.5,0,5.3,0c2,0,2.1-0.1,1.8-2.1
                        c-0.1-0.3-0.2-0.5-0.5-0.5c-0.6,0-1.1,0-1.7,0c0-1.5,0-2.8,0-4.2c0-0.1,0.3-0.4,0.4-0.4c2.2,0,4.5,0,6.7,0c0.1,0,0.4,0.2,0.4,0.3
                        c0,1.4,0,2.8,0,4.2c-0.1,0-0.2,0.1-0.2,0.1c-0.6,0.1-1.4-0.1-1.7,0.3c-0.3,0.4-0.1,1.1-0.2,1.7c0,0.4,0.1,0.6,0.6,0.6
                        c0.8,0,1.7,0,2.5,0c1.3,0,2.6,0,3.9,0c0.5,0,0.7-0.2,0.7-0.7c0-0.5,0-1,0-1.5C67.7,26.8,67.5,26.7,67.1,26.7z M78.2,11.8
                        c-5,0-9.2,4-9.1,8.8c0.1,4.6,3.2,9,9.1,9c6,0,9.2-4.5,9.2-9C87.4,15.7,83.3,11.8,78.2,11.8z M77,26.5c-1.5-0.3-2.5-1.2-3.4-2.4
                        c-1.6-2.3-1.2-5.8,0.9-7.7c1.7-1.5,3.6-1.9,5.7-1.1c2.1,0.8,3.3,2.4,3.6,4.6c0,0.3,0,0.6,0.1,0.7C83.8,24.6,80.6,27.2,77,26.5z
                        M152.8,11.8c-5,0.1-9,4.1-9,8.8c0.1,4.8,3.3,9,9.2,9c5.7,0,9.1-4.2,9.1-9C162.1,15.6,157.9,11.7,152.8,11.8z M153.1,26.6
                        c-3,0.1-5.7-2.3-5.7-6c0-3.3,2.7-5.8,5.7-5.7c3.1,0,5.5,2.5,5.5,5.8C158.5,23.9,156.1,26.5,153.1,26.6z M125.3,24.7
                        c-0.7,0-1.4,0-2.1,0c-0.2,0-0.4,0.2-0.4,0.4c-0.1,0.5-0.1,1-0.1,1.4c-1.7,0-3.3,0-4.9,0c0-1.5,0-3,0-4.5c1.9,0,3.8,0,5.7,0
                        c0-0.8,0-1.5,0-2.3c0-0.4-0.3-0.5-0.6-0.5c-1,0-1.9,0-2.9,0c-0.7,0-1.5,0-2.3,0c0-1.5,0-2.9,0-4.3c1.5,0,3,0,4.6,0c0,0.5,0,1,0,1.5
                        c0,0.2,0.3,0.4,0.4,0.4c0.7,0,1.3,0,2,0c0.4,0,0.6-0.2,0.6-0.6c0-1,0-2.1,0-3.1c0-0.9,0-0.9-1-0.9c-3.8,0-7.6,0-11.5,0
                        c-0.2,0-0.4,0-0.6,0.1c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.8,0,1.5,0,2.3c0.8,0,1.4,0,2,0c0,4,0,7.9,0,11.8c-0.2,0-0.3,0-0.4,0
                        c-1.6,0-1.6,0-1.6,1.7c0,0.9,0,1,1,1c3.4,0,6.8,0,10.2,0c0.6,0,1.3,0,1.9,0c0.2,0,0.5-0.2,0.5-0.4c0-1.3,0-2.6,0-3.9
                        C125.7,24.9,125.4,24.7,125.3,24.7z M141,12.1c-4.2,0-8.4,0-12.6,0c-0.4,0-0.8,0-1.3,0c0,1.9,0,3.6,0,5.3c1,0,2,0,3.1,0
                        c0-0.9,0-1.7,0-2.5c1,0,1.9,0,2.8,0c0,3.9,0,7.8,0,11.8c-0.5,0-1,0-1.5,0c-0.2,0-0.5,0.2-0.5,0.4c-0.1,0.7,0,1.4,0,2.2
                        c2.5,0,4.9,0,7.3,0c0.1,0,0.3-0.3,0.3-0.4c0-0.6,0-1.2,0-1.7c0-0.3-0.1-0.5-0.5-0.4c-0.5,0-1.1,0-1.6,0c0-4,0-7.9,0-11.8
                        c1,0,2,0,3,0c0,0.8,0,1.7,0,2.5c1.1,0,2.1,0,3.1,0c0-1.3,0-2.5,0-3.8C142.6,12.1,142.6,12.1,141,12.1z" />
                </svg>
            </div>
            <div class="shape"></div>
        </div>
    </div>
    <div class="overlay" *ngIf="forCard"></div>
</div>
