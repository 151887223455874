import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { RenewalTicketHolder } from '../models/renewal/ticket-holder.model';
import { Observable, of } from 'rxjs';
import { AgencyService } from './agency.service';
import { map, tap } from 'rxjs/operators';
import { ReservedHoldToken } from '../models/reserved/configuration.model';
import { ReservedSeatsService } from './reserved-seats.service';
import { Event } from '../models/event.model';
import { SeasonTicketExchange } from '../models/renewal/season-ticket-exchange';
import { environment } from '@env/environment';
import { PaymentMethod } from '../models/payment-method.model';
import { CartService } from './cart.service';
import { TicketPrice } from '../models/ticket-price.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RenewalService {

  private _baseUrl = 'https://api.seatsio.net';
  public encodedKey: string = environment.seatsio.encodedKey;
  public httpOptions = {};

  public holdToken: ReservedHoldToken = new ReservedHoldToken();
  public selectedSeats: SeasonTicketExchange[] = new Array<SeasonTicketExchange>();
  public event: Event;

  // cart attributes
  public hasPaymentMethod = false;
  public paymentMethod: PaymentMethod;
  public errors: Object;
  public processing = false;
  public nonce: string;

  constructor (
    private _http: HttpClient,
    private _agencyService: AgencyService,
    private _reserved: ReservedSeatsService,
    private _cart: CartService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Skip-Interceptor': 'true',
        'Authorization': `Basic ${this.encodedKey}`
      })
    };
  }

  public getRenewalBaseUrl(gatePassUUID: string): string {
    return `fans/agencies/${this._agencyService.agency.uuid}/renewals/${gatePassUUID}`;
  }

  public findTicketHolder(gatePassUUID: string, accessKey: string): Observable<RenewalTicketHolder> {

    const url = `${this.getRenewalBaseUrl(gatePassUUID)}/${accessKey}`;

    return this._http.get<RenewalTicketHolder>(url).pipe(
      map((ticketHolder) => {
        const ticketholder = new RenewalTicketHolder().deserialize(ticketHolder);
        if (ticketholder.currentPhase() === 0) {
          throw new HttpErrorResponse({ status: 403, error: 'The Ticket Renewal process is currently closed' });
        }
        return ticketholder;
      })
    );

  }

  public getExchangedSeats(): SeasonTicketExchange[] {
    return this.selectedSeats.filter((seat) => seat.exchangeSeatKey !== null);
  }

  public getHoldToken(): Observable<string> {

    if (this.holdToken.isExpired()) {
      return this._reserved.generateHoldToken().pipe(
        map((token) => this.holdToken = token),
        map(() => this.holdToken.holdToken)
      );
    } else {
      return of(this.holdToken.holdToken);
    }

  }

  getSeasonTicketEvents(gatePassUUID: string): Observable<Event[]> {

    const url = `fans/agencies/${this._agencyService.agency.uuid}/season/${gatePassUUID}/events`;

    return this._http.get<Event[]>(url).pipe(
      map((events) => events.map((event) => new Event().deserialize(event))),
      tap((events) => this.event = events[0])
    );

  }

  /**
   * returns the index number for the given ticket in the selectedTickets collection
   *
   */
  private _findSeat(seat: SeasonTicketExchange): number {
    return this.selectedSeats.findIndex((selectedSeat) => selectedSeat.seatKey === seat.seatKey);
  }

  public isSelected(seat: SeasonTicketExchange): boolean {
    return this._findSeat(seat) >= 0;
  }

  public clearSelected() {
    this.selectedSeats = new Array<SeasonTicketExchange>();
  }

  public toggleSeat(seat: SeasonTicketExchange) {

    // first, check to see if the tickets are in the selected collection
    const index = this._findSeat(seat);

    if (index >= 0) {
      // if the ticket was found, remove it
      this.deselectExchangeSeat(seat.exchangeSeatKey);
      this.selectedSeats.splice(index, 1);
    } else {
      this.selectedSeats.push(seat);
    }

  }

  public hasExchangedSeats(): boolean {
    return this.selectedSeats.filter((seat) => seat.isExchanged()).length > 0;
  }

  public selectExchangeSeat(seatKey: string, price: TicketPrice) {
    // Prevent assigning the same seatKey to multiple seat exchanges
    if (this.selectedSeats.some((innerSeat) => innerSeat.exchangeSeatKey === seatKey)) {
      return null;
    }
    const seat = this.selectedSeats.find((innerSeat) => !innerSeat.isExchanged());
    seat.exchangeSeatKey = seatKey;
    seat.exchangeSeatPrice = price;
  }

  public deselectExchangeSeat(seatKey: string) {
    this.selectedSeats.find((seat) => seat.exchangeSeatKey === seatKey).exchangeSeatKey = null;
    this.deselectSeat(this.event.uuid, seatKey).subscribe();
  }

  /**
   *
   * @param url
   * @param content
   */
  private _sendPost(url: string, content: any): Observable<any> {
    return this._http.post(url, content, this.httpOptions);
  }

  /**
   * Deselect a seat from outside of the seat map
   *
   * @param product
   * @param seat
   * @param holdToken
   */
  public deselectSeat(eventUUID: string, seatKey: string): Observable<any> {

    const url = `${this._baseUrl}/events/${eventUUID}/actions/release`;

    const object = { objectId: seatKey };

    const postData: any = {
      'objects': [object],
      'holdToken': this.holdToken.holdToken
    };

    return this._sendPost(url, postData);

  }

  private _getSeats(): any[] {

    return this.selectedSeats.filter((seat) => seat.isExchanged()).map((seat) => ({
      seatKey: seat.seatKey,
      exchangeSeatKey: seat.exchangeSeatKey,
      exchangeSeatPrice: seat.exchangeSeatPrice
    }));
  }

  public checkout (
    paymentMethod: PaymentMethod,
    gatePassUUID: string
  ): Observable<any> {

    const data: any = {
      holdToken: this.holdToken.holdToken,
      eventUUID: this.event.uuid,
      seats: this._getSeats(),
      method: paymentMethod,
      chargeAmount: this.getUpgradePrice()
    };

    const url = `fans/agencies/${this._agencyService.agency.uuid}/exchange/${gatePassUUID}`;

    return this._http.post(url, data);

  }

  public exchangeSeats (
    gatePassUUID: string
  ): Observable<any> {

    const data: any = {
      holdToken: this.holdToken.holdToken,
      eventUUID: this.event.uuid,
      seats: this._getSeats(),
      method: null,
      chargeAmount: 0
    };

    const url = `fans/agencies/${this._agencyService.agency.uuid}/exchange/${gatePassUUID}`;

    return this._http.post(url, data);

  }

  public getTotalPaid(): number {
    return _.sumBy(this.selectedSeats.filter((seat) => seat.isExchanged()).map((seat) => seat.paidAmount));
  }

  public getTotalExchange(): number {
    return _.sumBy(this.selectedSeats.filter((seat) => seat.isExchanged()).map((seat) => seat.exchangeSeatPrice.priceAmount));
  }

  public getUpgradePrice(): number {
    const price = this.getTotalExchange() - this.getTotalPaid();
    return price || 0;
  }

}
