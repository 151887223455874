import { IDeserializable } from './deserializable.interface';
import { Reward } from '@app/data/models/reward.model';
import { Agency } from '@app/data/models/agency.model';
import { LinkedAccount } from '@app/data/models/linked-account.model';

export class User implements IDeserializable {

  id: number;
  uuid: string;
  name: string;
  nameFirst: string;
  nameLast: string;
  email: string;
  accounts: any[];
  roles: any[];

  token: string;

  following: Agency[] = new Array<Agency>();
  rewards: Reward[] = new Array<Reward>();

  linkedAccounts: LinkedAccount[] = new Array<LinkedAccount>();

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);

    if (input.following) {
      input.following.map((agency: Agency) => new Agency().deserialize(agency));
    }

    if (input.rewards) {
      input.rewards.map((reward: Reward) => new Reward().deserialize(reward));
    }

    return this;
  }

  isFollowing(agencyUUID: string): boolean {
    return this.following.filter((agency: Agency) => agency.uuid === agencyUUID).length > 0;
  }

  private getLinkedByMember(memberType: string): Array<LinkedAccount> {
    return this.linkedAccounts.filter((account) => account.memberType === memberType);
  }

  get linkedChildren(): Array<LinkedAccount> {
    return this.getLinkedByMember('member');
  }

  get linkedParents(): Array<LinkedAccount> {
    return this.getLinkedByMember('memberOf');
  }
}
