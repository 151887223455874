import { Component, Input, OnInit } from '@angular/core';
import { ConsumerGatePass } from '@app/data/models/passes/consumer-gate-pass.model';
import { Transfer } from '@app/data/models/transfer.model';
import { PassService } from '@app/data/services/pass.service';
import { TransferService } from '@app/data/services/transfer.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-pass-cancel-transfer-modal',
  templateUrl: './cancel-transfer-modal.component.html',
  styleUrls: ['./cancel-transfer-modal.component.scss']
})
export class PassCancelTransferModalComponent implements OnInit {

  @Input() consumer: ConsumerGatePass;
  public isLoading = true;
  transfer: Transfer;

  constructor(
    public transferService: TransferService,
    private _messageService: MessageService,
    private _passService: PassService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.transferService.getTransfers()
      .subscribe(() => {
        this.isLoading = false;
        // TODO: fix api to return pass as part of the transfer object
        this.transfer = this.transferService.currentTransfers.outgoing.find((transfer) => transfer.event.uuid === null);
      });
  }

  public close() {
    this.activeModal.dismiss(false);
  }

  cancelTransfer(transfer: Transfer) {
    this._messageService.ConfirmYesNo('Are you sure you want to cancel this Pass Transfer?', 'Are you sure')
      .then(() => {
        this.transferService.cancelTransfer(transfer).pipe (
          switchMap(() => this._passService.getPasses())
        ).subscribe(() => {
          this._messageService.toast.success('Your Pass Transfer has been cancelled');
          this.close();
        });
      });
  }

  resendTransfer(transfer: Transfer) {
    this._messageService.ConfirmYesNo('Are you sure you want to resend this Pass Transfer?', 'Are you sure')
      .then(() => {
        // TODO: fix api for resend Transfer of a pass
        this.transferService.resendTransfer(transfer).subscribe(() => {
          this._messageService.toast.success('Your Pass Transfer has been resent');
          this.close();
        });
      });
  }
}
