import { IDeserializable } from '@app/data/models/deserializable.interface';
import { Activity } from './activity.model';
import { Agency } from './agency.model';

export class EventSchool implements IDeserializable {

  school: Agency;
  schoolActivity: Activity;
  isPassEligible: boolean;
  pctRevenueSplit: number;
  isHomeTeam: boolean;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);

    if (input.school) {
      this.school = new Agency().deserialize(input.school);
    }

    if (input.activity) {
      this.schoolActivity = new Activity().deserialize(input.activity);
    }

    return this;
  }

}
