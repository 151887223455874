import { Component, OnInit } from '@angular/core';
import { TicketShareService } from '@app/data/services/ticket-share.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { TicketShare } from '@app/data/models/ticket-share.model';

@Component({
  selector: 'app-shared-tickets',
  templateUrl: './shared-tickets.component.html'
})
export class SharedTicketsComponent implements OnInit {

  public share: TicketShare;

  constructor(
    private route: ActivatedRoute,
    public _ticketShareService: TicketShareService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.route.params
      .pipe(
        map( (params) => params['share-uuid']),
        switchMap( (id: string) => this._ticketShareService.getShare(id)),
        catchError((err) => {
          console.error(err);
          this.router.navigate(['../invalid'], { relativeTo: this.route });
          throw new Error('invalid share');
        })
      )
      .subscribe((share) => {
        this.share = share;
        if (this.share.event.isEventOver()) {
          this.router.navigate(['../complete'], { relativeTo: this.route });
        }
      });
  }

}
