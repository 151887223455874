import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { CartService } from '@app/data/services/cart.service';
import { PurchaseConfirmation, PurchaseConfirmationItem } from '@app/data/models/purchase-confirmation.model';
import { User } from '@app/data/models/user.model';
import { UserService } from '@app/data/services/user.service';
import { TicketService } from '@app/data/services/ticket.service';
import { CartItemEventSummary } from '@app/data/models/cart/cart-item-event';
import { CartItemPassSummary } from '@app/data/models/cart/cart-item-pass';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

declare global {
  interface Window {
    RoktLauncherScriptPromise: any;
    Rokt: any;  // Replace `any` with a more specific type if necessary
  }
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  public user: User;
  public confirmation: PurchaseConfirmation;
  public tickets: CartItemEventSummary[];
  public passes: CartItemPassSummary[];
  public isMobileApp = false;
  public fc: any;

  constructor(
    public cart: CartService,
    private _userService: UserService,
    private _ticketService: TicketService,
    private _router: Router,
    private _cookieService: CookieService,
  ) { }


  ngOnInit() {
    this.confirmation = this.cart.confirmation;
    if (!this.confirmation) {
      this._router.navigate([this.cart.cartRoute], { replaceUrl: true });
    }
    this.user = this._userService.user;
    this.tickets = this.cart.getEventItems();
    this.passes = this.cart.getPassItems();

    // Check if mobile app
    if (this._cookieService.get('isMobileApp') === 'true') {
      this.isMobileApp = true;
    }

    this.showRoktPPO();

    // Added to update tickets on confirmation. - ELH
    if (!this.user) {
      this._ticketService.getTickets().subscribe();
    } else {
      return;
    }
  }

  async showRoktPPO() {
    // list of agencyIDs opted OUT of PPO
    const PPOBlocklist = [
      // McKinney
      'ede369b5-66c5-4b9d-a053-3207afe03c46', // McKinney ISD
      'e144e92e-ec43-45b2-a437-1411f33f3b03', // McKinney Boyd High School
      '1a115679-d58d-4518-9f3c-0a6b3fed4b43', // McKinney North High School
      '0d871d1c-f33c-4d72-8862-d8072f99235b', // McKinney ISD Playoffs
      '70dd26e6-3605-4be0-a838-8c85a777e78a', // McKinney High School
      '69e28371-862d-40ad-b6e5-29989f463c6d', // Cockrill Middle School
      'a0af213c-601b-447e-9fd9-36881926fae6', // Johnson Middle School
      'b550236e-590d-4322-8a7b-f622fbac0914', // Faubion Middle School
      '092f08d7-ff65-48b2-b842-f76a52d4f069', // Dowell Middle School
      '6cbe593b-247f-4c68-9d76-8895b48cc6d0', // Evans Middle School
      '0523c6c3-5c5f-4e7d-9389-18cefa4858fa',  // McKinney ISD Cheer
      // Johnston County Public Schools
      'ba0ddc7a-8f0d-4f16-a1eb-3841cf69c6a0', // Johnston County Public Schools
      'c73c25c0-ff77-4c8c-865a-8b3105bb799b', // Four Oaks Middle
      'ce512b63-aa86-4d43-b22d-8342d5763fda', // Clayton High
      '88457a39-68de-4595-8142-04af7d329ae2', // Swift Creek Middle
      '6af76e20-4013-40b7-b5e0-7bf24802ed74', // West Johnston High
      'e03a0f07-840c-434a-93fa-9c0f4214692c', // Smithfield-Selma High
      'fe0abb26-2e11-45f6-bae3-131e6d5b3147', // South Johnston High
      'a1a4c08c-8cad-4596-b4a2-0af92fa0b754', // Cleveland High
      '85e6069e-3c6c-479e-bcbe-95d0e9b78958', // Meadow
      'e720b8be-2f1f-4ff4-9ff1-2fb15486efcc', // Selma Middle
      'eb564ffc-7722-4744-9de4-350b2811b171', // Clayton Middle
      'f4667872-bb72-4714-8171-ef3e3c3fe746', // Riverwood Middle
      '1e39a663-c3b8-4572-a589-52fe80c9335e', // McGee's Crossroads Middle
      'd57c10ec-29ed-4359-8342-7d88d41fef80', // Benson Middle
      'fc8514ab-0a68-4724-816f-1644b6eda5d7', // Corinth Holders High
      'efa628d0-0ce2-421f-b9a4-e351ca9f76a2', // North Johnston High
      '04ac20ca-b799-401a-81be-c75d7adca36c', // Cleveland Middle
      'b07b6891-4fc3-4175-9de8-f46a7ddab53f', // Smithfield Middle
      '737b8fb0-3222-4305-b0ef-92ac7e5bd627', // North Johnston Middle
      '634c8f45-c1de-4cff-adee-44a7c03eb4fc', // Princeton HS/ MS
      'fcb41f26-e69b-422b-9b51-d5ea984b530f', // Archer Lodge Middle
      // PHARR-SAN JUAN-ALAMO ISD
      '8aae8ded-3576-4ba5-b759-34dfd72669c6', // Austin Middle School
      'cbe4407c-1918-4b57-a837-ca9bc750b0de', // Jaime Escalante Middle School
      'eb2f1d59-1be0-46c5-ab51-b6258528297b', // Kennedy Middle School
      '23e4d71b-687a-4e42-a9a2-0d77f0ea6341', // Lyndon B. Johnson Middle School
      '9458bcf0-c362-4957-98c6-0ba6ab61d0c8', // Audie Murphy Middle School
      '73386da1-cad0-4ca9-966e-2508f3400fdb', // Liberty Middle School
      '1c1fe31c-87a6-410d-bcd9-43ac27dfc03c', // Yzaguirre Middle School
      'a5b66137-074c-4d83-9f3a-3c0164acd068', // PSJA Southwest High School
      '98ab7436-6842-47f3-b369-56bb3a7b3232', // PSJA High School
      '1c4b3ec1-1dc8-485e-a683-f6a596879ceb', // PSJA Memorial High School
      '92780591-6af9-49c2-b178-1082e7fb5ca8', // PSJA ISD Aquatics
      '300ae274-101d-42e0-9307-274ae9e73dbc', // PSJA ISD - Fine Arts
      '6018c47f-ff3e-4a14-80e5-5be671eb5534', // PSJA ISD Natatorium
      '7325d999-20b7-42b6-999a-c084df5cb5a7', // PSJA North High School
      '0136a5c2-c290-4cb2-946d-42163641e3d6', // PSJA All Events
      '588b2168-795f-4062-8556-1355a1dfeb06', // Pharr San Juan Alamo Special Events
      'd81f42c4-d244-45b8-a863-d85ba928a60a', // Jesus Vela Middle School
      // RIVERDALE RIDGE HIGH SCHOOL
      '2632bfad-c3a2-445c-b1dd-d0a5c7e7bbae', // Riverdale Ridge High School
    ];

    try {
      // have to rely on what's in localstorage as the best source for what agency we're checking out under
      const lastAgencyUUID = localStorage.getItem('lastAgencyUUID');

      // if we don't know what the agencyId is or if it's in the blocklist, skip the PPO entirely
      if (!lastAgencyUUID || PPOBlocklist.indexOf(lastAgencyUUID) >= 0) {
        return;
      }

      await window.RoktLauncherScriptPromise;

      const launcher = await window.Rokt.createLauncher({
        accountId: '3238511105208983552',
        sandbox: !environment.production,
      });

      const cartItems = this.cart.confirmation.items.map((item: PurchaseConfirmationItem) => ({
        majorcat: item.itemType,
        price: item.price,
        quantity: item.selectedQty,
      }));

      const roktPayload = {
        identifier: lastAgencyUUID,
        attributes: {
          email: this.cart.confirmation?.email,
          firstname: this.cart.confirmation?.firstName,
          lastname: this.cart.confirmation?.lastName,
          paymenttype: 'credit_card',
          mobile: this.cart?.phone || this.cart.confirmation?.phoneNumber,
          confirmationref: this.cart?.purchaseId,
          language: 'EN',
          country: 'US',
          currency: 'USD',
          amount: this.cart.confirmation?.totalAmount || 0,
          cartItems: JSON.stringify(cartItems),
        }
      };

      await launcher.selectPlacements(roktPayload);
    } catch (err) {
      // Log the error, but just fail quietly and let the PPO not show
      console.error('Error trying to show PPO: ', err);
    }
  }

  ticketsUrl() {
    // get list of product types in the order (as a Set, so the list is unique)
    const productTypes = this.cart.confirmation.items.reduce((s, item) => s.add(item.itemType), new Set());

    let href = environment.eventsUrl;
    if (productTypes.size > 1) {
      // shouldn't happen since you can't mix tickets and passes, but just in case
      href += '/tickets';
    } else if (productTypes.has('pass')) {
      href += '/passes';
    } else {
      href += `/tickets/purchase/${this.cart.purchaseId}`;
    }

    window.location.href = href;
  }
}
