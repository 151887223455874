import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '@app/data/services/cart.service';
import { ReservedSeat, ReservedSeatCategory } from '@app/data/models/reserved/seat.model';
import { CartItemSummary, CartItem } from '@app/data/models/cart/cart-item.model';
import { ReservedConfiguration } from '@app/data/models/reserved/configuration.model';
import { ReservedPricing } from '@app/data/models/reserved/pricing.model';
import { BehaviorSubject } from 'rxjs';
import { EventStoreChannel } from '@app/data/models/events/event-store-channel.model';
import { TicketPrice } from '@app/data/models/ticket-price.model';
import { GatePass } from '@app/data/models/passes/gate-pass.model';

@Component({
  selector: 'app-agency-season-seatmap',
  templateUrl: './seatmap.component.html',
  styleUrls: ['./seatmap.component.scss']
})
export class AgencySeasonSeatmapComponent implements OnInit {

  @Input() pass: GatePass;
  @Input() eventUUID: string;
  @Input() channel: EventStoreChannel;
  @Input() ticketPrices: TicketPrice[];

  public reservedSeats$ = new BehaviorSubject<CartItem[]>(new Array<CartItem>());

  public seats: {};
  public chart: any = null;
  public isLoading = true;

  public config: ReservedConfiguration;

  constructor (
    public cart: CartService
  ) {}

  ngOnInit() {
    this.cart.getHoldToken().subscribe((token) => {

      this.config = new ReservedConfiguration(token);
      this.config.setEvent(this.eventUUID);

      // set max selected objects based on channel
      if (this.channel) {
        this.config.maxSelectedObjects = this.channel.maxPerTrx;
      }
      // RJH 07/19/2022 - restrict selection for bridgewater
      if (this.pass.id === 2799) {
        this.config.maxSelectedObjects = 2;
      }

      this.ticketPrices.map((price) => {
        this.config.pricing.push(new ReservedPricing(new ReservedSeatCategory().deserialize(price.ticketType.reservedCategory), price));
      });

      // // loop through the pricing and set the pricing collection
      // this.event.getRESVTicketPrices().map((price) => {
      //     this.config.pricing.push(new ReservedPricing(new ReservedSeatCategory().deserialize(price.ticketType.reservedCategory), price));
      // });

      this.isLoading = false;
      this._setReservedSeats();

    });

  }

  ngOnDestroy() {
  }

  /**
   * Executed from the seatObjectSelected event on the seat map
   *
   * Since the seat is already selected in the seat map, it just needs to be added to the cart
   *
   * @param seat
   */
  public selectSeat(seat: any) {
    this.cart.addReservedItem (
      this.pass,
      new ReservedSeat().deserialize(seat),
      this.config.getTicketPriceByCategoryKey(seat.category.key, seat.selectedTicketType),
      this.channel
    );

    this._setReservedSeats();
  }

  /**
   * Called from seat listing - removes it from the list and also from the seatmap
   *
   * @param seat
   */
  public removeSeat (seat: any) {
    this.cart.removeReservedSeat (
      this.pass,
      new ReservedSeat().deserialize(seat)
    ).subscribe(
      () => this._setReservedSeats()
    );

  }


  /**
   * Called from the seatObjectDeselected event on the seat map
   *
   * It's removed from the seatmap, not it just needs to be removed from the cart
   *
   * @param seat
   */
  public deselectSeat(seat: any) {
    this.cart.items.removeReservedItem (
      this.pass,
      new ReservedSeat().deserialize(seat)
    );
    this.cart.saveCart();
    this._setReservedSeats();
  }

  private _setReservedSeats() {

    const summary: CartItemSummary = this.cart.items.getProductItemSummary(this.pass);

    if (summary) {
      this.reservedSeats$.next(summary.seats);
    } else {
      this.reservedSeats$.next(new Array<CartItem>());
    }
  }

  public setChart (chart: any) {
    this.chart = chart;
  }

}
