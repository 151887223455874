// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { env } from './.env';

// `.env.ts` is generated by the `npm run env` command


export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  fastLaneUrl: env.FASTLANE_URL || 'https://dev-ticketspicket-fastlane.web.app',
  serverUrl: env.API_PASSTHROUGH || 'https://api.dev.events.htt-testing.com/api/ts/v2',
  apiUrl: {
    fanweb: env.FAN_WEB_API_URL || 'https://fan-web-api-dev.ticketspicket.com',
  },
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR'
  ],
  seatsio: {
    apiUrl: 'https://api.seatsio.net',
    publicKey: '36e67f52-a30e-4d22-a257-195341419030',
    encodedKey: 'MDFmYTQzNGUtODZkYi00MjJkLThjZmMtMWI5N2QzYjM5ZmQyOg=='
  },
  googlePayMerchantId: 'BCR2DN4TZCR47PS6',
  eventsUrl: env.UNIFIED_URL || 'https://dev.events.htt-testing.com',
  clerkPublishableKey: 'pk_test_dHJ1c3RpbmctcGlnLTI3LmNsZXJrLmFjY291bnRzLmRldiQ',
  clerkApiUrl: 'clerk.htt-testing.com'
};
