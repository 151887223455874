<div style="overflow:hidden;">
    <div class="container bg-white pb-4" *ngIf="agency && pass" style="position:relative;z-index:100;">
        <app-agency-season-ticket-header [pass]="pass"></app-agency-season-ticket-header>
        <nav aria-label="breadcrumb" class="d-none d-sm-block">
            <ol class="breadcrumb">
                <li class="breadcrumb-item font-sm">
                    <a [routerLink]="['/agency', agency.uuid]">{{ _agencyService.embedded ? 'Events & Passes' : agency.name }}</a>
                </li>
                <li class="breadcrumb-item active font-sm" aria-current="page">Season Ticket Exchange</li>
            </ol>
        </nav>
        <button class="btn btn-sm btn-light btn-block d-sm-none d-block text-center mt-3" [routerLink]="['agencies', agency.uuid]">
            <i class="fa fa-arrow-left mr-2"></i>Back to Agency
        </button>
        <div class="row mt-4 mb-4">

            <div class="col-12 col-md-6">
                <ts-section-header>
                    <i class="fa fa-fw fa-info-circle mr-2"></i>Instructions
                </ts-section-header>
                <div class="pb-3">
                    <ol>
                        <li class="pb-3">Select the seats you would like to exchange</li>
                        <li class="pb-3">Click the "Find Available Seats" button to choose new seats to replace the selected ones</li>
                        <li class="pb-3">Click the <strong>"Exchange Seats"</strong> button to complete the transaction. **If an upgrade is required, enter your payment information and click the <strong>"Pay"</strong> button</li>
                    </ol>
                </div>
                <div class="py-2 text-center" *ngIf="allowPurchase()">
                    <div class="font-weight-bold pb-2">Need More Tickets?</div>
                    <button mdbRippleRadius type="button" class="btn btn-secondary" [routerLink]="purchaseUrl">
                        <i class="fas fa-ticket-alt mr-3"></i>Purchase Additional Seats
                    </button>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <ts-section-header>
                    <i class="fas fa-fw fa-ticket-alt mr-2"></i>Existing Tickets / Seats
                </ts-section-header>
                <div class="text-center py-3" *ngIf="seats.length === 0">
                    <h5 class="h4-responsive px-3 mt-3 border-0" style="font-weight:400;">You have not renewed any season tickets yet.</h5>
                </div>
                <!-- <div class="mb-4">
                    Click each seat that you would like to exchange. When selected, click the "Find Available Seats" button to open the seat map and choose your replacement seats.
                </div> -->
                <table class="table table-sm table-stripped table-hover">
                    <thead>
                        <tr>
                            <th class="w-32 text-center">&nbsp;</th>
                            <th class="text-center">Current Seat</th>
                            <th class="w-sm"></th>
                            <th class="text-center">Move To</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [ngClass]="{'font-weight-light': !renewalService.isSelected(seat)}" [ngClass]="{'font-weight-bold': renewalService.isSelected(seat)}" *ngFor="let seat of seats" (click)="toggleSeat(seat)">
                            <td class="w-32 text-center">
                                <i mat-list-icon class="mt-1 mr-3 fa fa-lg fa-fw" [ngClass]="{'fa-check-square text-success': renewalService.isSelected(seat), 'fa-square-o': !renewalService.isSelected(seat) } "></i>
                            </td>
                            <td class="text-center">
                                {{ seat.seatKey }}
                            </td>
                            <td class="w-sm text-center">
                                <i class="fa fa-exchange mr-5"></i>
                            </td>
                            <td class="text-center">
                                <span *ngIf="!seat.exchangeSeatKey">[ None Selected ]</span>
                                <span *ngIf="seat.exchangeSeatKey">{{ seat.exchangeSeatKey }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="font-sm text-center pt-2" *ngIf="this.getUpgradePrice() <= 0">
                    <span *ngIf="renewalService.selectedSeats.length === 0">No seats have been selected.</span>&nbsp;
                </div>

                <div class="p-3" *ngIf="this.getUpgradePrice() > 0">
                    <p>
                        The seats you have chosen require an additional payment.
                        Please choose your payment method below and click the "Pay" button when you are finished.
                    </p>
                    <div class="mb-3 summary-options text-right">
                        <div class="mb-2 pr-3">Existing Seats Cost: <span style="width: 200px;"><strong>{{getTotalPaid() | currency }}</strong></span></div>
                        <div class="mb-2 pr-3">Total Exchange Seats: <strong>{{getTotalExchange() | currency }}</strong></div>
                        <div class="mb-2 pr-3">Upgrade Charge: <strong>{{getUpgradePrice() | currency }}</strong></div>
                    </div>
                    <ts-section-header>Select Payment Method</ts-section-header>
                    <div>
                        <div class="mb-1">
                            <ngx-braintree-renewal
                                (paymentStatus)="onPaymentStatus($event)"
                                (payButtonStatus)="onPayButtonStatus($event)"
                            >
                            </ngx-braintree-renewal>
                        </div>
                    </div>
                </div>
                <div class="row text-center my-3">
                    <div class="col-6">
                        <button mdbRippleRadius type="button" [disabled]="renewalService.selectedSeats.length === 0" class="btn btn-secondary btn-block" (click)="showSeatMap()">
                            <i class="fa fa-search mr-3"></i>Find Available Seats
                        </button>
                    </div>
                    <div class="col-6" *ngIf="getUpgradePrice() <= 0">
                        <button mdbRippleRadius type="button" [disabled]="!canExchangeSeats()" class="btn btn-secondary btn-block" (click)="exchangeSeats()">
                            <i class="fa fa-exchange mr-3"></i>Exchange Seats
                        </button>
                    </div>
                    <div class="col-6" *ngIf="getUpgradePrice() > 0">
                        <button class="btn btn-secondary btn-block" (click)="checkout()" [disabled]="!enablePayButton">
                            <span><i class="fa fa-credit-card mr-3"></i>Pay {{getUpgradePrice() | currency }} </span>
                        </button>
                        <p class="font-sm text-uppercase font-weight-bold">ALL SALES ARE FINAL</p>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
