import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationVersion } from '../models/application-version.model';
import { map } from 'rxjs/operators';

@Injectable()
export class ApplicationService {

  constructor (
    private _http: HttpClient
  ) { }

  public getVersion(): Observable<ApplicationVersion> {
    return this._http.get<ApplicationVersion[]>('version').pipe (
      map( (versions: ApplicationVersion[]) => versions.filter((version) => version.name === 'web-consumer').map((version) => new ApplicationVersion().deserialize(version))[0])
    );
  }

}
