import { Component, OnInit } from '@angular/core';

import { TransferService } from '@app/data/services/transfer.service';
import { Transfer } from '@app/data/models/transfer.model';
import { Pagination } from '@app/data/models/pagination.model';
import { MessageService } from '@app/shared/ticket-spicket/message.service';

@Component({
  selector: 'app-transfers',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class TransferListingComponent implements OnInit {

  public history: Transfer[] = new Array<Transfer>();
  public pagination: Pagination = new Pagination(6);
  public isLoading = true;
  public noResultsMessage = 'transfers at this time';

  constructor(
    public transferService: TransferService,
    private _messageService: MessageService
  ) { }

  ngOnInit () {
    this.transferService.getTransfers().subscribe();
    this.transferService.getTransferHistory().subscribe();
    this.isLoading = false;
  }

  cancelTransfer(transfer: Transfer) {
    this._messageService.ConfirmYesNo('Are you sure you want to cancel this Ticket Transfer?', 'Are you sure').then(() => {
      this.transferService.cancelTransfer(transfer).subscribe(() => {
        this._messageService.toast.success('Your Ticket Transfer has been cancelled');
        this.ngOnInit();
      });
    });
  }

  acceptTransfer(transfer: Transfer) {
    this._messageService.ConfirmYesNo('Are you sure you want to accept this Ticket Transfer?', 'Are you sure').then(() => {
      this.transferService.acceptTransfer(transfer).subscribe(() => {
        this._messageService.toast.success('Your Ticket Transfer has been accepted');
        this.ngOnInit();
      });
    });
  }

  declineTransfer(transfer: Transfer) {
    this._messageService.ConfirmYesNo('Are you sure you want to decline this Ticket Transfer?', 'Are you sure').then(() => {
      this.transferService.declineTransfer(transfer).subscribe(() => {
        this._messageService.toast.success('Your Ticket Transfer has been declined');
        this.ngOnInit();
      });
    });
  }

  resendTransfer(transfer: Transfer) {
    this._messageService.ConfirmYesNo('Are you sure you want to resend this Ticket Transfer?', 'Are you sure').then(() => {
      this.transferService.resendTransfer(transfer).subscribe(() => {
        this._messageService.toast.success('Your Ticket Transfer has been resent');
        this.ngOnInit();
      });
    });
  }
}
