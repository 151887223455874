<div class="card text-left" style="height:100%;cursor:pointer;border-left:6px solid #2014CC;" [ngStyle]="{'border-color': pass.agency.settings?.primaryColor}">
  <div class="card-body">
    <div class="float-right">
      <svg class="icon" *ngIf="pass.gatePassType.value === 'All Access'">
          <use xlink:href="#allAccess"></use>
      </svg>
      <svg class="icon" *ngIf="pass.gatePassType.value === 'Season'">
          <use attr.xlink:href="#{{pass.season.iconClassName}}"></use>
      </svg>
      <svg class="icon" *ngIf="pass.gatePassType.value === 'Activity'">
          <use attr.xlink:href="#{{pass.activity.typeActivity.iconClassName}}"></use>
      </svg>
      <svg class="icon" *ngIf="pass.gatePassType.value === 'Event'">
          <use *ngIf="pass.activity" attr.xlink:href="#{{pass.activity.typeActivity.iconClassName}}"></use>
      </svg>
    </div>
    <h5 class="card-title name">{{ pass.name }}</h5>
    <p class="card-text description">{{ pass.description }}</p>
    <div class="card-text" *ngIf="pass.prices.length > 0">
      <div class="details" *ngFor="let price of pass.prices">
        <div>
          <div class="float-right">{{ price.priceAmount | currency }}</div>
          {{ price.displayName }} 
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer py-1 px-3">
    <div class="text-right">
      <a class="buy">Buy Passes
        <i class="fa fa-arrow-right ml-1"></i>
      </a>
    </div>
  </div>
</div>
