import { Injectable } from '@angular/core';
import { parsePublishableKey } from '@clerk/shared';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    // Please keep the ts-ignore here for now
    // This is a known issue that will be fixed soon
    // @ts-ignore
    Clerk?: Clerk;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ClerkService {

  public clerkPublishableKey = environment.clerkPublishableKey;

  public user$ = new BehaviorSubject(null);
  public session$ = new BehaviorSubject(null);
  public isAuthenticated$ = new BehaviorSubject(null);
  public initialized$ = new BehaviorSubject(false);

  constructor() {
    this.isAuthenticated$.next(false);
    if (!this.clerkPublishableKey) {
      throw new Error('Clerk Publishable Key not set');
    }
    this.loadScript();
  }

  private loadScript() {
    const clerkService = this; // Due to the scope of the dynamic script, you must store a reference to this service
    clerkService.isAuthenticated$.next(false);

    const { frontendApi } = parsePublishableKey(this.clerkPublishableKey);

    // load script
    const script = document.createElement('script');
    script.setAttribute('data-clerk-publishable-key', this.clerkPublishableKey);
    script.src = `https://${frontendApi}/npm/@clerk/clerk-js@4/dist/clerk.browser.js`;
    script.crossOrigin = 'anonymous';
    script.addEventListener('load', async () => {
      await window.Clerk.load();
      window.Clerk.addListener(async (clerkListenerResult) => {
        clerkService.initialized$.next(true);
        clerkService.user$.next(clerkListenerResult.user);
        clerkService.session$.next(clerkListenerResult.session);
        clerkService.isAuthenticated$.next(clerkListenerResult.user !== null && clerkListenerResult.user !== undefined);
      });
    });
    script.addEventListener('error', () => {
      document.getElementById('no-frontend-api-warning').hidden = false;
    });

    document.body.appendChild(script);
  }


  public signOut() {
    window.Clerk.signOut();
  }
}
