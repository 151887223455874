import { IDeserializable } from '@app/data/models/deserializable.interface';
import { Event } from '@app/data/models/event.model';
import { Agency } from '@app/data/models/agency.model';
import { TicketLevel } from '@app/data/models/ticket-level.model';
import * as moment from 'moment';
import { IAdmission, AdmissionSource, AdmissionActions } from './tickets/admission.interface';
import { ReservedSeat } from './reserved/seat.model';

export class Ticket implements IDeserializable, IAdmission {

  id: number;
  ticketNumber: string;
  uuid: string;
  bundleUUID: string;
  event: Event;
  level: TicketLevel;
  agency: Agency;

  seat: ReservedSeat;
  seatNumber: string;

  printable: boolean;
  transferable: boolean;
  transferred: boolean;
  shared: boolean;
  isPrinted: boolean;
  isSynchronized: boolean;

  dateRedeemed: Date;
  redemptions: any[];

  paidAmount: number;
  discountAmount: number;
  feeAmount: number;

  public source: string = AdmissionSource.ticket;

  constructor() {}

  deserialize(input: any) {

    Object.assign(this, input);

    if (input.event) {
      this.event = new Event().deserialize(input.event);
    }

    if (input.school) {
      this.agency = new Agency().deserialize(input.school);
    }

    if (input.level) {
      this.level = new TicketLevel().deserialize(input.level);
    }

    if (input.seatNumber) {
      this.seat = new ReservedSeat().deserialize(input.seatNumber);
    }

    if (input.dateRedeemed) {
      this.dateRedeemed = moment(input.dateRedeemed).toDate();
    }

    return this;

  }

  public getActions(): string[] {
    const actions: string[] = new Array<string>();

    actions.push(AdmissionActions.buy);

    if (!this.transferred) {
      actions.push(AdmissionActions.view);
    } else {
      actions.push(AdmissionActions.cancel);
    }

    if (this.printable) {
      actions.push(AdmissionActions.print);
    }

    if (this.transferable) {
      actions.push(AdmissionActions.transfer);
    }

    return actions;

  }

}
