<div *ngIf="agency" class="submenu">
    <div class="container py-3 pr-3 submenu-margin">
        <div *ngIf="agency.settings?.logoURI" (click)="goToAgency()" class="pointer logo d-lg-none" [ngStyle]="{'background-image': 'url(' + agency.settings?.logoURI + ')'}"></div>
        <div *ngIf="!agency.settings?.logoURI" (click)="goToAgency()" class="pointer logo default d-lg-none text-center" [ngStyle]="{'background-color': agency.settings?.primaryColor }">{{ agency.name | limitTo: 1 }}</div>
        <span (click)="goToAgency()" class="text-uppercase" style="font-size: 1.2rem;"
            [ngClass]="{ 'pointer': !disableNav }">
            <strong>{{ agency.name }}</strong>
        </span>
        <span (click)="goToAgency()" [ngClass]="{ 'pointer': !disableNav }">
            <span class="d-none d-lg-inline">&bull; </span>
            <br class="d-inline-block d-lg-none" />{{agency.primaryAddress?.cityState}}
        </span>
        <span *ngIf="forecast">
            <span class="d-none d-lg-inline">- </span>
            <br class="d-inline-block d-lg-none" />{{forecast.current}}&#8451;
        </span>
        <div class="ml-1 d-inline-block" style="line-height:10px;" *ngIf="agency.settings?.facebookURI || agency.settings?.twitterURI || agency.settings?.instagramURI">
            <span class="d-none d-lg-inline">- </span>
            <br class="d-inline-block d-lg-none" />
            <div class="d-inline" *ngIf="!mobileService.isCapacitor">
                <a [href]="agency.settings?.facebookURI" target="_blank" *ngIf="agency.settings?.facebookURI"><i class="fa fa-facebook ml-1 mr-2 text-white"></i></a>
                <a [href]="agency.settings?.twitterURI" target="_blank" *ngIf="agency.settings?.twitterURI"><i class="fa fa-twitter mr-2 text-white"></i></a>
                <a [href]="agency.settings?.instagramURI" target="_blank" *ngIf="agency.settings?.instagramURI"><i class="fa fa-instagram mr-2 text-white"></i></a>
            </div>
            <div class="d-inline" *ngIf="mobileService.isCapacitor">
                <a (click)="mobileService.openURL(agency.settings?.facebookURI)" *ngIf="agency.settings?.facebookURI"><i class="fa fa-facebook ml-1 mr-2 text-white"></i></a>
                <a (click)="mobileService.openURL(agency.settings?.twitterURI)" *ngIf="agency.settings?.twitterURI"><i class="fa fa-twitter mr-2 text-white"></i></a>
                <a (click)="mobileService.openURL(agency.settings?.instagramURI)" *ngIf="agency.settings?.instagramURI"><i class="fa fa-instagram mr-2 text-white"></i></a>
            </div>
        </div>
    </div>
</div>
