import { Component, Input } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';

@Component({
  selector: 'ts-pass-list-item',
  templateUrl: './pass-list-item.component.html',
  styleUrls: ['./pass-list-item.component.scss']
})

export class PassListItemComponent {
  @Input() pass: GatePass;
  @Input() schoolView: boolean;
  @Input() isLinked = false;
  @Input() configMode: boolean;

  constructor() {}

}
