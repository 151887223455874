import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, interval, firstValueFrom } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { PurchaseConfirmation } from '@app/data/models/purchase-confirmation.model';
import { PromotionCode } from '../models/promotion-code.model';
import { Promotion } from '../models/promotion.model';
import { ReservedSeat } from '../models/reserved/seat.model';
import { ReservedSeatsService } from './reserved-seats.service';
import { CartItemCollection } from '../models/cart/cart-item-collection.model';
import { PaymentMethod } from '../models/payment-method.model';
import { CartItem, CartItemProduct } from '../models/cart/cart-item.model';
import { CartExpiration } from '../models/cart/cart-expiration.model';
import { CartItemEventSummary } from '../models/cart/cart-item-event';
import { CartItemPassSummary } from '../models/cart/cart-item-pass';
import { Event } from '../models/event.model';
import { CartItemTypes } from '../models/cart/cart-item-types.enum';
import { GatePass } from '../models/passes/gate-pass.model';
import { TicketPrice } from '../models/ticket-price.model';
import { IDeserializable } from '../models/deserializable.interface';
import { ReservedHoldToken } from '../models/reserved/configuration.model';
import { EventStoreChannel } from '../models/events/event-store-channel.model';

/**
 * This interface is used to communicate with the API
 *
 */
export interface ICart {

  items: any[];
  bundledItems: any[];
  method: PaymentMethod;
  holdToken: string;
  totalAmount: number;
  promotionCode: string;
  promotion?: Promotion;
  other?: CartOtherFees;
  phoneNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export class CartOtherFees implements IDeserializable {
  name: string = null;
  amount = 0;

  constructor() { }

  public deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

}

@Injectable()
export class CartService {

  storageKey = 'com.ticketspicket.cart';

  public expiration: CartExpiration = new CartExpiration();
  public interval: any;
  public countdown: string;
  public purchaseId: string;
  public items: CartItemCollection = new CartItemCollection();
  public bundledItems: CartItemCollection = new CartItemCollection();

  public holdToken: ReservedHoldToken = new ReservedHoldToken();

  public hasPaymentMethod = false;
  public paymentMethod: PaymentMethod;

  public promotionCode: PromotionCode = new PromotionCode();

  public errors: Object;
  public processing = false;
  public nonce: string;

  public other: CartOtherFees = new CartOtherFees();

  public confirmation: PurchaseConfirmation;
  public phone = '';
  public email = '';
  public firstName = '';
  public lastName = '';

  public embedded = false;
  public embeddedAgencyId = 'unknown';

  public isGuestCheckout = false;

  constructor(private _http: HttpClient, private _reserved: ReservedSeatsService) {
    this._loadCartFromStorage();
  }

  public get cartRoute(): string {
    if (this.embedded) {
      return '/embed/agency/' + this.embeddedAgencyId + '/cart/';
    }
    return '/cart';
  }

  private _startExpirationClock(dateAdded?: Date) {
    if (!this.interval) {
      this.expiration.start(dateAdded);
      this.interval = interval(1000).subscribe(() => this._checkExpiration());
    }
  }

  /**
   * Checks to see if the cart is expired - if true, it clears the cart
   *
   */
  private _checkExpiration() {
    this.countdown = moment.utc(moment(this.expiration.dateExpiration).diff(moment())).format('mm:ss');
    if (this.expiration.isExpired()) {
      this.clearCart();
    }
  }

  public setIsGuestCheckout(value: boolean) {
    this.isGuestCheckout = value;
    this.saveCart();
  }

  /**
   * returns true if there are no items in the cart
   */
  public isEmpty(): boolean {
    return this.items.isEmpty();
  }

  /**
   * returns the number of items in the cart
   */
  public itemCount(): number {
    return this.items.items.length;
  }

  public getEventItems(): CartItemEventSummary[] {
    return this.items.getEventItems();
  }
  public getProductItemSummary(product: CartItemProduct): CartItemEventSummary | CartItemPassSummary {
    return this.items.getProductItemSummary(product);
  }

  public getPassItems(): CartItemPassSummary[] {
    return this.items.getPassItems();
  }

  public getTotalFees(): number {
    return this.items.getTotalFees();
  }

  public getOtherFees(): number {
    return this.other.amount;
  }

  public getDiscount(): number {
    return this.promotionCode.amountDiscount;
  }

  public getTotalPrice(): number {
    return this.items.getTotalPrice() + this.getOtherFees() - this.getDiscount();
  }

  /**
   * returns the number of items in the cart
   */
  public getItemCount(): number {
    return this.getEventItems().length + this.getPassItems().length;
  }

  public getFinalSalesLanguage(): string {
    return 'All sales are final and non-refundable.';
  }

  public hasItem(product: CartItemProduct): boolean {
    return this.items.hasProduct(product);
  }
  
  public clearOtherEventItems(product: CartItemProduct) {
    const firstUuid = this.getProductItemSummary(product).product.uuid;
    const badItems = [];
    const items = this.items.items;
    items.forEach(item => {
        const itemUuid = this.getProductItemSummary(item.product).product.uuid;
        if (itemUuid !== firstUuid) {
            badItems.push(item);
        }
    });
    badItems.forEach(item => {
        this.removeItem(item);
        this.saveCart();
    });
  }

  public addReservedItem(
    product: CartItemProduct,
    seat: ReservedSeat,
    price: TicketPrice,
    channel: EventStoreChannel
  ) {
    this.items.addReservedItem(product, seat, price, channel);
    this._startExpirationClock();
    this.extendHoldToken().subscribe(() => {
      this.saveCart();
    }, (error) => {
      console.error(error);
      this.saveCart();
    });
    this.saveCart();
    this.clearOtherEventItems(product);
  }

  public async addSeatRenewal(
    product: CartItemProduct,
    seat: ReservedSeat,
    price: TicketPrice,
    channel: EventStoreChannel) {
    this.items.addReservedItem(product, seat, price, channel);
    this._startExpirationClock();
    await this.getHoldTokenPromise();
    this.saveCart();
    this.clearOtherEventItems(product);
  }
  /**
   * Adds an independent collection of items to the item collection
   *
   * @param items
   */
  public addItems(items: CartItemCollection) {
    this.items.fold(items.items);
    this._startExpirationClock();
    this.saveCart();
    items.items.forEach(item => {
      this.clearOtherEventItems(item.product);
    });
  }

  /**
   * removes an item from the cart
   *
   * @param item
   */
  public removeItem(item: CartItem) {
    if (item.isReserved) {
      if (this.holdToken.isExpired()) {
        this.items.removeReservedItem(item.product, item.seat);
      } else {
        this.removeReservedSeat(item.product, item.seat).subscribe();
      }
      this.saveCart();
    } else {
      this.items.removeItem(item.product, item.ticketPrice);
      this.saveCart();
    }
  }

  public removeBundleItem(item: CartItem) {
    if (item.isReserved) {
      if (this.holdToken.isExpired()) {
        this.bundledItems.removeReservedItem(item.product, item.seat);
      } else {
        this.removeReservedSeat(item.product, item.seat).subscribe();
      }
      this.saveCart();
    } else {
      this.bundledItems.removeItem(item.product, item.ticketPrice);
      this.saveCart();
    }
  }

  /**
   * remove the selcted seat from the cart
   * @param seat
   */
  public removeReservedSeat(product: CartItemProduct, seat: ReservedSeat): Observable<boolean> {
    return this._reserved.deselectSeat(product, seat, this.holdToken.holdToken).pipe(
      catchError(error => {
        console.error(error);
        return of([]);
      }),
      tap(() => {
        this.items.removeReservedItem(product, seat);
        this.saveCart();
        return true;
      }),
    );
  }

  /**
   * Get hold token from seats.io
   *
   */
  public getHoldToken(): Observable<string> {

    if (this.holdToken.isExpired()) {
      return this._reserved.generateHoldToken().pipe(
        map((token) => this.holdToken = token),
        map(() => this.holdToken.holdToken)
      );
    } else {
      return of(this.holdToken.holdToken);
    }

  }

  public async getHoldTokenPromise(): Promise<string> {
    return (await firstValueFrom(this._reserved.generateHoldToken().pipe(map((token) => this.holdToken = token)))).holdToken;
  }

  /**
   * Update hold token expiration time from seats.io
   *
   */
  public extendHoldToken(): Observable<any> {
    if (this.holdToken.holdToken) {
      return this._reserved.extendHoldToken(this.holdToken.holdToken).pipe(
        map((token) => this.holdToken = token),
        map(() => this.holdToken)
      );
    } else {
      this.getHoldToken();
    }

    return null;
  }

  /**
   * Saves the cart to local storage
   *
   */
  public saveCart() {

    const cart: any = {
      dateAdded: this.expiration.dateAdded,
      items: this.items.items,
      bundledItems: this.bundledItems.items,
      holdToken: this.holdToken,
      promotionCode: this.promotionCode,
      totalAmount: this.getTotalPrice(),
      isGuestCheckout: this.isGuestCheckout
    };

    this.calculateCart().subscribe(() => {
      if (this.isEmpty()) {
        this.clearCart();
      } else {
        localStorage.setItem(this.storageKey, JSON.stringify(cart));
      }
    });

  }

  /**
   * removes all of the items from the cart and saves it
   *
   */
  public clearCart(purchased: boolean = false) {
    this.isGuestCheckout = false;
    if (this.interval) {
      this.interval.unsubscribe();
    }

    this.countdown = null;
    this.interval = null;
    this.expiration = new CartExpiration();

    // need to loop backwards in order to ensure all of the items are cleared
    for (let i = this.items.items.length - 1; i >= 0; i--) {
      if (purchased) {
        this.items.removeItem(this.items.items[i].product, this.items.items[i].ticketPrice);
      } else {
        this.removeItem(this.items.items[i]);
      }
    }

    if (this.items.items.length > 0) {
      // need to loop backwards in order to ensure all of the bundled items are cleared
      for (let i = this.bundledItems.items.length - 1; i >= 0; i--) {
        if (purchased) {
          this.bundledItems.removeItem(
            this.bundledItems.items[i].product,
            this.bundledItems.items[i].ticketPrice
          );
        } else {
          this.removeItem(this.bundledItems.items[i]);
          this.removeBundleItem(this.bundledItems.items[i]);
        }
      }
    }

    localStorage.removeItem(this.storageKey);
  }

  /**
   * Returns true if the attempt to load the cart from storage is successful
   *
   * If the stored cart is expired, it clears it
   *
   */
  private _loadCartFromStorage(): boolean {

    const storedCart = localStorage.getItem(this.storageKey);

    if (storedCart) {

      try {

        const cart: any = JSON.parse(storedCart);

        this.holdToken = new ReservedHoldToken().deserialize(cart.holdToken);

        if (this.holdToken.holdToken && this.holdToken.isExpired()) {
          this.clearCart();
          return false;
        } else {

          const addItems = (itemList: CartItemCollection) => (item: CartItem) => {

            let product: CartItemProduct;

            if (item.itemType === CartItemTypes.event) {
              product = new Event().deserialize(item.product);
            }

            if (item.itemType === CartItemTypes.pass) {
              product = new GatePass().deserialize(item.product);
            }

            if (item.seat) {
              itemList.addReservedItem(
                product,
                new ReservedSeat().deserialize(item.seat),
                new TicketPrice().deserialize(item.ticketPrice),
                item.channel
              );
            } else {
              itemList.addItem(
                product,
                new TicketPrice().deserialize(item.ticketPrice),
                item.selectedQty,
                item.members,
                item.channel
              );
            }

          };

          // loops through the items and adds them to the cart proper
          cart.items.map(addItems(this.items));

          // loops through the bundled items and adds them to the cart proper
          cart.bundledItems.map(addItems(this.bundledItems));

          this.promotionCode = new PromotionCode().deserialize(cart.promotionCode);
          this._startExpirationClock(moment(cart.dateAdded).toDate());
          this.calculateCart().subscribe();

        }
      } catch (error) {
        console.error(error);
        this.clearCart();
        return false;
      }

      return true;
    }
    return false;
  }

  private _serialize(): ICart {
    return {
      items: this.items.serialize(),
      bundledItems: this.bundledItems.serialize(),
      holdToken: this.holdToken.holdToken,
      totalAmount: this.getTotalPrice(),
      promotionCode: this.promotionCode.code,
      method: this.paymentMethod,
      phoneNumber: '',
      email: '',
      firstName: '',
      lastName: '',
    };
  }

  public calculateCart(): Observable<ICart> {

    const url = 'fans/payments/new-calculate-cart';
    const cart = this._serialize();
    cart.bundledItems.map((item) =>
      cart.items.push(item)
    );
    return this._http.post<ICart>(url, cart).pipe(
      tap((returnCart) => {
        this.items.setCalculations(returnCart.items);
        if (returnCart.promotion) {
          this.promotionCode.setPromotion(returnCart.promotion);
        } else {
          this.promotionCode = new PromotionCode().create(null);
        }
        if (returnCart.other) {
          this.other = new CartOtherFees().deserialize(returnCart.other);
        } else {
          this.other = new CartOtherFees();
        }
      })
    );

  }

  /**
   *
   *
   */
  public setPaymentMethod(paymentMethod: any) {
    this.paymentMethod = paymentMethod;
  }

  /**
   *
   */
  public getClientToken(): Observable<string> {
    let url = '';
    if (!this.isGuestCheckout) {
      url = 'fans/payments/client-token';
    } else {
      url = 'fans/guest/client-token';
    }
    return this._http.get<string>(url).pipe(
      map((response: any) => this.nonce = response.token)
    );
  }

  public checkout(paymentMethod: any): Observable<any> {
    this.setPaymentMethod(paymentMethod);
    const cart = this._serialize();

    cart.bundledItems.map((item) =>
      cart.items.push(item)
    );

    const phone = paymentMethod.phoneNumber;
    const email = paymentMethod.email;
    const firstName = paymentMethod.firstName;
    const lastName = paymentMethod.lastName;
    let url = '';
    if (this.isGuestCheckout) {
      url = 'fans/guest/new-checkout';
      cart.phoneNumber = '1' + paymentMethod.phoneNumber.replace(/[^\d]/g, '');
      cart.email = paymentMethod.email;
      cart.firstName = paymentMethod.firstName;
      cart.lastName = paymentMethod.lastName;
    } else {
      url = 'fans/payments/new-checkout';
    }

    return this._http.post<any>(url, cart).pipe(
      map((confirmation) => {
        this.purchaseId = confirmation.payment.uuid;
        this.confirmation = confirmation;
        this.phone = phone;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.clearCart(true);
      })
    );
  }

  public hasPromoCode(): boolean {
    return !_.isEmpty(this.promotionCode.code);
  }

  public hasOtherFees(): boolean {
    return !_.isEmpty(this.other.name);
  }

  /**
   * Method that takes a promotion code and attempts to apply it to a given cart item
   *
   * @param code string
   *
   */
  public applyPromotionCode(code: string): Observable<ICart> {

    this.promotionCode = new PromotionCode().create(code);
    return this.calculateCart();

  }

  public getItemsLog() {
    if (this.getItemCount() > 0) {
      const cartItems = [];
      this.items.getEventItems().forEach(i => {
        cartItems.push({
          id: i.product.id,
          product: 'event',
          prices: i.ticketPrices.map(p => ({
            quantity: p.quantity,
            price: p.price.id
          }))
        });
      });
      this.items.getPassItems().forEach(i => {
        cartItems.push({
          id: i.product.id,
          product: 'pass',
          prices: i.ticketPrices.map(p => ({
            quantity: p.quantity,
            price: p.price.id
          }))
        });
      });
      return cartItems;
    }
    return [];
  }
}
