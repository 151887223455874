import { IDeserializable } from './deserializable.interface';
import { ISchool } from '@app/data/models/school.interface';
import { Address } from '@app/data/models/address.model';
import { AccountSettings } from '@app/data/models/settings.model';

export class AgencyGeneric implements IDeserializable, ISchool {

  public id: number;

  public schoolName: string;
  public lat: number;
  public lng: number;
  public streetAddress: string;
  public cityState: string;
  public city: string;
  public state: string;
  public zip: string;
  public zipPlus: string;
  public phone: string;

  public districtName: string;
  public countyName: string;

  public isCustomer: boolean;
  public isCharter: boolean;
  public isMagnet: boolean;
  public isTitleOne: boolean;
  public isTitleOneSchoolWide: boolean;

  public studentCount: number;
  public teacherCount: number;
  public freeLunchCount: number;
  public reducedLunchCount: number;

  public ncesSchoolID: string;
  public stateSchoolID: string;
  public ncesDistrictID: string;
  public stateDistrictID: string;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);

    return this;
  }

  get uuid(): string {
    return this.id.toString();
  }

  get name(): string {
    return this.schoolName;
  }

  get primaryAddress(): Address {
    const address: Address = new Address();
    address.line1 = this.streetAddress;
    address.city = this.city;
    address.state = this.state;
    address.zipcode = this.zip;
    address.lat = this.lat;
    address.lng = this.lng;
    address.cityState = this.cityState;
    address.name = this.schoolName;
    address.formatted = this.cityState;
    return address;
  }

  get settings(): AccountSettings {
    return (new AccountSettings());
  }

  get website(): string {
    return null;
  }

  get nameMascot(): string {
    return null;
  }

  getType(): string {
    return 'generic';
  }

}
