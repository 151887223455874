import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Agency } from '@app/data/models/agency.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PassService } from '@app/data/services/pass.service';
import { ConsumerPass } from '@app/data/models/passes/consumer-pass.model';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { BehaviorSubject } from 'rxjs';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
import { EventFilter } from '@app/data/models/events/event-filter.model';
import { EventFilterModalComponent } from '@app/agency/events/filter.component';
import * as _ from 'lodash';
import { ClerkService } from '@app/core/authentication/clerk.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-consumer-pass-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class ConsumerPassesDetailComponent implements OnInit, AfterContentChecked {


  public passUuid: string;
  public agency: Agency;
  public pass: ConsumerPass;
  public _events: EventAdmission[] = new Array<EventAdmission>();
  public eventsUrl: string = environment.eventsUrl; // This is the new Events URL on HTT

  public filter: EventFilter = new EventFilter();
  public filteredEvents$ = new BehaviorSubject<EventAdmission[]>(new Array<EventAdmission>());

  constructor(
    private _route: ActivatedRoute,
    private _passService: PassService,
    public _modal: NgbModal,
    public _userAdmissionService: UserAdmissionService,
    private _spinner: SpinnerService,
    private _clerkService: ClerkService
  ) {
    this._spinner.setMessage('Loading Passes');
  }

  ngOnInit() {
    // this._spinner.show();
    // get the pass based on the pass-index route parameter
    this._route.params.pipe(
      map(params => params['pass-id']),
    )
      .subscribe(id => {

        this._passService.getPasses().subscribe(() => {

          this.passUuid = id;
          this._passService.myPasses().subscribe((passes) => {

            if (passes.length === 0) {
              return;
            }
            const passEvents = new Array<EventAdmission>();

            this.pass = _.find(passes, { uuid: this.passUuid });
            this._passService.getAvailablePassEvents(this.pass.id).subscribe((events) => {
              events.map((event) => this.pass.addEvent(event));
              events.map(event => {
                const ea: EventAdmission = new EventAdmission(event);
                // loop through the passes and get the event availability
                const pass: ConsumerPass = new ConsumerPass(this.pass.gatePass);
                this._passService.getPassEventAvailability(this.pass.gatePass.id, event.uuid)
                  .subscribe((consumers) => {
                    pass.consumers = consumers;
                  });
                ea.admission.push(pass);
                passEvents.push(ea);
              });
              // loop through the events and add any tickets they might have
              passEvents.map((event) => {
                if (this._userAdmissionService.hasEventTickets(event.event)) {
                  this._userAdmissionService.getEventAdmission(event.event).getTickets().map((ticket) => {
                    event.admission.push(ticket);
                  });
                }
              });
              this._events = passEvents;
              this.filter.setEvents(this._events.map((event) => event.event));
              this.filteredEvents$.next(this.getFilteredTickets());
              this._spinner.hide();
            });
          });
        });

      });

  }

  ngAfterContentChecked() {
  }

  showFilter() {
    const modalRef = this._modal.open(EventFilterModalComponent, { centered: true });
    modalRef.componentInstance.filter = this.filter;
    modalRef.componentInstance.showAgenciesFilter = true;
    modalRef.result.then(() => {
      this.filteredEvents$.next(this.getFilteredTickets());
    }, () => {
      this.filteredEvents$.next(this.getFilteredTickets());
    });
  }

  public getFilteredTickets() {
    return _.chain(this._events)
      .filter(admission => this.filter.getEvents().findIndex((filter) => filter.id === admission.event.id) > -1)
      .orderBy(admission => admission.event.dateStart)
      .value();
  }
}
