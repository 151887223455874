import { Component, Input } from '@angular/core';
import { Agency } from '@app/data/models/agency.model';

@Component({
  selector: 'ts-agency-card',
  templateUrl: './agency-card.component.html',
  styleUrls: ['./agency-card.component.scss']
})

export class AgencyCardComponent {

  @Input() agency: Agency;

  constructor() {
  }

}
