import { Injectable } from '@angular/core';

@Injectable()
export class MobileService {

  public deviceInfo: any;
  public isCapacitor = false;

  async deviceCheck() {
    // comment below line for web application
    // this.deviceInfo = await Device.getInfo();
  }

  async openURL() {
    // comment below line for web application
    // await Browser.open({ 'url': url });
  }

}
