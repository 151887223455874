import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ConsumerPass } from './models/passes/consumer-pass.model';
import { UserAdmissionService } from './services/user-admission.service';

@Injectable()
export class PassesResolver implements Resolve<ConsumerPass[]> {

  constructor (
    private _userAdmissionService: UserAdmissionService,
    private router: Router
  ) {}

  resolve (): Observable<ConsumerPass[]> {
    return this._userAdmissionService.getPasses().pipe(
      catchError(() => {
        this.router.navigate(['/']);
        return of (null);
      }),
      take(1)
    );
  }

}
