import { Component, OnInit, Input } from '@angular/core';
import { TicketService } from '@app/data/services/ticket.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';

@Component({
  selector: 'app-ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss']
})
export class TicketModalComponent implements OnInit {

  @Input() eventTicket: EventAdmission;

  redeemProcess = false;

  constructor (
    public activeModal: NgbActiveModal,
    public _ticketService: TicketService
  ) { }

  ngOnInit() { }

  redeemProcessStarted() {
    this.redeemProcess = true;
  }

  close() {
    this.activeModal.dismiss(false);
    if (this.redeemProcess) {
      this._ticketService.getTickets().subscribe();
    }
  }

}
