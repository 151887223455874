<div class="modal-body p-0 pb-4 mb-3">
    <button type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <ts-ticket-header [agency]="admission.event.agency"></ts-ticket-header>
    <ts-ticket-event-header [event]="admission.event"></ts-ticket-event-header>
    <div class="mb-2 p-3 text-left" style="overflow:hidden; position:relative;" *ngIf="admission.getUnclaimedTicketCount() > 0">
        <h5 class="border-top border-bottom text-center py-2 text-uppercase">
            Use your Pass to Claim Tickets
        </h5>
        <div class="alert alert-warning font-sm text-center mb-4" style="border-radius:0;">
            <strong>Please note:</strong> Below is a list of available tickets that can be claimed using your pass(es). Select which tickets you are ready to claim.
        </div>
        <div>
            <!-- <div class="text-center mb-2 text-uppercase">
                <strong>If you are ready, claim all of your available tickets.</strong>
            </div> -->
            <div class="check-in-all text-center">
                <button type="button" class="btn btn-success btn-block" (click)="selectAll()" [disabled]="isLoading">
                    <i class="fa fa-check-circle mr-2"></i>CLAIM ALL TICKETS
                </button>
            </div>
        </div>
        <div class="text-center row my-2 font-sm align-items-center">
            <div class="col-5"><hr /></div>
            <div class="col-2">OR</div>
            <div class="col-5"><hr /></div>
        </div>
        <div class="text-center mb-2 text-uppercase">
            <strong>Select individual tickets from your pass below.</strong>
        </div>
        <div *ngFor="let pass of passes">
            <mat-list style="background:transparent;">
                <mat-list-item class="border rounded mb-2" [ngClass]="{'border-success': isConsumerSelected(consumer.uuid)}" style="background:white;height:62px;cursor:pointer;" *ngFor="let consumer of pass.getUnclaimedTickets()" (click)="toggleConsumer(consumer.uuid)">
                    <i mat-list-icon class="mt-1 fa fa-lg fa-fw" [ngClass]="{'fa-check-square text-success': isConsumerSelected(consumer.uuid), 'fa-square-o': !isConsumerSelected(consumer.uuid) } "></i>
                    <p class="h5" mat-line><strong>{{ consumer.holderName }}</strong></p>
                    <p class="h5" mat-line>{{ consumer.level.title }}</p>
                    <p class="h5 font-sm text-muted" mat-line>{{pass.gatePass.name}}</p>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="check-in-all text-center mb-2 mt-3">
            <button type="button" class="btn btn-secondary btn-block" (click)="claimTickets()" [disabled]="isLoading || selectedConsumers.length==0">
                <i class="fa fa-check mr-2"></i>CLAIM SELECTED TICKETS
            </button>
        </div>
    </div>
    <div class="p-3" *ngIf="admission.getClaimedTicketCount() > 0">
        <div class="h5 border-bottom text-center py-2 text-uppercase">
            <strong>Your Claimed Tickets</strong>
        </div>
        <mat-list style="background:transparent;" [ngbCollapse]="isCollapsed">
            <div *ngFor="let pass of passes">
                <mat-list-item *ngFor="let consumer of pass.getClaimedTickets()" style="background:white;height:62px;" class="border-bottom mb-2">
                    <i mat-list-icon class="fas fa-lg fa-ticket-alt text-success"></i>
                    <p class="h5" mat-line><strong>{{ consumer.holderName }}</strong></p>
                    <p class="h5" mat-line>{{ consumer.level.title }} <span class="font-sm text-muted">(#{{consumer.ticket?.ticketNumber}})</span></p>
                    <p class="h5 font-sm text-muted" mat-line>{{pass.gatePass.name}}</p>
                </mat-list-item>
            </div>
        </mat-list>
        <div class="text-center py-3">
            <button type="button" class="btn btn-primary btn-block text-uppercase" (click)="viewTickets()">
                <i class="fas fa-ticket-alt mr-2"></i>View Tickets
            </button>
        </div>
    </div>
</div>
<div class="text-center modal-close-button">
    <button type="button" class="btn btn-light" aria-label="Close" style="min-width:150px;" (click)="close()" [disabled]="isLoading || selectedConsumers.length===0">Close</button>
</div>
<ngx-spinner name="modal-spinner" [fullScreen]="false" type="ball-spin-clockwise" size="medium"></ngx-spinner>