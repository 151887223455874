<mat-sidenav-container [@.disabled]="true">
    <mat-sidenav [@.disabled]="true" #sidenav mode="over" class="mat-side-nav">
        <div class="background-gradient"></div>
        <div style="z-index:2;position:relative;">
            <mat-nav-list>
                <a mat-list-item class="d-block" routerLink="/home" routerLinkActive="active" (click)="sidenav.toggle()" aria-label="Button that takes you to the home search page.">
                    <i class="fa fa-search mr-2" style="font-weight: 400;"></i>
                    <span>Search</span>
                    <div style="clear:both;"></div>
                </a>
                <a mat-list-item class="d-block" routerLink="/tickets" routerLinkActive="active" (click)="sidenav.toggle()" aria-label="Button that takes you to the Tickets page.">
                    <i class="fas fa-ticket-alt mr-2 mt-1" style="font-weight: 700;"></i>
                    <span>My Tickets</span><span [matBadge]="ticketBadgeCount" [matBadgeHidden]="ticketBadgeCount == 0" matBadgeColor="danger"></span>
                    <div style="clear:both;"></div>
                </a>
                <a mat-list-item class="d-block" href="{{eventsUrl}}/passes" (click)="sidenav.toggle()" aria-label="Button that takes you to the Passes page.">
                    <i class="fa fa-tag mr-2" style="font-weight: 400;"></i>
                    <span>My Passes</span>
                    <div style="clear:both;"></div>
                </a>
                <a mat-list-item class="d-block" routerLink="/auth/login" *ngIf="!isAuthenticated()" routerLinkActive="active" (click)="sidenav.toggle()" aria-label="Button that takes you to the Login page.">
                    <i class="fa fa-user mr-1"></i>
                    <span>Login or Sign Up</span>
                    <div style="clear:both;"></div>
                </a>
            </mat-nav-list>
        </div>
    </mat-sidenav>
    <mat-sidenav-content [@.disabled]="true">
        <header class="d-block d-md-none" style="margin-bottom:54px;">
            <div *ngIf="mobileService.deviceInfo?.model === 'iPhone'" class="mobile-top"></div>
            <nav class="navbar navbar-expand-lg navbar-dark mb-0 fixed-top" style="background-color:#004080; display:block;">
                <button mat-button aria-label="Navbar Dropdown" class="p-0" style="min-width:40px;font-size:1.3rem;color:white" (click)="sidenav.toggle()">
                        <i class="fa fa-bars"></i>
                </button>
                <div class="float-right">
                    <button mat-button aria-label="Navbar Account" routerLink='/account' class="p-0" style="min-width:40px;font-size:1.3rem;color:white">
                        <i class="fas fa-user fa-overide"></i>
                    </button>
                </div>
                <div style="clear:both;"></div>
            </nav>
        </header>
        <app-header class="d-none d-md-block"></app-header>
        <!-- Transfer Alert -->
        <div class="alert alert-danger font-sm text-center tappable d-none d-sm-block" role="alert" style="cursor:pointer;" (click)="$event.stopPropagation();openBottomSheet();" *ngIf="hasIncomingTransfer">
            <i class="fa fa-lg fa-send mr-2"></i>A ticket or pass has been transferred to you - <strong>click here to check it out!</strong>
        </div>
        <div class="timer py-2" style="cursor:pointer;" *ngIf="cart.countdown" routerLink="/cart">
            <div class="container">
                <div class="row align-items-center m-0 pr-0">
                    <div class="col-9 col-lg-10 description font-sm py-1 py-sm-0" [ngClass]="{'agency': isAgencyPage}">
                        <i class="fa fa-info-circle mr-2 d-none d-md-inline-block"></i>Due to limited availability the items in your cart are subject to a validation check at the time of purchase.
                    </div>
                    <div class="col-3 col-lg-2 time my-1">
                        {{cart.countdown}}
                    </div>
                </div>
            </div>
        </div>
        <div class="py-2 tappable text-center alert alert-danger d-block d-sm-none" style="cursor:pointer;" (click)="$event.stopPropagation();openBottomSheet();" *ngIf="hasIncomingTransfer">
            <div class="container">
                <div class="row align-items-center m-0 pr-0">
                    <div class="col-12 font-sm py-1 py-sm-0" [ngClass]="{'agency': isAgencyPage}">
                        <i class="fa fa-lg fa-send mr-2"></i>A ticket or pass has been transferred to you - <strong>click here to check it out!</strong>
                    </div>
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
        <ts-svg></ts-svg>
        <div *ngIf="mobileService.deviceInfo?.model === 'iPhone'" class="mobile-bottom"></div>
    </mat-sidenav-content>
</mat-sidenav-container>
