import { Component, Input, OnInit } from '@angular/core';
import { TicketPrice } from '@app/data/models/ticket-price.model';
import * as _ from 'lodash';
import { TicketLevel } from '@app/data/models/ticket-level.model';

export class TicketPriceGroupByType {
  ticketLevel: TicketLevel;
  prices: TicketPrice[];
}

@Component({
  selector: 'ts-ticket-price-group',
  templateUrl: './price-group.component.html',
  styleUrls: ['./price-group.component.scss']
})
export class TicketPriceGroupComponent implements OnInit {

  @Input() prices: TicketPrice[];

  public priceGroup: TicketPriceGroupByType[] = new Array<TicketPriceGroupByType>();

  constructor () { }

  ngOnInit() {

    // take the prices and summarize them by the ticket type
    this.priceGroup = _.chain(this.prices)
      .groupBy((value) => value.ticketType.id)
      .map((value) =>  ({
        ticketType: value[0].ticketType,
        prices: value
      }))
      .orderBy(['ticketType.rank']).map( (type: any) => {
        const group = new TicketPriceGroupByType();
        group.ticketLevel = new TicketLevel().deserialize(type.ticketType);
        group.prices = type.prices.map((price) => new TicketPrice().deserialize(price));
        return group;
      })
      .value();
  }

}
