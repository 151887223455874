import { IDeserializable } from '@app/data/models/deserializable.interface';

export class EventSales implements IDeserializable {

  available: number;
  availablePercentage: number;
  capacity: number;
  soldPercentage: number;
  thresholdPercentage: number;
  ticketAmount: number;
  ticketCountDigital: number;
  ticketCountPaper: number;
  ticketCountTotal: number;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
