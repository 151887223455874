// `.env.ts` is generated by the `npm run env` command

import { env } from './.env';

export const environment = {
  production: true,
  version: env.npm_package_version,
  fastLaneUrl: 'https://go.ticketspicket.com',
  serverUrl: 'https://fan-api.events.hometownticketing.com/api/ts/v2',
  apiUrl: {
    fanweb: 'https://fan-web-api.ticketspicket.com',
  },
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR'
  ],
  seatsio: {
    apiUrl: 'https://api.seatsio.net',
    publicKey: '36e67f52-a30e-4d22-a257-195341419030',
    encodedKey: 'MDFmYTQzNGUtODZkYi00MjJkLThjZmMtMWI5N2QzYjM5ZmQyOg=='
  },
  googlePayMerchantId: 'BCR2DN4TZCR47PS6',
  eventsUrl: 'https://events.hometownticketing.com',
  clerkPublishableKey: 'pk_live_Y2xlcmsuaG9tZXRvd250aWNrZXRpbmcuY29tJA',
  clerkApiUrl: 'clerk.hometownticketing.com'
};
