import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { CartService } from '@app/data/services/cart.service';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-checkout-option',
  templateUrl: './checkout-option.component.html',
  styleUrls: ['./checkout-option.component.scss']
})
export class CheckoutOptionComponent implements OnInit {

  version: string = environment.version;
  error: string;
  loginForm: FormGroup;
  isLoading = false;
  returnParam = '';
  isGuestDisabled = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private _spinner: SpinnerService,
    private cart: CartService,
    public activeModal: NgbActiveModal,

  ) {
    this.createForm();
    if (authenticationService.credentials) {
      this.loginForm.setValue({ username: authenticationService.credentials.username, password: null, remember: true });
    }
    this._spinner.setMessage('Logging In');
  }

  checkEventOrPass() {
    const items = JSON.parse(localStorage.getItem('com.ticketspicket.cart')).items;
    if (items.length) {
      items.forEach(item => {
        if (item.itemType === 'pass') {
          this.isGuestDisabled = true;
        }
      });
    }

  }
  ngOnInit() {
    this.isGuestDisabled = false;
    this.cart.saveCart();
    this.route.queryParams.subscribe((params) => this.returnParam = params['return'] || '/home');
    this.checkEventOrPass();
  }

  login() {
    this._spinner.show();
    this.isLoading = true;
    // const loginContext = {
    //   email: this.loginForm.value.username,
    //   password: this.loginForm.value.password,
    //   remember: this.loginForm.value.remember
    // };
    // TODO Fix From Clerk Conversion
    // this.authenticationService.login(loginContext).pipe(
    //     finalize(() => {
    //         this.loginForm.markAsPristine();
    //         this.isLoading = false;
    //         this._spinner.hide();
    //     })
    // )
    //     .subscribe(() => {
    //         if (this.cart.countdown) {
    //             const info = JSON.stringify(this.cart.getItemsLog(), replacer);
    //             SentryTransaction('Chectout', 'Authenticated - login - redirecting to checkout', { cart: info });
    //             this.router.navigateByUrl('/cart/checkout');
    //         } else {
    //             this.router.navigateByUrl(this.returnParam);
    //         }
    //         this.close()
    //     }, (error) => {
    //         log.debug(error);
    //         this.error = error.error;
    //     });
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }
  public guestCheckout() {
    this.cart.setIsGuestCheckout(true);
    setTimeout(() => {
      this.router.navigateByUrl('/cart/checkout');
    }, 1000);
    this.close();
  }
  public close() {
    this.activeModal.dismiss(false);
  }
}
