import { Component, OnInit } from '@angular/core';
import { CartService } from '@app/data/services/cart.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PromotionCode } from '@app/data/models/promotion-code.model';
import { CartItem } from '@app/data/models/cart/cart-item.model';
import { CartItemEventSummary } from '@app/data/models/cart/cart-item-event';
import { CartItemPassSummary } from '@app/data/models/cart/cart-item-pass';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
import { UserService } from '@app/data/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class CartSummaryComponent implements OnInit {

  promoCodeForm: FormGroup;
  isLoading = false;
  promoCode: PromotionCode = new PromotionCode();

  public events: CartItemEventSummary[] = new Array<CartItemEventSummary>();
  public passes: CartItemPassSummary[] = new Array<CartItemPassSummary>();

  constructor(
    private router: Router,
    public cart: CartService,
    private formBuilder: FormBuilder,
    public _modal: NgbModal,
    private _messageService: MessageService,
    private _spinner: SpinnerService,
    private _userService: UserService
  ) {
    this.createForm();
    this._spinner.setMessage('Loading Cart Summary...');
  }

  ngOnInit() {
    this.cart.setIsGuestCheckout(false);
    this.events = this.cart.getEventItems();
    this.passes = this.cart.getPassItems();
    this._spinner.hide();
  }

  public checkoutOption() {
    this.cart.setIsGuestCheckout(true);
    this.router.navigateByUrl('/cart/checkout');

  }

  public clearCart() {
    this._messageService.ConfirmYesNo('Are you sure you want to remove all items from your cart?').then(() => {
      this.cart.clearCart();
      this.update();

      this.router.navigate(['/home'], { replaceUrl: true });
    });
  }

  public remove(item: CartItem) {
    this._messageService.ConfirmYesNo('Are you sure you want to remove this item from your cart?').then(() => {
      this.cart.removeItem(item);
    });
  }

  public update() {
    this.events = this.cart.getEventItems();
    this.passes = this.cart.getPassItems();
    this.cart.saveCart();
  }

  private createForm() {
    this.promoCodeForm = this.formBuilder.group({
      promoCode: ['', Validators.required],
    });
  }

  public checkPromoCode() {
    this.isLoading = true;
    this.cart.applyPromotionCode(this.promoCodeForm.controls['promoCode'].value).subscribe(() => {
      this.isLoading = false;
      this.promoCodeForm.reset();
      this.promoCode = this.cart.promotionCode;
      this.update();
    }, () => this.isLoading = false
    );
  }

  public showPromotionTerms(content: any) {
    this._modal.open(content, { centered: true, size: 'lg' }).result.then(() => {
    }, () => {
    });
  }

  public get homeRoute(): string {
    return this.cart.embedded ? '/embed/agency/' + this.cart.embeddedAgencyId : '/home';
  }
}
