<div class="alert alert-warning p-0 mt-3">
    <div class="row align-items-center mx-0 py-2" style="cursor:pointer;" (click)="showHelp()">
        <div class="col-3 col-md-2 px-0 d-inline-block text-center" *ngIf="isMobileOnly">
            <i class="fa fa-mobile fa-2x"></i>&nbsp;<div class="d-inline" style="font-size:30px;color:#856404;font-weight:100;">|</div>
            <div class="d-inline" class="fa-stack fa-lg" style="vertical-align:top;">
                <i class="fa fa-print fa-stack-1x"></i>
                <i class="fa fa-ban fa-stack-2x text-danger"></i>
            </div>
        </div>
        <div class="col-9 col-md-10 px-0 d-inline-block help-cta" style="line-height:2rem;vertical-align: text-bottom;" (keyup.Space)="showHelp()" tabindex="0" *ngIf="isMobileOnly">
            <div class="font-sm text-uppercase"><strong>Digital/Mobile only.</strong></div>
            <div class="font-sm">Tickets for this event can not be printed. Click here for more information.<i class="ml-2 fa fa-question-circle-o font-sm"></i></div>
        </div>
        <div class="col-3 col-md-2 px-0 d-inline-block text-center" *ngIf="!isMobileOnly">
            <i class="fa fa-mobile fa-2x"></i>&nbsp;<div class="d-inline" style="font-size:30px;color:#856404;font-weight:100;">|</div>&nbsp;<i class="fa fa-print fa-2x" style="font-size:1.7em;"></i>
        </div>
        <div class="col-9 col-md-10 px-0 d-inline-block help-cta" style="line-height:2rem;vertical-align: text-bottom;" (keyup.Space)="showHelp()" tabindex="0" *ngIf="!isMobileOnly">
            <div class="font-sm text-uppercase"><strong>Tickets for this event are both digital and printable at home.</strong></div>
            <div class="font-sm">Click here for more information.<i class="ml-2 fa fa-question-circle-o font-sm"></i></div>
        </div>
    </div>
</div>