import { Component, OnInit, Input } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';

@Component({
  selector: 'app-agency-season-ticket-header',
  templateUrl: './season-ticket-header.component.html',
  styleUrls: ['./season-ticket-header.component.scss']
})
export class AgencySeasonTicketHeaderComponent implements OnInit {

  @Input()
  public pass: GatePass = new GatePass();

  constructor ( ) {}

  ngOnInit() {

  }

}

