<div style="overflow:hidden;">
    <div class="container bg-white pb-4" *ngIf="agency && pass" style="position:relative;z-index:100;"
        [class.embedded]="_agencyService.embedded">
        <div
            class="coverArt view allAccess {{ pass.activity?.typeActivity.iconClassName }} {{pass.season?.iconClassName}}">
            <div class="colorOverlay"></div>
            <div class="details">
                <div class="row align-items-center">
                    <div class="col-12">
                        <svg class="icon" *ngIf="pass.gatePassType.value === 'All Access'">
                            <use xlink:href="#allAccess"></use>
                        </svg>
                        <svg class="icon" *ngIf="pass.gatePassType.value === 'Season'">
                            <use attr.xlink:href="#{{pass.season.iconClassName}}"></use>
                        </svg>
                        <svg class="icon" *ngIf="pass.gatePassType.value === 'Activity'">
                            <use attr.xlink:href="#{{pass.activity.typeActivity?.iconClassName}}"></use>
                        </svg>
                        <svg class="icon" *ngIf="pass.gatePassType.value === 'Event' && pass.activity">
                            <use *ngIf="pass.activity" attr.xlink:href="#{{pass.activity.typeActivity?.iconClassName}}">
                            </use>
                        </svg>
                        <h5 class="name">{{ pass.name }}</h5>
                        <p class="description">{{ pass.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
      <div class="col-12 text-center p-2 font-sm" style="background:#008CF4;color:white;" *ngIf="hasCartItem()">
        <i class="mr-2 fa fa-info-circle"></i>This item is already in the cart
      </div>
    </div> -->
        <nav aria-label="breadcrumb" class="d-none d-sm-block">
            <ol class="breadcrumb">
                <li class="breadcrumb-item font-sm">
                    <a routerLink="../../">{{ _agencyService.embedded ? 'Events & Passes' : agency.name }}</a>
                </li>
                <li class="breadcrumb-item font-sm">
                    <a routerLink="../../passes">All Passes</a>
                </li>
                <li class="breadcrumb-item active font-sm" aria-current="page">{{ pass.name }}</li>
            </ol>
        </nav>
        <button class="btn btn-sm btn-light btn-block d-sm-none d-block text-center mt-3" routerLink="../">
            <i class="fa fa-arrow-left mr-2"></i>Back to All Passes
        </button>

        <div class="mb-4" *ngIf="hasTerms()">
            <ts-section-header>
                <i class="fa fa-fw fa-info-circle mr-1"></i>{{ pass.name }} Information
            </ts-section-header>
            <div class="px-2" [innerHTML]="getTerms()"></div>
        </div>

        <div class="py-4">
            <div class="p-0">
                <ts-section-header>
                    <i class="fas fa-fw fa-ticket-alt mr-2"></i>Package Item Selections
                </ts-section-header>
                <div class="mb-3">
                    <div *ngFor="let item of passItems" class="mb-5">
                        <div class="my-2 pb-2 align-items-center" style="border-bottom:1px dotted #e5e5e5;">
                            <strong>{{item.product.name}}</strong>
                        </div>
                        <div class="row my-2 pb-2 mx-0 align-items-center" style="border-bottom:1px dotted #e5e5e5;"  *ngIf="selectableProduct(item.product.id)">
                            <div class="col-8 p-0">
                                {{selectableMessage(item.product.id)}}
                            </div>
                            <div class="col-4 text-right p-0">
                                <button class="btn btn-primary w-sm" (click)="selectSeats(item.product)">
                                    <span *ngIf="item.seats.length === 0 && item.ticketPrices.length === 0">Select</span>
                                    <span *ngIf="item.seats.length > 0 || item.ticketPrices.length > 0">Change</span>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="row my-2 pb-2 mx-0 align-items-center" style="border-bottom:1px dotted #e5e5e5;" *ngIf="selectableProduct(item.product.id)">
                            
                        </div> -->
                        <div class="row my-2 pb-2 mx-0 align-items-center" style="border-bottom:1px dotted #e5e5e5;"
                            *ngFor="let price of item.ticketPrices">
                            <div class="col-8 p-0" style="line-height:30px;">
                                {{price.price.ticketType.title}}
                            </div>
                            <div class="col-4 text-right p-0">
                                <i class="fas fa-ticket-alt mr-2"></i>
                                x&nbsp;&nbsp;&nbsp;<strong>{{price.quantity}}</strong>
                            </div>
                        </div>
                        <div class="row my-2 pb-2 mx-0 align-items-center" style="border-bottom:1px dotted #e5e5e5;"
                            *ngFor="let seat of item.seats">
                            <div class="col-6 p-0" style="line-height:30px;">
                                {{seat.seat.section}}-{{seat.seat.row}}-{{seat.seat.number}}
                            </div>
                            <div class="col-6 text-right p-0">
                                <i class="fas fa-ticket-alt mr-2"></i> x&nbsp;&nbsp;&nbsp;<strong>1</strong>
                            </div>
                        </div>
                        <div *ngIf="item.ticketPrices.length === 0 && item.seats.length === 0" style="font-weight: 200; font-size: .9em" class="text-danger text-uppercase">
                            <span class="font-weight-bold">No Selection has been made</span><br/>
                            (This will not be included in your purchase)
                        </div>
                    </div>
                </div>
                <div class="border-top py-3 text-uppercase">
                    <div class="row">
                        <div class="col-6 text-left">
                            <button class="btn btn-light" (click)="cancel()">
                                <i class="fa fa-fw fa-times mr-1"></i>
                                Cancel
                            </button>
                        </div>
                        <div class="col-6 text-right">
                            <button class="btn btn-primary" (click)="checkout()" [disabled]="!isOrderComplete()">
                                <i class="fa fa-fw fa-credit-card mr-1"></i>
                                Checkout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <svg class="background">
        <use xlink:href="#background" x="0px" y="0px"></use>
    </svg>
</div>
