import { Component, Input, OnInit } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';

@Component({
  selector: 'ts-pass-card',
  templateUrl: './pass-card.component.html',
  styleUrls: ['./pass-card.component.scss']
})

export class PassCardComponent implements OnInit {
  @Input() pass: GatePass;
  @Input() schoolView: boolean;

  constructor() {
  }

  ngOnInit() {
  }
}
