import { TicketPrice } from '../ticket-price.model';

export class CartItemTicketPriceSummary {
  public price: TicketPrice;
  public quantity: number;
  public numbers: Array<number> = new Array<number>();

  private _quantity: number;

  constructor(price: TicketPrice, quantity: number) {
    this.price = price;
    this.quantity = quantity;
    this._quantity = quantity;
  }

  public resetQuantity() {
    this.quantity = this._quantity;
  }
}
