import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { User } from '@app/data/models/user.model';
import { UserService } from '@app/data/services/user.service';
import { TicketBundle } from '@app/data/models/ticket-bundle';
import { Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { MobileService } from '@app/data/services/mobile.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { ITicketService } from '../interfaces/ticket-service.interface';

@Component({
  selector: 'ts-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  @Input() ticket: TicketBundle;

  @Input()
  private service: ITicketService;

  @Output() checkedIn = new EventEmitter<boolean>();
  public now: Date = new Date;

  public user: User;
  public checkingin = false;
  public validating = false;
  public checkincomplete = false;
  private checkIn$: Subscription = new Subscription();
  public totalCheckingIn = 0;
  public labelString = '';
  public errorMessage: string;

  constructor (
    public _userService: UserService,
    private _mobileService: MobileService,
    public angulartics2: Angulartics2GoogleAnalytics
  ) {
    this.user = this._userService.user;
  }

  ngOnInit() {
    this.ticket.levels.forEach((value) => {
      if (value) {
        this.totalCheckingIn += value.count;
      }
    });
    this.ticket.seats.forEach(() => {
      this.totalCheckingIn += 1;
    });
  }

  public checkIn() {
    this.service.selfCheckIn(this.ticket).subscribe( () => {
      this.checkedIn.emit(true);
    });
  }

  public startCheckingIn(ticket: TicketBundle) {

    if (this.checkingin) {
      return;
    }

    this.checkingin = true;
    this.validating = false;
    this.errorMessage = null;

    // Track Event Ad Click - ELH
    // this.trackEvent('Load');
    // this.trackEvent('View');
    this.checkIn$ = timer(1700).subscribe(() => {
      this.validating = true;
      this.service.selfCheckIn(ticket).subscribe( () => {
        this.ticket.dateRedeemed = new Date();
      }, (error) => {
        console.error(error);
        this.errorMessage = error.error.message;
        this.checkingin = false;
        this.validating = false;
        this.checkedIn.emit(true);
      }, () => {
        this.checkingin = false;
        this.validating = false;
        this.checkedIn.emit(true);
      });
    });
  }

  public stopCheckingIn() {
    this.checkIn$.unsubscribe();
    // if the ticket is already being validated, then don't stop
    if (!this.validating) {
      this.checkingin = false;
    }
  }

  public gatesOpen() {
    return moment(this.now).isAfter(this.ticket.event.dateGates);
  }

  // Track Event Ad Click - ELH
  public sponsorLinkClicked(url: string) {
    this.trackEvent('Click');
    if (this._mobileService.isCapacitor) {
      this._mobileService.openURL();
    } else {
      window.open(url, '_blank');
    }
  }

  public trackEvent(type: string) {
    this.angulartics2.eventTrack(type, {
      category: 'Digital Ad - Ticket Placement 1',
      label: this.labelString,
      value: 1
    });
  }

}
