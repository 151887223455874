import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { MobileService } from '@app/data/services/mobile.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public version: string = environment.version;

  constructor (
    public mobileService: MobileService
  ) { }

  ngOnInit() { }

}
