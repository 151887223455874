<div class="bg-white p-3">
    <ts-section-header *ngIf="!cart.isGuestCheckout">Checkout</ts-section-header>
    <ts-section-header *ngIf="cart.isGuestCheckout">{{ this.checkoutText.header }}</ts-section-header>
    <!-- Free -->
    <div *ngIf="cart.getTotalPrice() === 0 && !cart.isEmpty()" class="p-3">
        <!-- Guest Checkout -->
        <div *ngIf="cart.isGuestCheckout">
            <p class="font-weight-bold">Contact Information</p>
            <p>{{ this.checkoutText.subHeader }}</p>
            <form autocomplete="off" [formGroup]="formCode" (ngSubmit)="sendCode()">
                <formly-form [form]="formCode" [fields]="formCodeFields" [model]="code" class="row">
                </formly-form>
            </form>
        </div>
        <div class="empty-cart text-center" style="margin:50px auto;">
            <h2 class="border-0">Congratulations!</h2>
            <h5>Today, your cart total comes to $0.00, so your purchase is <span style="color:green;">FREE.</span></h5>
            <p>Click Complete Checkout below to complete the purchase process and receive your digital tickets!</p>
            <hr />
            <div class="row d-block d-sm-none">
                <button class="btn btn-secondary btn-block px-5 my-2" (click)="completePurchase()">
                    <i class="fa fa-credit-card mr-2"></i>Complete Checkout
                </button>
                <button class="btn btn-light btn-block px-5 my-2" [routerLink]="['..']"><i
                        class="fa fa-arrow-left mr-2"></i>Back</button>
            </div>
            <div class="row d-none d-sm-flex">
                <div class="col-6">
                    <button class="btn btn-light btn-block my-2" [routerLink]="['..']"><i
                            class="fa fa-arrow-left mr-2"></i>Back</button>
                </div>
                <div class="col-6">
                    <button class="btn btn-secondary btn-block px-5 my-2" (click)="completePurchase()" [disabled]="cart.isGuestCheckout ? !isValidForm : false">
                        <i class="fa fa-credit-card mr-2"></i>Complete Checkout
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Purchase with cost -->
    <div *ngIf="cart.getTotalPrice() > 0">
        <!-- Guest Checkout -->
        <div *ngIf="cart.isGuestCheckout">
            <p class="font-weight-bold">Contact Information</p>
            <p>{{ this.checkoutText.subHeader }}</p>
            <form autocomplete="off" [formGroup]="formCode" (ngSubmit)="sendCode()">
                <formly-form [form]="formCode" [fields]="formCodeFields" [model]="code" class="row">
                </formly-form>
            </form>
        </div>
        <div class="mb-3">
            <ngx-braintree [chargeAmount]="cart.getTotalPrice()" (paymentStatus)="onPaymentStatus($event)"
                (payButtonStatus)="onPayButtonStatus($event)">
            </ngx-braintree>
        </div>
        <div class="mb-3 summary-options text-right">
            <div class="mb-2 pr-3" *ngIf="cart.getTotalFees() > 0">Convenience Fee: <strong>{{cart.getTotalFees() |
                    currency }}</strong></div>
            <div class="mb-2 pr-3">Total Amount: <strong>{{cart.getTotalPrice() | currency }}</strong></div>
        </div>
        <div class="my-5 text-center">
            <div class="row d-none d-sm-flex">
                <div class="col-6">
                    <button class="btn btn-light btn-block my-2" [routerLink]="['..']" (click)="back()">
                        <i class="fa fa-arrow-left mr-2"></i>Back
                    </button>
                </div>
                <div class="col-6">
                    <button class="btn btn-secondary btn-block my-2" (click)="checkout()" [disabled]="!enablePayButton || (cart.isGuestCheckout ? !isValidForm : false)">
                        <span><i class="fa fa-credit-card mr-2"></i>Pay {{cart.getTotalPrice() | currency }}</span>
                    </button>
                    <p class="font-sm text-uppercase font-weight-bold">{{ cart.getFinalSalesLanguage() }}</p>
                </div>
            </div>
            <div class="d-block d-sm-none">
                <button class="btn btn-secondary btn-block px-5 my-2" (click)="checkout()"
                    [disabled]="!enablePayButton || (cart.isGuestCheckout ? !isValidForm : false)">
                    <span><i class="fa fa-credit-card mr-2"></i>Pay {{cart.getTotalPrice() | currency }}</span>
                </button>
                <p class="font-sm text-uppercase font-weight-bold">{{ cart.getFinalSalesLanguage() }}</p>
                <button class="btn btn-light btn-block px-5 my-2" [routerLink]="['..']"><i
                        class="fa fa-arrow-left mr-2"></i>Back</button>
            </div>
        </div>
    </div>
</div>
