<ts-section-header>
    Events
    <div class="actions">
        <div class="btn-group float-right" role="group" aria-label="Switch between card and list Event View">
            <button mdbRippleRadius type="button" class="btn btn-outline-secondary btn-sm" [ngClass]="{'active': !showEventList}" (click)="toggleEventList()"><i class="fa fa-th-large"></i></button>
            <button mdbRippleRadius type="button" class="btn btn-outline-secondary btn-sm" [ngClass]="{'active': showEventList}" (click)="toggleEventList()"><i class="fa fa-list"></i></button>
        </div>
        <div class="float-right">
            <button mdbRippleRadius type="button" class="btn btn-outline-secondary mr-2 btn-sm" [ngClass]="{'active': filter.hasActivityFilter()}" (click)="filterEvents()">
                <span class="d-none d-sm-inline mr-1">Filter Events</span>
                <i class="fa fa-filter"></i>
                <i class="fa fa-xs pt-1 fa-check" style="left: -5px; position: relative; margin-right: -5px;" *ngIf="filter.hasActivityFilter()"></i>
            </button>
        </div>
    </div>
    <div class="description" *ngIf="filter.hasActivityFilter() && getFilteredEvents().length != events.length">(showing {{getFilteredEvents().length}} of {{events.length}})</div>
</ts-section-header>
<div *ngIf="events?.length > 0">
    <div *ngIf="showEventList" class="events">
        <div *ngFor="let event of getFilteredEvents()">
            <ts-event-list-item [event]="event" [agency]="agency" [schoolView]="true" [parentSchoolView]="agency.hasChildren()" [routerLink]="[ '/', 'agency', agency.uuid, 'events', event.uuid]" (keydown.enter)="onKeydownEnterEvent($event, event)"></ts-event-list-item>
        </div>
    </div>
    <div *ngIf="!showEventList" class="row events">
        <div class="col-12 col-sm-12 col-lg-4 mb-4" *ngFor="let event of getFilteredEvents()">
            <ts-event-card-h2h [event]="event" [agency]="agency" [schoolView]="isSchoolView" [routerLink]="[ '/', 'agency', agency.uuid, 'events', event.uuid]" *ngIf="agency.hasChildren()"></ts-event-card-h2h>
            <ts-event-card [event]="event" [agency]="agency" [schoolView]="isSchoolView" [parentSchoolView]="agency.hasChildren()" [routerLink]="[ '/', 'agency', agency.uuid,'events', event.uuid]" (keydown.enter)="onKeydownEnterEvent($event, event)"  *ngIf="!agency.hasChildren()"></ts-event-card>
        </div>
    </div>
</div>
<div class="mb-5" *ngIf="getFilteredEvents().length === 0">
    <ts-loading message="Hang tight, we're loading events" [show]="agency.isLoadingEvents"></ts-loading>
    <ts-no-results [message]="noResultsMessage" *ngIf="!filter.hasActivityFilter() && !agency.isLoadingEvents"></ts-no-results>
    <div class="text-center p-3" style="color:lightslategrey;" *ngIf="filter.hasActivityFilter() && hasEvents()">
        <h4 class="h4-responsive">There are no results based on the applied filter</h4>
        <div class="mt-1">
            <button mdbRippleRadius type="button" class="btn btn-secondary mr-2 active" (click)="clearFilter()">
                <span class="d-none d-sm-inline">Remove Filter</span>
                <i class="fa fa-filter ml-1"></i>
                <i class="fa fa-xs pt-1 fa-times" style="left: -5px; position: relative; margin-right: -5px;" *ngIf="filter.hasActivityFilter()"></i>
            </button>
        </div>
    </div>
</div>
