<div class="home-container">
  <div class="container-fluid">
    <div class="text-center">
      <div class="container align-middle pb-3 px-0" style="padding-top:88px;">
        <div class="ticket-spicket logo" [ngClass]="{'featuredEventsShown': featuredEvents.length > 0}"></div>
        <ng-select [items]="agency$ | async" bindLabel="name" [addTag]="false" [multiple]="false" [hideSelected]="true"
          [trackByFn]="trackByFn" [minTermLength]="2" [loading]="agencyLoading" [typeahead]="agencyInput$"
          placeholder="Search for a School, Organization or Event" [(ngModel)]="selectedAgency"
          (change)="onAgencySelection()" class="search-input home-page-search">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" style="padding: -10px">
            <ts-agency-list-item [agency]="item"></ts-agency-list-item>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div id="sign-in" name="sign-in"></div>
</div>