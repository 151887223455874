import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpModalComponent } from '@app/agency/help/help-modal.component';

@Component({
  selector: 'ts-ticket-help-cta',
  templateUrl: './ticket-help-cta.component.html',
  styleUrls: ['./ticket-help-cta.component.scss']
})
export class TicketHelpCTAComponent {

  @Input() isMobileOnly: boolean;

  constructor(
    private _modal: NgbModal
  ) {}

  public showHelp() {
    const modalRef = this._modal.open(HelpModalComponent);
    modalRef.componentInstance.digitalOnly = this.isMobileOnly;
  }

}
