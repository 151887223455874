import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CartService } from '@app/data/services/cart.service';
import { Router } from '@angular/router';
import { NgxBraintreeComponent } from '@app/cart/braintree/ngx-braintree.component';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SentryTransaction } from '@app/core';
import { ClerkService } from '@app/core/authentication/clerk.service';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  @ViewChild(NgxBraintreeComponent, { static: false }) paymentMethod: NgxBraintreeComponent;

  public code = {
    phoneNumber: '',
    email: '',
    firstName: '',
    lastName: '',
  };

  public checkoutText = {
    header: 'Guest Checkout',
    subHeader: 'Please enter your contact information. The information entered will be used to access your tickets.'
  };

  enablePayButton = false;
  interval: any;

  constructor(
    public cart: CartService,
    private _router: Router,
    private _messageService: MessageService,
    private activeModal: NgbActiveModal,
    private clerkService: ClerkService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  public formCode: FormGroup = new FormGroup({});
  public phoneRegex = new RegExp(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/);
  public emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  public nameRegex = new RegExp(/^[a-z ,.'-]+$/i);
  public isValidForm = false;
  public formCodeFields: FormlyFieldConfig[] = [
    {
      className: 'col-sm-6',
      key: 'firstName',
      type: 'input',
      id: 'inpFirstName',
      templateOptions: {
        type: 'firstName',
        label: 'First Name',
        placeholder: '',
        required: true,
        appearance: 'outline',
        pattern: this.nameRegex,
      },
      expressionProperties: {
        'model.firstName': 'model.firstName',
      },
      modelOptions: {
        updateOn: 'blur',
      }
    },
    {
      className: 'col-sm-6',
      key: 'lastName',
      type: 'input',
      id: 'inpLastName',
      templateOptions: {
        type: 'lastName',
        label: 'Last Name',
        placeholder: '',
        required: true,
        appearance: 'outline',
        pattern: this.nameRegex,
      },
      expressionProperties: {
        'model.lastName': 'model.lastName',
      },
      modelOptions: {
        updateOn: 'blur',
      }
    },
    {
      className: 'col-sm-6',
      key: 'phoneNumber',
      type: 'input',
      id: 'inpPhoneNumber',
      parsers: [(value: string) => this.formatNumber(value)],
      templateOptions: {
        type: 'tel',
        label: 'Phone Number',
        placeholder: '(555) 987-6543',
        required: true,
        appearance: 'outline',
        pattern: this.phoneRegex,
      },
      expressionProperties: {
        'model.phoneNumber': 'model.phoneNumber',
      },
      modelOptions: {
        updateOn: 'blur',
      }
    },
    {
      className: 'col-sm-6',
      key: 'email',
      type: 'input',
      id: 'inpEmail',
      templateOptions: {
        type: 'email',
        label: 'Email',
        placeholder: '',
        required: true,
        appearance: 'outline',
        pattern: this.emailRegex,
      },
      expressionProperties: {
        'model.email': 'model.email',
      },
      modelOptions: {
        updateOn: 'blur',
      }
    },
  ];

  public sendCode() {
    this._messageService.toast.success('success.');
  }

  public formatNumber(value: string): string {
    if (!value || value === '') {
      return value;
    }

    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    return match;
  }

  public onChanges() {
    this.formCode.valueChanges.subscribe (() => {
      this.validateForm();
    });
  }

  ngOnInit() {
    this.cart.setIsGuestCheckout(true);
    this.cart.expiration.extendExpiration();
    this.prefillFormWithAccountInformation();
    this.interval = setInterval(() => {
      if (this.cart.countdown === '02:00') {
        this.promptForTime();
      } else if (this.cart.countdown === '00:00') {
        this.activeModal.dismiss(false);
        this._router.navigate([this.cart.cartRoute]);
      }
    }, 1000);
    this.onChanges();
  }

  ngAfterViewInit() {
    // Prevents Chrome autofill (but not autocomplete) which was breaking validation logic
    this.formCodeFields.map((field) => document.getElementById(field.id).setAttribute('autocomplete', field.id));
  }

  prefillFormWithAccountInformation(): void {
    this.clerkService.user$.subscribe(user => {
      if (user) {
        this.code.firstName = user.firstName || '';
        this.code.lastName = user.lastName || '';
        this.code.phoneNumber = user.primaryPhoneNumber?.phoneNumber.replace(/^\+1/, '') || '';
        this.code.email = user.primaryEmailAddress?.emailAddress || '';
        this.formCode.patchValue(this.code); // Update form model
        this.updateFieldDisabledStatus(); // Update the form fields with disabled status and rerender
        this.checkoutText.header = 'Checkout';
        this.checkoutText.subHeader = 'Confirm your contact information below. Any changes must be made in your account.';
      }
    });
  }

  shouldDisableField(fieldName: string): boolean {
    return this.code[fieldName] !== '';
  }

  updateFieldDisabledStatus() {
    this.formCodeFields = this.formCodeFields.map(field => ({
      ...field,
      templateOptions: {
        ...field.templateOptions,
        disabled: this.shouldDisableField(field.key as string),
      },
    }));
    // Rerender the checkout form with fields prefilled and disabled
    this.changeDetectorRef.detectChanges();
    // onChange won't run if there is no input changes
    // so validate the form after detectChanges runs
    this.validateForm();
  }

  private validateForm(): void {
    // If the user is filling out the form we check the valid boolean
    // If the user has all fields prefilled by the account, the form status is DISABLED
    this.isValidForm = this.formCode.valid || this.formCode.status === 'DISABLED';
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  onPaymentStatus() {
    this._router.navigate(['/cart/confirmation']);
  }

  onPayButtonStatus(event: boolean) {
    this.enablePayButton = event;
  }

  checkout() {
    this.paymentMethod.pay(this.code.phoneNumber, this.code.email, this.code.firstName, this.code.lastName);
  }

  completePurchase() {
    this.cart.checkout(this.code).subscribe((confirmation) => {
      SentryTransaction('Checkout', 'Complete Purchase', confirmation);
      this._router.navigate(['/cart/confirmation']);
    }, (error) => {
      console.error(error);
    });
  }

  promptForTime() {
    if (this.cart.expiration.canExtend()) {
      this._messageService.ConfirmYesNo('Are you still entering your payment information? Click \'Yes\' to extend your session.').then(() => {
        this.cart.expiration.extendExpiration();
      });
    }
  }

  back() {
    this.cart.setIsGuestCheckout(false);
  }
}
