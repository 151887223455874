import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CartItemEvent } from '@app/data/models/cart/cart-item-event';
import { CartItemTicketPriceSummary } from '@app/data/models/cart/cart-item-tickettype-summary.model';
import { CartItemSummary } from '@app/data/models/cart/cart-item.model';
import { MessageService } from '../message.service';

@Component({
  selector: 'ts-cart-ticket-type',
  templateUrl: './cart-ticket-type.component.html',
  styleUrls: ['./cart-ticket-type.component.scss']
})
export class CartTicketTypeComponent implements OnInit {

  @Input() summary: CartItemSummary;

  private _maxQty = 25;

  @Output() remove: EventEmitter<CartItemEvent> = new EventEmitter<CartItemEvent>();
  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  constructor (
    private _message: MessageService
  ) {
  }

  ngOnInit() {
    if (this.summary.items.hasChannel) {
      this._maxQty = this.summary.items.items[0].channel.maxPerTrx;
    }

    this.summary.ticketPrices.forEach(t => {
      t.numbers = this.getQuantity(t);
    });
  }

  public getQuantity(item: CartItemTicketPriceSummary) {
    const maxQty = item.price.calulatedAvailable === -1 ?
      this._maxQty : Math.min(item.price.calulatedAvailable, this._maxQty);
    const numbers: Array<number> = new Array<number>();
    for (let i = 0; i <= maxQty; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  public updateItem(summary: CartItemSummary, item: CartItemTicketPriceSummary) {

    if (item.quantity === 0) {
      this._message.ConfirmYesNo('Are you sure you want to remove this from the cart?').then(() => {
        this.update.emit({ 'product': summary.product, 'ticketPrice': item.price, 'quantity': item.quantity });
      }, () => {
        item.resetQuantity();
      });
    } else {
      this.update.emit({ 'product': summary.product, 'ticketPrice': item.price, 'quantity': item.quantity });
    }

  }

  public removeItem(summary: CartItemSummary, item: CartItemTicketPriceSummary) {
    item.quantity = 0;
    this.updateItem(summary, item);
  }

}
