import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MessageService } from '../../message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsumerGatePass } from '@app/data/models/passes/consumer-gate-pass.model';
import { PassAssignModalComponent } from '@app/passes/assign/pass-assign.component';
import { PassTransferModalComponent } from '@app/passes/transfer/pass-transfer.component';
import { PassDetailModalComponent } from '@app/passes/pass-detail.component';
import { PassCancelTransferModalComponent } from '@app/passes/transfer/cancel-transfer-modal.component';

@Component({
  selector: 'ts-configure-pass-bottom-sheet',
  templateUrl: './configure-pass-bottom-sheet.component.html'
})

export class ConfigurePassBottomSheet {

  private _modalOptions: any = {
    centered: true,
    backdrop: 'static',
    keyboard: false
  };

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ConfigurePassBottomSheet>,
    @Inject(NgbModal) private _modalService: NgbModal,
    @Inject(MessageService) private _messageService: MessageService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: ConsumerGatePass
  ) {}

  public hasAction(action: string): boolean {

    let hasAction = false;

    switch (action) {
      case 'assign':
        hasAction = true;
        break;
      case 'transfer':
        hasAction = this.data.isTransferrable;
        break;
      case 'transfer-cancel':
        hasAction = this.data.isTransferred;
        break;
      default: break;
    }

    return hasAction;
  }

  public doAction (action: string) {

    this.bottomSheetRef.dismiss(action);

    switch (action) {
      case 'assign':
        this.assignPass();
        break;
      case 'transfer':
        this.transferPass();
        break;
      case 'transfer-cancel':
        this.cancelTransferPass();
        break;
      default: break;
    }
  }

  public assignPass() {

    const modalRef = this._modalService.open(PassAssignModalComponent, this._modalOptions);
    modalRef.componentInstance.consumer = this.data;
  }

  public transferPass() {
    const modalRef = this._modalService.open(PassTransferModalComponent, this._modalOptions);
    modalRef.componentInstance.consumer = this.data;
  }

  public cancelTransferPass() {
    const modalRef = this._modalService.open(PassCancelTransferModalComponent, this._modalOptions);
    modalRef.componentInstance.consumer = this.data;
  }

  // TODO: need to implement this later
  public viewDetails() {
    const modalRef = this._modalService.open(PassDetailModalComponent, this._modalOptions);
    modalRef.componentInstance.consumer = this.data;
  }

}
