import { IDeserializable } from './deserializable.interface';
import { Event } from './event.model';
import { Ticket } from './ticket.model';
import * as moment from 'moment';
import { EventAdmission } from './tickets/event-admission.model';

export class TicketShare implements IDeserializable {

  public id: number;
  public uuid: string;
  public dateCreated: Date;
  public event: Event;
  public tickets: Ticket[] = new Array<Ticket>();

  public admission: EventAdmission;

  constructor() {}

  deserialize(input: any) {

    this.id = input.id;
    this.uuid = input.uuid;

    if (input.dateCreated) {
      this.dateCreated = moment(input.dateCreated).toDate();
    }

    if (input.event) {
      this.event = new Event().deserialize(input.event);
    }

    if (input.tickets) {
      this.tickets = input.tickets.map((ticket) => new Ticket().deserialize(ticket.ticket));
    }

    this.admission = new EventAdmission(this.event).setAdmission(this.tickets);

    return this;
  }

}
