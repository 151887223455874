import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PassFilter } from '@app/data/models/passes/pass-filter.model';

@Component({
  selector: 'app-agency-pass-filter-modal',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class PassFilterModalComponent implements OnInit {

  @Input()
  public filter: PassFilter = new PassFilter();

  constructor (
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void  {
  }

  applyFilter() {
    this.activeModal.close(true);
  }

}
