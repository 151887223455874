<div class="background-color">
    <div class="container pb-4">
        <div class="row text-center stepper m-0">
            <button class="col-4 step-container border-0" routerLink="." [disabled]="isSummaryDisabled()"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <div class="step" [ngClass]="{'complete': step > 1}">
                    <div class="icon"><i class="fa fa-list-alt"></i></div>
                    <div class="d-none d-sm-block name">Selection Summary</div>
                </div>
            </button>
            <button class="col-4 step-container border-0" (click)="checkoutOption()" [disabled]="isCheckoutDisabled()"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [ngClass]="{'active': step === 2}">
                <div class="step" [ngClass]="{'complete': step > 2}">
                    <div class="icon"><i class="fa fa-credit-card"></i></div>
                    <div class="d-none d-sm-block name">{{ labelCheckout }}</div>
                </div>
            </button>
            <button class="col-4 step-container border-0" [disabled]="step !== 3" routerLink="confirmation"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <div class="step">
                    <div class="icon"><i class="fa fa-file-text-o"></i></div>
                    <div class="d-none d-sm-block name">Confirmation</div>
                </div>
            </button>
        </div>
        <div class="row">
            <div class="col-12 px-0 px-md-3">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
