<svg class="icon" [ngClass]="{'ticketView': ticketView}">
    <use attr.xlink:href="#{{ event.activity?.typeActivity.iconClassName }}"></use>
</svg>
<mat-list class="p-2 pb-3">
    <mat-list-item>
        <div matListAvatar *ngIf="event.type.code != 'HEADTOHEAD'">
            <div *ngIf="!logo && !event.agency.settings?.logoURI" matListAvatar class="default-logo">{{ event.agency.name | limitTo: 1 }}</div>
            <img *ngIf="!logo && event.agency.settings?.logoURI" matListAvatar [src]="event.agency.settings?.logoURI">
            <img *ngIf="logo" matListAvatar [src]="logo">
        </div>
        <div matListAvatar *ngIf="event.type.code === 'HEADTOHEAD'" class="double-logo">
            <img *ngIf="event.opponent.settings?.logoURI" matListAvatar [src]="event.opponent.settings?.logoURI">
            <img *ngIf="event.getHomeAgency().settings?.logoURI" matListAvatar [src]="event.getHomeAgency().settings?.logoURI">
            <div *ngIf="!event.opponent.settings?.logoURI" class="default logo d-inline-block" style="background-color:#2014CC;color:white;" [ngStyle]="{'background-color': event.opponent.settings?.primaryColor, 'color': event.opponent.settings?.primaryText}">{{ event.opponent.nameShort | limitTo: 1 }}</div>
            <div *ngIf="!event.getHomeAgency().settings?.logoURI" class="default logo d-inline-block" style="background-color:#2014CC;color:white;" [ngStyle]="{'background-color': event.getHomeAgency().settings?.primaryColor, 'color': event.getHomeAgency().settings?.primaryText}">{{ event.getHomeAgency().nameShort | limitTo: 1 }}</div>
        </div>
        <h5 mat-line class="title mb-1 pt-1 text-uppercase">{{ event.title }}</h5>
        <h5 mat-line class="subtitle mb-1">{{ event.subtitle }}</h5>
        <div mat-line class="special-event">
            <strong>{{ event.specialEvent }}</strong>
        </div>
        <div mat-line class="location mt-2 pb-1">
            <i class="fa fa-fw fa-calendar mr-2"></i>{{ event.dateStart | date: 'EEE, MMM d, yyyy h:mm a' }}
        </div>
        <div mat-line class="location">
            <i class="fa fa-fw fa-map-marker mr-2"></i>{{ event.location?.address.name }}, {{ event.location?.address.cityState }}
        </div>
    </mat-list-item>
</mat-list>
