<div class="modal-body p-0 pb-4 mb-3">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-header-color single">
        <ts-ticket-selection-event-header [event]="event"></ts-ticket-selection-event-header>
    </div>
    <div class="modal-body pb-3">
        <div class="row">
            <div class="col-12 col-md-6 border-right mb-4">
                <ts-section-header>
                    <i class="fas fa-fw fa-ticket-alt mr-1"></i>Choose Your Tickets
                </ts-section-header>
                <div *ngFor="let price of ticketPrices">
                  <div class="row align-items-center m-0 schedule pb-3" *ngIf="price.hasAvailability">
                      <div class="col-8 text-left p-0">
                          <div class="price title">{{price.ticketType.title}}</div>
                          <div class="discount-message" *ngIf="price.hasDiscount()">Discount valid until: {{ price.dateEnd | date: 'M/dd/yyyy @ h:mm a' }}</div>
                      </div>
                      <div class="col-4 text-right p-0">
                          <div class="price discount" [ngClass]="{'has-discount': price.hasDiscount()}" *ngIf="price.hasDiscount()">
                            <span *ngIf="price.priceAmount > 0">{{ price.priceAmount | currency }}</span>
                            <span *ngIf="price.priceAmount === 0">FREE</span>
                          </div>
                          <div class="price" [ngClass]="{'has-discount': price.hasDiscount()}" >
                            <div></div>
                            <span *ngIf="price.priceAmountOriginal > 0">{{ price.priceAmountOriginal | currency }}</span>
                            <span *ngIf="price.priceAmountOriginal === 0">FREE</span>
                          </div>
                      </div>
                      <div class="col-12 px-0 pt-2">
                          <div class="quantity-selector">
                              <div class="row align-items-center text-center m-0" [attr.data-test]="price.ticketType.title + '-ticketLevel'">
                                  <div class="col-4 p-0">
                                      <button mdbRippleRadius type="button" class="btn btn-light quantity-decrease border-0"
                                        style="width:100%;height:100%;" (click)="removeTicket(price)"
                                        aria-label="Decrease Ticket Count">-</button>
                                  </div>
                                  <div class="col-4 border-right border-left">
                                      <div class="quantity"  aria-label="Ticket Count" tabindex="0">{{ cartItems.getProductTicketPriceQty(event, price) | async }}</div>
                                  </div>
                                  <div class="col-4 p-0">
                                      <button mdbRippleRadius type="button" class="btn btn-light quantity-increase border-0"
                                        style="width:100%;height:100%;" (click)="addTicket(price)"
                                        [disabled]=" (price.capacity > 0 && (price.addedCount >= price.available) ) || _cartService.itemCount() === 25 || cartItems.items.length >= 25 ||( isIncreaseButtonDisabled | async)"
                                        aria-label="Increase Ticket Count">+</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="font-weight-bold text-center alert alert-info" *ngIf="channel && channel?.maxPerTrx > 0">
                    You are allowed to select up to {{ channel.maxPerTrx }} ticket<span *ngIf="channel.maxPerTrx > 1" >s</span>
                </div>
                <div class="font-weight-bold text-center alert alert-warning" *ngIf="channel && cartChannelCount > 0">
                    You already have {{ cartChannelCount }} tickets in your cart
                </div>
            </div>
            <div class="col-12 col-md-6">
                <ts-section-header>
                    <i class="fa fa-fw fa-list-alt mr-1"></i>Selection Summary
                </ts-section-header>
                <div *ngFor="let price of ticketPrices">
                    <div class="row align-items-center" *ngIf="price.hasAvailability">
                      <div class="col-6 text-left">
                        <div class="price title">{{price.ticketType.title}}</div>
                      </div>
                      <div class="col-6 text-right ml-auto">
                          <div class="d-inline-block">x</div>
                          <div class="d-inline-block" style="width:20px;">{{ cartItems.getProductTicketPriceQty(event, price) | async }}</div>
                          <div class="ml-2 d-inline-block">=</div>
                          <div class="d-inline-block" style="width:89px;">{{ cartItems.getProductTicketPriceTotalPrice(event, price) | async | currency }}</div>
                      </div>
                    </div>
                </div>
                <hr />
                <div>
                    <div class="text-right mb-3">
                        <!-- <div class="mb-2" *ngIf="event.convenienceFee">
                            <strong>Convenience Fee:</strong>
                            <div class="d-inline-block text-right" style="width:89px;"><span *ngIf="!loading">{{cartItems.getTotalFees() | currency }}</span><span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></span></div>
                        </div> -->
                        <div class="mb-2">
                            <strong>Total Amount:</strong>
                            <div class="d-inline-block text-right" style="width:89px;"><span *ngIf="!loading">{{cartItems.getTotalPrice() | currency }}</span><span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></span></div>
                        </div>
                        <div class="mb-2 text-right text-muted">
                            (plus applicable fees)
                        </div>
                    </div>
                    <div class="row text-center my-3">
                        <div class="col-12">
                            <button mdbRippleRadius type="button" [disabled]="cartItems.isEmpty()" class="btn btn-secondary w-100" (click)="checkout()">
                                <i class="fa fa-credit-card mr-2"></i>Checkout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center modal-close-button">
    <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="close()">Done</button>
</div>
