import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { Agency } from '@app/data/models/agency.model';
import { AgencyService } from '@app/data/services/agency.service';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { CartService } from '@app/data/services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MobileService } from '@app/data/services/mobile.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _ from 'lodash';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
import { CartItemCollection } from '@app/data/models/cart/cart-item-collection.model';
import { CartItemEventSummary } from '@app/data/models/cart/cart-item-event';
import { CartItemPassSummary } from '@app/data/models/cart/cart-item-pass';
import { Bundle, BundleProduct } from '@app/data/models/bundle.model';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { AgencyBundleSeatmapComponent } from './seatmap/seatmap.component';
import { TicketPrice } from '@app/data/models/ticket-price.model';

@Component({
  selector: 'app-agency-bundle',
  templateUrl: './pass.component.html',
  styleUrls: ['./pass.component.scss']
})
export class AgencyBundleComponent implements OnInit, AfterContentChecked {

  public passId: string;
  public agency: Agency;
  public pass: GatePass;

  public bundle: Bundle = new Bundle();

  public selectedIndex = 0;
  public selectedProduct: BundleProduct = new BundleProduct();

  public isLoading = false;

  public passItems: CartItemPassSummary[] = new Array<CartItemPassSummary>();
  public eventItems: CartItemEventSummary[] = new Array<CartItemEventSummary>();

  public bundledItems: CartItemCollection = new CartItemCollection();

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    public _agencyService: AgencyService,
    public _cart: CartService,
    public mobileService: MobileService,
    private _spinner: SpinnerService,
    private _sanitizer: DomSanitizer,
    private _messageService: MessageService,
    public _modal: NgbModal
  ) {
    this._spinner.setMessage('Loading Pass Details');
  }

  ngOnInit() {

    this.isLoading = true;
    this.agency = this._agencyService.agency;

    this.route.params.pipe(
      map((params) => this.passId = params['pass-id']),
      switchMap((id: number) => this._agencyService.getPass(this.agency.uuid, id)),
      map((pass) => this.pass = pass),
      switchMap(() => this._cart.getHoldToken()),
      // map((token) => this.config = new ReservedConfiguration(token)),
    ).subscribe(() => {
      this.getBundleData(this.pass.id);

      // If the Bundle is not in the cart already, add it
      if (!this._cart.hasItem(this.pass)) {
        const items: CartItemCollection = new CartItemCollection();
        items.addPassItem(this.pass, this.pass.prices[0], 1);
        this._cart.addItems(items);
      }
      this._spinner.hide();
    });

    this._cart.bundledItems.items$.subscribe(() => {
      this.getItems();
    });
  }

  getBundleData(id: number) {

    // fetch('./assets/bundles.json').then(res => res.json()).then((jsonData) => {

    this._agencyService.getBundle(id).subscribe((jsonData) => {

      this.bundle = new Bundle().deserialize(jsonData);

      // loop through the bundle products and fetch them
      this.bundle.products.map((product, index) => {

        // If the bundled product is a Pass, configure it as a pass
        if (product.type === 'pass') {

          // get the pass from the API
          this._agencyService.getPass(this._agencyService.agency.uuid, product.id).pipe(
            tap((pass) => {

              this.bundle.products[index].cartProduct = pass;
              const price: TicketPrice = _.find(pass.prices, (innerPrice) => innerPrice.ticketType.id === product.ticketTypeId);
              price.priceAmount = 0;
              this.bundle.products[index].ticketPrice = price;

              for (let i = 1; i <= this.bundle.products[index].qtyIncluded; i++) {
                this.bundledItems.addPassItem(pass, this.bundle.products[index].ticketPrice);
              }

            }),
            switchMap((pass) => this._agencyService.getSeasonTicketEvents(pass.uuid)),
          ).subscribe((events) => {

            if (events.length > 0) {
              (this.bundle.products[index].cartProduct as GatePass).eventKeys.push(events[0].uuid);
            }

            if (this._cart.bundledItems.items.length === 0) {
              this._cart.bundledItems = this.bundledItems;
            }

            this.getItems();

          });

        }

        // If the bundled product is a Ticket, configure it as a ticket/event
        if (product.type === 'ticket') {

        }

      });
    });
  }

  public getItems() {
    this.passItems = _.orderBy(this._cart.bundledItems.getPassItems()
      .map(x => ({ item: x, rank: this.bundle.products.find((p) => p.id === x.product.id)?.rank })), ['rank']
    ).map(x => x.item);
    this.eventItems = this._cart.bundledItems.getEventItems();
  }

  ngAfterContentChecked() {
  }

  public hasCartItem(): boolean {
    return this._cart.hasItem(this.pass);
  }

  public getTerms(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.pass.terms);
  }

  public hasTerms(): boolean {
    return !_.isEmpty(this.pass.terms);
  }

  public prevProduct() {
    this.bundle.selectPrev();
  }

  public nextProduct() {
    this.bundle.selectNext();
  }

  public cancel() {
    this._messageService.ConfirmYesNo('This will clear your selections and release your seats for other people to select.  Are you sure?', 'Cancel Selections?').then(() => {
      this._cart.clearCart();
      this._router.navigate(['/agency', this.agency.uuid, 'passes']);
    });
  }

  public selectableProduct(productId: number): boolean {
    return this.bundle.getSelectableProducts().findIndex(p => p.id === productId) > -1;
  }

  public selectableMessage(productId: number): string {
    return this.bundle.getSelectableProducts().find(p => p.id === productId).message;
  }

  public selectSeats(pass: GatePass) {
    const product = this.bundle.getSelectableProducts().find((p) => p.id === pass.id);
    const modalRef = this._modal.open(AgencyBundleSeatmapComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.pass = product.cartProduct;
    modalRef.componentInstance.eventUUID = (product.cartProduct as GatePass).eventKeys[0];
    modalRef.componentInstance.ticketPrices = [product.ticketPrice];
    modalRef.componentInstance.maxObjects = product.qtyIncluded;
    modalRef.result.then(() => {
      this.getItems();
    }, () => {
      this.getItems();
    });
  }

  public checkout() {
    // this._cart.addItems(this.items);
    this._router.navigate([this._cart.cartRoute]);
  }

  public isOrderComplete(): boolean {
    return true;
  }

}
