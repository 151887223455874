import { ReservedSeatCategory } from './seat.model';
import { TicketPrice } from '../ticket-price.model';

export class ReservedPricing {

  public category: ReservedSeatCategory;
  public price: TicketPrice;

  constructor (category: ReservedSeatCategory, price: TicketPrice) {
    this.category = category;
    this.price = price;
  }

}
