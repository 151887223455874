<ts-section-header>
    Passes
    <div class="actions">
        <div class="btn-group float-right" role="group" aria-label="Switch between card and list Pass View">
            <button mdbRippleRadius type="button" class="btn btn-outline-secondary btn-sm" [ngClass]="{'active': !showPassList}" (click)="togglePassList()"><i class="fa fa-th-large"></i></button>
            <button mdbRippleRadius type="button" class="btn btn-outline-secondary btn-sm" [ngClass]="{'active': showPassList}" (click)="togglePassList()"><i class="fa fa-list"></i></button>
        </div>
    </div>
</ts-section-header>
<div *ngIf="passes.length > 0">
    <div *ngIf="showPassList" class="row passes">
        <div class="col-12" *ngFor="let pass of getPasses()">
            <ts-pass-list-item [pass]="pass" [schoolView]="true" [routerLink]="[ '/', 'agency', agency.uuid,'passes', pass.id]" (keydown.enter)="onKeydownEnterPass($event, pass)"></ts-pass-list-item>
        </div>
    </div>
    <div *ngIf="!showPassList" class="row passes">
        <div class="col-12 col-sm-12 col-lg-4 mb-4" *ngFor="let pass of getPasses()">
            <ts-pass-card [pass]="pass" [schoolView]="true" [routerLink]="[ '/', 'agency', agency.uuid,'passes', pass.id]" (keydown.enter)="onKeydownEnterPass($event, pass)"></ts-pass-card>
        </div>
    </div>
</div>
<div *ngIf="!passes || passes.length === 0">
    <ts-no-results [message]="noResultsMessage"></ts-no-results>
</div>
