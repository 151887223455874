import { IDeserializable } from './deserializable.interface';
import { Address } from '@app/data/models/address.model';
import { AccountSettings } from '@app/data/models/settings.model';

export class Sponsor implements IDeserializable {

  constructor() {}

  id: number;
  uuid: string;
  name: string;
  tagline: string;
  website: string;
  vanityURI?: string;
  primaryAddress?: Address;
  settings?: AccountSettings;
  logoURI: string;
  version?: string;

  static default() {
    const json: Object = {
      'version': null,
      'id': 0,
      'type': null,
      'uuid': null,
      'name': 'Hometown Ticketing',
      'website': 'http://www.hometownticketing.com',
      'vanityURI': 'www.hometownticketing.com',
      'logoURI': 'https://s3.amazonaws.com/com.ticketspicket.app/logos/HomeTown_long_rgb.png',
      'tagline': null,
      'primaryAddress': null,
      'settings': {
        'snapchatURI': null,
        'twitterURI': null,
        'primaryText': 'white',
        'timezone': 'US/Eastern',
        'secondaryActive': '#190b6f',
        'primaryColor': '#2014CC',
        'primaryHover': '#4438ec',
        'logoURI': null,
        'secondaryText': 'white',
        'primaryActive': '#190b6f',
        'coverArtURI': null,
        'instagramURI': null,
        'facebookURI': null,
        'logo2xURI': null,
        'secondaryColor': '#FF6900',
        'secondaryHover': '#ff9144'
      }
    };

    return new Sponsor().deserialize(json);

  }

  deserialize(input: any) {
    if (input.name.includes('Ticket Spicket')) {
      return Sponsor.default();
    }

    Object.assign(this, input);

    this.settings = new AccountSettings().deserialize(input.settings);

    if (input.primaryAddress) {
      this.primaryAddress = new Address().deserialize(input.primaryAddress);
    }

    return this;
  }
}
