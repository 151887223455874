import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ts-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class TicketSpicketConfirmation {
  @Input() title = 'Are you sure?';
  @Input() content = 'Are you sure?';
  @Input() confirmText = 'Yes';
  @Input() cancelText = 'No';

  constructor(public activeModal: NgbActiveModal) {}

}
