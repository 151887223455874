import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '@app/core';
import { ConsumerPassesComponent } from '@app/passes/passes.component';
import { ConsumerPassesListComponent } from '@app/passes/listing.component';
import { ConsumerPassesDetailComponent } from '@app/passes/detail.component';
const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: '',
    component: ConsumerPassesComponent,
    canActivate: [AuthenticationGuard],
    data: { showSpinner: true, title: 'Passes - Ticket Spicket' },
    // resolve: { passes: PassesResolver },
    children: [
      { path: '', component: ConsumerPassesListComponent, data: { showSpinner: true,  title: 'Pass Detail - Ticket Spicket' }},
      { path: ':pass-id', component: ConsumerPassesDetailComponent, data: { showSpinner: true, title: 'Pass Detail - Ticket Spicket' }},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class PassesRoutingModule { }
