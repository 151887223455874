import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CartItem } from '@app/data/models/cart/cart-item.model';
import { MessageService } from '../message.service';

@Component({
  selector: 'ts-cart-ticket-seats',
  templateUrl: './cart-ticket-seats.component.html'
})
export class CartTicketSeatsComponent {

  @Input() seats: CartItem[];

  @Output() remove: EventEmitter<CartItem> = new EventEmitter<CartItem>();

  constructor (
    private _message: MessageService
  ) {}

  public removeSeat(item: CartItem) {
    this._message.ConfirmYesNo('Are you sure you want to remove this Seat?').then(() => {
      this.remove.emit(item);
    });
  }

}
