import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { AgencyService } from '@app/data/services/agency.service';
import { CartService } from '@app/data/services/cart.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { CartItem, CartItemSummary } from '@app/data/models/cart/cart-item.model';
import { ReservedConfiguration } from '@app/data/models/reserved/configuration.model';
import { ReservedPricing } from '@app/data/models/reserved/pricing.model';
import { ReservedSeatCategory, ReservedSeat } from '@app/data/models/reserved/seat.model';
import { Event } from '@app/data/models/event.model';
import { Agency } from '@app/data/models/agency.model';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-agency-season-reserved',
  templateUrl: './season-reserved.component.html',
  styleUrls: ['./season-reserved.component.scss']
})
export class AgencySeasonReservedComponent implements OnInit {

  public seats: {};
  public chart: any;
  public isLoading = true;

  public agency: Agency;
  public pass: GatePass = new GatePass();
  public events: Event[] = new Array<Event>();

  public config: ReservedConfiguration;

  constructor (
    private _route: ActivatedRoute,
    private _spinner: SpinnerService,
    public cart: CartService,
    private _agencyService: AgencyService,
    private _message: MessageService
  ) {
    this.agency = this._agencyService.agency;
    this._spinner.setMessage('Loading Season Tickets');
  }

  ngOnInit() {

    this._route.params.pipe(
      map( (params) => params['season-id']),
      switchMap( (id: string) => this._agencyService.getSeasonTicket(id)),
      map((season) => this.pass = season),
      switchMap(() => this.cart.getHoldToken()),
      map((token) => this.config = new ReservedConfiguration(token)),
      switchMap(() => this._agencyService.getSeasonTicketEvents(this.pass.uuid)),
      map((events) => this.events = events.map((event) => new Event().deserialize(event)))
    )
      .subscribe(() => {
        // loop through the pricing and set the pricing collection
        this.pass.prices.map((price) => {
          this.config.pricing.push(new ReservedPricing(new ReservedSeatCategory().deserialize(price.ticketType.reservedCategory), price));
        });
        // RJH 06/11/2021 - just using the first event
        this.config.setSeasonEvent(this.events[0].uuid);
        this.pass.eventKeys = this.config.seasonEventKeys;
        this.isLoading = false;

        this._spinner.hide();
      });

  }

  public selectSeat(seat: any) {
    this.cart.addReservedItem (
      this.pass,
      new ReservedSeat().deserialize(seat),
      this.config.getTicketPriceByCategoryKey(seat.category.key, null),
      null
    );
  }

  public removeSeat(seat: any) {
    this.cart.removeReservedSeat(this.pass, new ReservedSeat().deserialize(seat)).subscribe(() => {
      this.removeCartSeat(seat);
    });
    // this.cart.removeReservedSeat(this.pass, new ReservedSeat().deserialize(seat.id));
    // this.removeCartSeat(seat);
  }

  public removeCartSeat(seat: ReservedSeat) {
    const seats: Array<string> = new Array<string>();
    seats.push(seat.key);
    this.chart.deselectObjects(seats);
  }

  public getReservedSeats(): Array<CartItem> {
    const summary: CartItemSummary = this.cart.items.getProductItemSummary(this.pass);
    if (summary) {
      return summary.seats;
    } else {
      return new Array<CartItem>();
    }
  }

  public setChart(chart: any) {
    this.chart = chart;
  }

  public ngOnDestroy(): void {
    // //Called once, before the instance is destroyed.
    // //Add 'implements OnDestroy' to the class.
    // this.chart.destroy();
  }

}
