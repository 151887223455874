<div style="overflow:hidden;">
    <div class="container bg-white pb-4" *ngIf="agency && pass" style="position:relative;z-index:100;" [class.embedded]="_agencyService.embedded">
        <app-agency-season-ticket-header [pass]="pass"></app-agency-season-ticket-header>
        <nav aria-label="breadcrumb" class="d-none d-sm-block">
            <ol class="breadcrumb">
                <li class="breadcrumb-item font-sm">
                    <a [routerLink]="['/agency', agency.uuid]">{{ _agencyService.embedded ? 'Events & Passes' : agency.name }}</a>
                </li>
                <li class="breadcrumb-item active font-sm" aria-current="page">{{ pass.name }}</li>
            </ol>
        </nav>
        <button class="btn btn-sm btn-light btn-block d-sm-none d-block text-center mt-3" [routerLink]="['agencies', agency.uuid]">
            <i class="fa fa-arrow-left mr-2"></i>Back to Agency
        </button>
        <div *ngIf="!ticketHolder.isValid()" class="mb-5">
            <ts-section-header>
                <i class="fa fa-fw fa-lock mr-1"></i>Season Ticket Renewal Access Code
            </ts-section-header>
            <p>Enter your season ticket renewal code in order to review your season tickets.</p>
            <form (ngSubmit)="findTicketHolder()" [formGroup]="formChannel" novalidate>
                <div class="input-group mb-3">
                    <input type="text" class="form-control search-input" name="accessKey" formControlName="accessKey" placeholder="Enter Season Ticket Renewal Code" autocomplete="off">
                    <div class="input-group-append">
                        <button mdbrippleradius class="btn btn-primary" type="submit" [disabled]="formChannel.invalid || isLoading">
                            <i class="fa fa-spinner fa-spin mr-2" *ngIf="isLoading"></i>
                            <i class="fa fa-search mr-2" *ngIf="!isLoading"></i>
                            Find My Tickets
                        </button>
                    </div>
                </div>
                <div class="alert alert-danger" *ngIf="error">
                    <strong>{{ error }}</strong>
                    <p class="mb-0">If you think this is an error, please contact our customer support team (support@ticketspicket.com)</p>
                </div>
            </form>
        </div>

        <div class="row mt-2 mb-4" *ngIf="ticketHolder.isValid() && ticketHolder.currentPhase() === 1">
            <div class="col-12 col-md-6">
                <ts-section-header>
                    <i class="fas fa-fw fa-user mr-2"></i>Ticket Renewal Holder Info
                </ts-section-header>
                <div class="font-sm">
                    This renewal has been assigned to:
                    <span class="pull-right"><a [routerLink]="[]" [queryParams]="{key: null}" queryParamsHandling="merge">Have another code?</a></span>
                </div>
                <div class="pb-5 px-3">
                    <ts-read-only label="Access Code:" value="{{ticketHolder.accessKey}}"></ts-read-only>
                    <ts-read-only label="First & Last Name:" value="{{ticketHolder.firstName}} {{ticketHolder.lastName}}"></ts-read-only>
                    <ts-read-only label="Email Address:" value="{{ticketHolder.emailAddress}}"></ts-read-only>
                </div>
                <ts-section-header>
                    <i class="fa fa-fw fa-check mr-2"></i>Seats Already Renewed
                </ts-section-header>
                <div class="font-sm">
                    The following seats have already been renewed for the season:
                </div>
                <div class="text-center py-3" *ngIf="getPurchasedSeats().length === 0">
                    <h5 class="h4-responsive px-3 mt-3 border-0" style="font-weight:400;">You have not renewed any season tickets yet.</h5>
                </div>
                <div class="text-left" style="overflow:hidden;">
                    <mat-list style="background:transparent;">
                        <mat-list-item *ngFor="let seat of getPurchasedSeats()" style="background:white; " class="">
                            <i mat-list-icon class="fas fa-lg fa-ticket-alt text-success"></i>
                            <p class="h5" mat-line>
                                Reserved Seat: <strong>{{ seat.seatKey }}</strong>
                                <span style="float: right"><strong>{{ seat.amountPrice | currency }}</strong></span>
                            </p>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <ts-section-header>
                    <i class="fas fa-fw fa-ticket-alt mr-2"></i>Available Seats
                </ts-section-header>
                <div class="font-sm">
                    When you are finished choosing your seats, click the "Checkout" button to pay for your selection.
                </div>

                <div class="text-center py-3" *ngIf="getAvailableSeats().length === 0">
                    <h5 class="h4-responsive px-3 mt-3 border-0" style="font-weight:400;">There are no more available seats for this renewal - they have all been purchased.</h5>
                </div>
                <div class="p-3 text-left" style="overflow:hidden;">
                    <mat-list style="background:transparent;">
                        <mat-list-item class="border rounded mb-2" [ngClass]="{'border-success': isSelected(seat)}" style="background:white;height:47px;cursor:pointer;" *ngFor="let seat of getAvailableSeats()" (click)="toggleSeat(seat)">
                            <i mat-list-icon class="mt-1 mr-3 fa fa-lg fa-fw" [ngClass]="{'fa-check-square text-success': isSelected(seat), 'fa-square-o': !isSelected(seat) } "></i>
                            <p class="h5" mat-line>
                                Reserved Seat: <strong>{{ seat.seatKey }}</strong>
                                <span style="float: right"><strong>{{ seat.amountPrice | currency }}</strong></span>
                            </p>
                            <!-- <p class="h5" mat-line><strong>Section:</strong> {{ seat.seatKey }} <strong>Seat:</strong> {{ "1" }}-{{ "1" }}</p> -->
                        </mat-list-item>
                    </mat-list>
                </div>
                <hr />
                <div class="text-right mb-3">
                    <div class="mb-2">
                        <strong># Selected:</strong>
                        <div class="d-inline-block text-right" style="width:89px;">{{selectedSeats.length }}</div>
                    </div>
                    <div class="mb-2">
                        <strong>Total Amount:</strong>
                        <div class="d-inline-block text-right" style="width:89px;">{{getTotalAmount() | currency }}</div>
                    </div>
                    <div class="mb-2 text-right text-muted">
                        (plus applicable fees)
                    </div>
                </div>
                <div class="row text-center my-3">
                    <div class="col-12">
                        <button mdbRippleRadius type="button" [disabled]="selectedSeats.length === 0" class="btn btn-secondary w-100" (click)="checkout()">
                            <i class="fa fa-credit-card mr-2"></i>Checkout
                        </button>
                    </div>
                </div>
                <div class="font-sm text-center pt-2">
                    <span *ngIf="selectedSeats.length === 0">No seats have been selected.</span>&nbsp;
                </div>
            </div>
        </div>

        <svg class="background">
            <use xlink:href="#background" x="0px" y="0px"></use>
        </svg>
    </div>
</div>
