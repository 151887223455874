import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@env/environment';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { HomeModule } from './home/home.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AgencyModule } from '@app/agency/agency.module';
import { TicketsModule } from '@app/tickets/tickets.module';
import { CartModule } from '@app/cart/cart.module';
import { DataModule } from '@app/data/data.module';
import { TicketSpicketModule } from '@app/shared/ticket-spicket/ticket-spicket.module';
import { CordovaService } from '@app/data/services/cordova.service';
// import { MDBBootstrapModule } from 'angular-bootstrap-md';
import 'hammerjs';
import { PassesModule } from './passes/passes.module';
import { SpinnerService } from './shared/ticket-spicket/spinner.service';

import * as Sentry from '@sentry/angular';
import { Router, UrlSerializer } from '@angular/router';
import { Angulartics2Module } from 'angulartics2';
import { ClerkService } from './core/authentication/clerk.service';
import { CustomUrlSerializer } from './core/customUrlSerializer';
import { errorExtractor } from './core/sentry.util';

// Configs

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    CoreModule,
    DataModule,
    SharedModule,
    HomeModule,
    Angulartics2Module.forRoot(),
    AppRoutingModule,
    AgencyModule,
    TicketsModule,
    PassesModule,
    CartModule,
    TicketSpicketModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    ClerkService,
    SpinnerService,
    CordovaService,
    CookieService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        extractor: errorExtractor
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
