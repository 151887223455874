import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { Agency } from '@app/data/models/agency.model';
import { CartService } from '@app/data/services/cart.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { CartItemCollection } from '@app/data/models/cart/cart-item-collection.model';
import { TicketPrice } from '@app/data/models/ticket-price.model';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-pass-family-selection-modal',
  templateUrl: './select-family.component.html',
  styleUrls: ['./select-family.component.scss']
})
export class FamilyPassSelectionModalComponent implements OnInit {

  @Input() product: GatePass;

  public agency: Agency;
  public loading = false;

  public selectedQty = 0;
  public selectedOption = 0;
  public selectedPrice: TicketPrice = new TicketPrice();
  public hasSelectedPrice = false;

  public selectionForm: FormGroup;
  public members: FormArray;

  public cartItems: CartItemCollection = new CartItemCollection();

  public ticketPrices: TicketPrice[] = new Array<TicketPrice>();



  constructor (
    public activeModal: NgbActiveModal,
    private _cartService: CartService,
    private _messageService: MessageService,
    private _router: Router,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {

    this.createForm();
    this.agency = this.product.agency;
    this.ticketPrices = this.product.getGATicketPrices();
    this.selectedQty = 0;
    this.selectedPrice = new TicketPrice();

    if (this.ticketPrices.length === 1) {
      this.selectedOption = this.ticketPrices[0].id;
      this.selectOption(this.selectedOption);
    }

  }

  private createForm() {
    this.selectionForm = this._formBuilder.group({
      selectedOption: ['', Validators.required],
      members: this._formBuilder.array([])
    });
  }

  private _createMemberItem(): FormGroup {
    return this._formBuilder.group({
      name: null
    });
  }

  private _setMemberItems(): void {

    this.members = this.selectionForm.get('members') as FormArray;
    const addMember: boolean = this.selectedQty > this.members.length;

    if (addMember) {
      this.members.push(this._createMemberItem());
    } else {
      for (let i = this.members.length; i > this.selectedQty; i--) {
        this.members.removeAt(i - 1);
      }
    }

  }

  public selectOption(priceId: number) {

    const id = _.toNumber(priceId);

    this.cartItems = new CartItemCollection();
    this.hasSelectedPrice = false;

    if (id === 0) {
      this.selectedPrice = new TicketPrice();
      this.selectedQty = 0;
    } else {
      this.selectedPrice = this.product.prices.find((price) => price.id === id);
      this.selectedQty = 0; // this.selectedPrice.optionQuantity;
      this.addTicket(this.selectedPrice);
      this.hasSelectedPrice = true;
    }

    this._setMemberItems();
  }

  public updateSelectedQty(value: number) {
    this.selectedQty += value;
    this.cartItems.items.map((item) => item.selectedQty = this.selectedQty);
    this._setMemberItems();
  }

  public addTicket(price: TicketPrice) {
    this.cartItems.addPassItem(this.product, price, this.selectedQty);
  }

  public removeTicket(price: TicketPrice) {
    this.cartItems.removeItem(this.product, price);
  }

  public addToCart() {
    this.cartItems.items[0].members = this.selectionForm.get('members').value;
    this._cartService.addItems(this.cartItems);
    this._messageService.toast.success('Your tickets have been added to the cart.');
    this.activeModal.close('cart');
  }

  public checkout() {
    this.cartItems.items[0].members = this.selectionForm.get('members').value;
    this._cartService.addItems(this.cartItems);
    this._router.navigate([this._cartService.cartRoute]);
    this.activeModal.close('checkout');
  }

  public canAddToCart(): boolean {
    return this.selectionForm.valid && !this.cartItems.isEmpty() && this.selectedQty > 0;
  }

  public close() {
    this.activeModal.dismiss(false);
  }

}
