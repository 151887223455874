import { Component, OnInit, Input } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { Agency } from '@app/data/models/agency.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agency-passes-list',
  templateUrl: './passes-list.component.html',
  styleUrls: ['./passes-list.component.scss']
})
export class AgencyPassesListComponent implements OnInit {

  @Input() passes: GatePass[];
  @Input() agency: Agency;
  @Input() showCount = -1;

  public showPassList = false;
  public noResultsMessage = 'passes at this time';

  constructor (public router: Router) { }

  ngOnInit() { }

  togglePassList() {
    this.showPassList = !this.showPassList;
  }

  getPasses(): GatePass[] {
    if (this.showCount > 0) {
      return this.passes.slice(0, this.showCount);
    }
    return this.passes;
  }

  public onKeydownEnterPass(keyboardEvent: KeyboardEvent, pass: GatePass) {
    this.router.navigate([ '/', 'agency', this.agency.uuid, 'passes', pass.id]);
  }
}
