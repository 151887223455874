import { Component, Input, OnInit } from '@angular/core';
import { User } from '@app/data/models/user.model';
import { UserService } from '@app/data/services/user.service';
import { ISchool } from '@app/data/models/school.interface';
import { MobileService } from '@app/data/services/mobile.service';

@Component({
  selector: 'ts-agency-footer',
  templateUrl: './agency-footer.component.html',
  styleUrls: ['./agency-footer.component.scss']
})

export class AgencyFooterComponent implements OnInit {
  public user: User;
  public following = false;

  @Input() agency: ISchool;

  constructor (
    private _userService: UserService,
    public mobileService: MobileService
  ) {
    this.user = this._userService.user;
  }

  ngOnInit() {
  }



}
