import { Event } from '@app/data/models/event.model';
import { Ticket } from '@app/data/models/ticket.model';
import * as _ from 'lodash';
import { ReservedSeat } from './reserved/seat.model';

export class TicketBundle {

  event: Event;
  tickets: Ticket[] = new Array<Ticket>();
  dateRedeemed: Date;
  levels: any[] = new Array<any>();
  seats: ReservedSeat[] = new Array<ReservedSeat>();
  qrCode: string;

  constructor(event: Event, tickets: Ticket[]) {
    this.event = event;
    this.tickets = tickets;
    const ticketNumbers = this.tickets.map((ticket) => ticket.ticketNumber).join(':');
    this.qrCode = 'v2|' + this.event.uuid + '|' + ticketNumbers;

    this.seats = this.tickets
      .filter((ticket) => ticket.seatNumber !== null)
      .map((ticket) => new ReservedSeat().deserialize(ticket.seatNumber));

    this.levels = _
      .chain(this.tickets)
      .filter((value) => value.level !== null && value.seatNumber === null)
      .groupBy(function(value: Ticket) {
        return value.level.id;
      })
      .map((value: Ticket[]) => {
        const numbers = value.map((ticket: Ticket) => ticket.ticketNumber);
        return {
          level: value[0].level,
          count: value.length,
          numbers
        };
      })
      .value();
  }

}
