import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consumer-passes',
  templateUrl: './passes.component.html'
})
export class ConsumerPassesComponent implements OnInit {


  constructor () { }

  ngOnInit() { }

}

