import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgencyService } from '@app/data/services/agency.service';
import { Agency } from '@app/data/models/agency.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgencyListModalComponent } from './agency-list.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';

@Component({
  selector: 'app-agency-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class AgencyHomeComponent implements OnInit, OnDestroy {

  private _events$: Subscription;
  private _passes$: Subscription;
  constructor (
    private _agencyService: AgencyService,
    public _modalService: NgbModal,
    private router: Router,
    private _spinner: SpinnerService
  ) {
    this._spinner.setMessage('Loading ' + this.agency.name);
  }

  ngOnInit() {
    this._events$ = this._agencyService.getEvents(this.agency.uuid).subscribe(() => {
      this._spinner.hide();
    });
    this._passes$ = this._agencyService.getPasses(this.agency.uuid).subscribe();
  }

  get agency(): Agency {
    return this._agencyService.agency;
  }

  getEvents() {
    return this.agency.events;
  }

  getPasses() {
    return this.agency.passes;
  }

  hasEvents(): boolean {
    return this._agencyService.hasEvents() && this._agencyService.hasFilteredEvents();
  }

  goToAgency(uuid: string) {
    this.router.navigate(['/agency', uuid], { replaceUrl: true });
  }

  public agencyList(agencies: Agency[]) {
    const modalRef = this._modalService.open(AgencyListModalComponent, { size: 'lg' });
    modalRef.componentInstance.agencies = agencies;
  }

  ngOnDestroy() {
    this._events$.unsubscribe();
    this._passes$.unsubscribe();
  }

  public goToRenewal() {
    this.router.navigate(['/renewal'], { replaceUrl: true });
  }

}
