import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '@app/data/models/user.model';
import { Payment } from '@app/data/models/payment.model';

export interface SimpleUser {
  dateRegistered: Date;
  email: string;
  id: number;
  name: string;
  nameFirst: string;
  nameLast: string;
  uuid: string;
  verifyUUID: string;
}

@Injectable()
export class UserService {

  public user: User = new User();
  public payments: Payment[] = new Array<Payment>();
  public baseUrl = 'fans/rewards';
  public user$ = new Subject<User>();

  constructor() {

  }
}
