import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ApplicationVersion } from '@app/data/models/application-version.model';
import { ApplicationService } from '@app/data/services/application.service';
import { CartService } from '@app/data/services/cart.service';
import { MobileService } from '@app/data/services/mobile.service';
import { TransferService } from '@app/data/services/transfer.service';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { IncomingTransferBottomSheet } from '@app/shared/ticket-spicket/bottom-sheet/transfer-bottom-sheet/incoming-transfer-bottom-sheet.component';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
import { environment } from '@env/environment';
import { Subscription, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {

  public isAgencyPage = false;
  public version: ApplicationVersion;
  public currentVersion: string = environment.version;
  public inCart: boolean;
  public eventsUrl: string = environment.eventsUrl; // This is the new Events URL on HTT

  private _checkVersionIntervalInSeconds = 60;
  private checkVersion$: Subscription;

  constructor(
    public cart: CartService,
    public router: Router,
    public mobileService: MobileService,
    private _bottomSheet: MatBottomSheet,
    private _transferService: TransferService,
    private _messageService: MessageService,
    private _authenticationService: AuthenticationService,
    private _userAdmissionService: UserAdmissionService,
    private _applicationService: ApplicationService,
    private _spinner: SpinnerService,
  ) {
    // Added below router check to adjust cart language based on whether on an agency page or not. - ELH
    this.router.events.subscribe((event) => {
      this.inCart = this.router.url.indexOf('cart') > -1;
      if (this.router.url.indexOf('agency') > -1) {
        this.isAgencyPage = true;
      } else {
        this.isAgencyPage = false;
      }
      if (event instanceof NavigationEnd
                && this.router.url.indexOf('cart') === -1) {
        this.cart.clearCart();
      }
    });
    this.mobileService.deviceCheck();
  }

  public ticketBadgeCount = 0;

  ngOnInit() {
    this.checkVersion$ = interval(this._checkVersionIntervalInSeconds * 1000).pipe(
      switchMap(() => this._applicationService.getVersion())
    ).subscribe((version) => this.version = version);

    this.mobileService.deviceCheck();

    this._userAdmissionService.ticketBadgeCount$.subscribe(res => {
      this.ticketBadgeCount = res;
    });
  }

  ngOnDestroy() {
    this.checkVersion$.unsubscribe();
  }

  hasNewVersion() {
    if (this.version) {
      return this.version.version !== environment.version;
    } else {
      return false;
    }
  }

  /**
   * basically
   */
  public refreshApp() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations: ServiceWorkerRegistration[]) {
        if (registrations.length === 0) {
          return;
        }
        for (const registration of registrations) {
          // registration.update();  does not work!
          registration.unregister();
        }
        window.location.reload();
      });
    }
  }

  get hasIncomingTransfer(): boolean {
    return this._transferService.getIncomingTransferCount() > 0;
  }

  public openBottomSheet(): void {
    const bottomSheetRef = this._bottomSheet.open(IncomingTransferBottomSheet);
    bottomSheetRef.afterDismissed().subscribe((choice: string) => {
      switch (choice) {
        case 'accept':
          this._messageService.toast.success('Your Ticket Transfer has been accepted.');
          break;
        case 'decline':
          this._messageService.toast.success('Your Ticket Transfer has been declined successfully.');
          break;
        default: break;
      }
    });
  }

  public isAuthenticated(): boolean {
    return this._authenticationService.isAuthenticated();
  }

  public logout() {
    this._spinner.show();
    this._authenticationService.logout().subscribe(
      () => {
        this.router.navigate(['/home'], { replaceUrl: true });
        this._spinner.hide();
      });
  }

}
