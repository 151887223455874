import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Route } from '@app/core';
import { RedirectGuard } from './app-redirect-guard.module';
import { environment } from '@env/environment';

const routes: Routes = [
  Route.withShell([
    { path: 'r/agency/:agency-id/events/:event-id', redirectTo: 'agency/:agency-id/events/:event-id', pathMatch: 'full' },
    { path: 'r/agency/:agency-id', redirectTo: 'agency/:agency-id', pathMatch: 'full' },
    { path: 'agency', loadChildren: () =>  import('./agency/agency.module').then(m => m.AgencyModule) },
    { path: 'passes', loadChildren: () =>  import('./passes/passes.module').then(m => m.PassesModule) },
    { path: 'cart', loadChildren: () =>  import('./cart/cart.module').then(m => m.CartModule) }
  ]),

  {
    path: 'tickets',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: `${ environment.eventsUrl }/tickets`
    }
  },
  {
    path: 'account',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: `${ environment.eventsUrl }/account`
    }
  },
  {
    path: 'order-history',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: `${ environment.eventsUrl }/order-history`
    }
  },
  {
    path: 'sign-in',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: `${ environment.eventsUrl }/sign-in`
    }
  },
  {
    path: 'sign-up',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: `${ environment.eventsUrl }/sign-up`
    }
  },

  // old urls from v1
  { path: 'app/school/:uuid', redirectTo: '/agency/:uuid' },
  { path: 'app/school/:uuid/events/:eventid', redirectTo: '/agency/:uuid/events/:eventid' },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [RedirectGuard]
})
export class AppRoutingModule { }
