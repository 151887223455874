import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalid-bundle',
  templateUrl: './invalid-bundle.component.html',
  styleUrls: ['./shared.component.scss']
})
export class InvalidBundleComponent implements OnInit {

  constructor () {
  }

  ngOnInit() {
  }

}
