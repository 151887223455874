import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TicketBundle } from '@app/data/models/ticket-bundle';
import * as moment from 'moment';
import { ITicketService } from '@app/shared/ticket-spicket/interfaces/ticket-service.interface';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import { Ticket } from '@app/data/models/ticket.model';
import { UserAdmissionService } from '@app/data/services/user-admission.service';

@Component({
  selector: 'app-ticket-redeem',
  templateUrl: './ticket-redeem.component.html',
  styleUrls: ['./ticket-redeem.component.scss']
})
export class TicketRedeemComponent implements OnInit {

  @Input()
  public eventTicket: EventAdmission;

  @Input()
  private service: ITicketService;

  @Output()
    redeemProcess = new EventEmitter<boolean>();

  public selectedTickets: Ticket[] = new Array<Ticket>();

  public bundle: TicketBundle;
  public step = 1;
  public now: Date = new Date;

  constructor (
    private _userAdmissionService: UserAdmissionService
  ) { }

  ngOnInit() {
    if (this.eventTicket.getUnusedTickets().length === 1 && this.eventTicket.getUsedTickets().length === 0) {
      this.selectAll();
    }
  }

  updateStep(step: number) {
    if (step === 2) {
      this.redeemProcess.emit(true);
    }
    this.bundle = new TicketBundle(this.eventTicket.event, this.selectedTickets);
    this.step = step;
  }

  selectAll() {
    this.bundle = new TicketBundle(this.eventTicket.event, this.eventTicket.getUnusedTickets());
    this.step = 2;
    this.redeemProcess.emit(true);
  }

  onRedeemed(checkedIn: boolean) {
    if (checkedIn) {
      this._userAdmissionService.getEventTickets(this.eventTicket.event.uuid).subscribe();
      // this.activeModal.close();
    }
  }

  gatesOpen() {
    return moment(this.now).isAfter(this.eventTicket.event.dateGates);
  }

  /**
   * returns the index number for the given ticket in the selectedTickets collection
   *
   */
  private _findTicket(uuid: string): number {
    return this.selectedTickets.findIndex((ticket) => ticket.uuid === uuid);
  }

  toggleTicket(uuid: string) {

    // first, check to see if the tickets are in the selected collection
    const index = this._findTicket(uuid);

    if (index >= 0) {
      // if the ticket was found, remove it
      this.selectedTickets.splice(index, 1);
    } else {
      // Ticket was not already in the collection, so add it
      const tickets = this.eventTicket.getTickets().find((ticket) => ticket.uuid === uuid);
      this.selectedTickets.push(tickets);
    }
  }

  isTicketSelected(uuid: string): boolean {
    return this._findTicket(uuid) >= 0;
  }

}
