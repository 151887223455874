import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Agency } from '@app/data/models/agency.model';
import { AgencyService } from '@app/data/services/agency.service';
import { catchError, take } from 'rxjs/operators';

@Injectable()
export class AgencyResolver implements Resolve<Agency> {

  constructor (
    private _agencyService: AgencyService,
    private router: Router
  ) {}

  resolve (
    route: ActivatedRouteSnapshot
  ): Observable<Agency> {
    const id: string = route.paramMap.get('agency-id');
    return this._agencyService.getAgency(id).pipe(
      catchError(() => {
        this.router.navigate(['/']);
        return of (null);
      }),
      take(1)
    );
  }

}
