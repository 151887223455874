import { Component, OnInit } from '@angular/core';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { ConsumerPass } from '@app/data/models/passes/consumer-pass.model';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
import { PassService } from '@app/data/services/pass.service';
import { PassFilter } from '@app/data/models/passes/pass-filter.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PassFilterModalComponent } from './filter.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-consumer-passes-list',
  templateUrl: './listing.component.html'
})
export class ConsumerPassesListComponent implements OnInit {

  public filter: PassFilter = new PassFilter();

  public noResultsMessage = 'passes at this time';
  public passes: ConsumerPass[] = new Array<ConsumerPass>();
  public filteredPasses$ = new BehaviorSubject<ConsumerPass[]>(new Array<ConsumerPass>());

  constructor(
    private _userAdmissionService: UserAdmissionService,
    private _passService: PassService,
    private _spinner: SpinnerService,
    private _modalService: NgbModal
  ) {
    this._spinner.setMessage('Loading Passes');
  }

  ngOnInit() {
    this._userAdmissionService.loadAdmission().subscribe(() => {
      this.passes = this._passService.passes;
      this.filter.setPasses(this.passes);
      this.filteredPasses$.next(this.filter.getPasses());
      this._spinner.hide();
    });
  }

  public showFilter() {
    const modalRef = this._modalService.open(PassFilterModalComponent, { centered: true });
    modalRef.componentInstance.filter = this.filter;
    modalRef.result.then(() => {
      this.filteredPasses$.next(this.filter.getPasses());
    }, () => {
      this.filteredPasses$.next(this.filter.getPasses());
    });
  }
}

