import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TicketBundle } from '@app/data/models/ticket-bundle';
import { ITicketService } from '@app/shared/ticket-spicket/interfaces/ticket-service.interface';
import { TicketShare } from '@app/data/models/ticket-share.model';
import { Ticket } from '../models/ticket.model';
import { APIServiceWithAuth } from '@app/core/http/token.interceptor';

@Injectable()
export class TicketShareService implements ITicketService {

  public share: TicketShare;

  public basePath = 'fans/share';

  private _http: HttpClient;

  constructor (
    APIService: APIServiceWithAuth
  ) {
    this._http = APIService.httpClient;
  }

  public getShare(shareUUID: string): Observable<TicketShare> {

    const url = `${this.basePath}/${shareUUID}`;

    return this._http.get<TicketShare>(url).pipe (
      map( (share) => this.share = new TicketShare().deserialize(share)),
    );
  }

  public selfCheckIn(bundle: TicketBundle): Observable<Ticket[]> {

    const url = `${this.basePath}/${this.share.uuid}/redeem`;

    const ticketNumbers: string[] = bundle.tickets.map((ticket) => ticket.ticketNumber);

    const checkin: Object = {
      eventUUID: bundle.event.uuid,
      tickets: ticketNumbers
    };

    return this._http.post(url, checkin).pipe(
      switchMap(() => this.getShare(this.share.uuid)),
      map((share) => share.admission.getTickets())
    );

  }

}
