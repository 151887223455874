import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class WeatherService {

  ROOT_URL = 'https://api.darksky.net/forecast/cf2635b7a1d2c52a3e796c4073c83c40/';
  forecast: any;

  constructor(private _http: HttpClient) { }

  currentForecast(lat: number, lng: number): Observable<any> {
    // let params = new HttpParams()
    // params = params.set('lat', lat.toString())
    // params = params.set('lng', lng.toString())

    const fullUrl = this.ROOT_URL + lat.toString() + lng.toString;

    return this._http.get(fullUrl).pipe(
      map((response: any) => this.forecast = response)
    );
  }

}
