<div class="modal-body p-0">
    <button mdbRippleRadius type="button" class="close" style="position:absolute;right:10px;top:3px;opacity:1;cursor:pointer;z-index:2;" aria-label="Close" (click)="close()">
        <span style="font-size:3rem;font-weight:300;color:white;" aria-hidden="true">&times;</span>
    </button>
    <div class="modal-header-color single">
        <ts-ticket-selection-event-header [event]="event"></ts-ticket-selection-event-header>
    </div>
  <div class="modal-body p-0" style="overflow:hidden;">
    <mat-accordion class="ticket-selection-container">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Seat Selections ({{ (reservedSeats$ | async).length }})
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="variation-content">
                <div class="my-4 text-center selection-empty pb-4" *ngIf="(reservedSeats$ | async).length === 0">
                    No seats are currently selected.<br />
                    Choose from the seat map below.
                </div>
                <div class="text-center list">
                    <div class="seat-selection" *ngFor="let item of reservedSeats$ | async">
                        <div class="seat-icon">
                            {{item.seat.section}}
                        </div>
                        <div class="details">
                            <div class="float-right text-right" style="line-height:40px;">
                                <strong>{{item.seat.price | currency}}</strong>
                            </div>
                            <div class="float-left text-left font-sm">
                                Row {{item.seat.row}}<br />
                                Seat {{item.seat.number}}
                            </div>
                            <div class="float-left text-center">
                                <div class="row">
                                    <div class="col-6 offset-3 font-weight-bold">
                                        {{item.ticketPrice.displayName}}
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both;"></div>
                        </div>
                        <div class="remove">
                            <button type="button" class="btn btn-danger rounded-circle btn-sm" (click)="removeSeat(item.seat)">
                                <i class="fa fa-minus"></i>
                            </button>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="p-2 checkout-button">
        <button mdbRippleRadius type="button" class="btn btn-secondary w-100" [routerLink]="cart.cartRoute" (click)="close()" [disabled]="(reservedSeats$ | async).length === 0">
            <i class="fa fa-credit-card mr-2"></i>Checkout
        </button>
    </div>
    <div class="text-center border-top">
        <ts-seat-map [config]="config" mode="normal" (seatObjectSelected)='selectSeat($event)' (seatObjectDeselected)='deselectSeat($event)' (onRender)="setChart($event)" *ngIf="!isLoading"></ts-seat-map>
    </div>
</div>
<div class="text-center modal-close-button mt-3">
  <button mdbRippleRadius type="button" class="btn btn-light" aria-label="Cancel" style="min-width:150px;" (click)="close()">Done</button>
</div>
<ts-agency-style [settings]="event.agency.settings"></ts-agency-style>
