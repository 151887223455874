import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PassService } from '@app/data/services/pass.service';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketModalComponent } from '@app/tickets/ticket/ticket-modal.component';
import { EventAdmission } from '@app/data/models/tickets/event-admission.model';
import { switchMap } from 'rxjs/operators';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { ConsumerPunchPass } from '@app/data/models/passes/consumer-punch-pass.model';

@Component({
  selector: 'app-consumer-pass-punch-modal',
  templateUrl: './pass-punch-modal.component.html',
  styleUrls: ['../pass.component.scss']
})
export class ConsumerPassPunchModalComponent implements OnInit {

  @Input() admission: EventAdmission;

  public punchPass: ConsumerPunchPass = new ConsumerPunchPass();
  public isLoading = false;

  constructor (
    public activeModal: NgbActiveModal,
    private _userAdmissionService: UserAdmissionService,
    private _passService: PassService,
    private _messageService: MessageService,
    private _modalService: NgbModal
  ) { }

  ngOnInit() {
    this.punchPass = this.admission.getPunchPass();
  }

  claimTickets() {

    if (this.punchPass.getTotalSelectedQty() === 0) {
      return;
    }

    this._messageService.ConfirmYesNo('Are you sure you would like to claim the selected tickets for this event?', 'Claim Tickets').then(() => {

      this.isLoading = true;

      this._passService.usePunchPass(this.admission.event, this.punchPass).pipe(
        switchMap(() => this._userAdmissionService.getEventTickets(this.admission.event.uuid))
      ).subscribe(
        () => {
          this._messageService.toast.success('Navigate to <a href="tickets">"My Tickets"</a> to view your claimed tickets', 'Success', { timeOut: 5000, extendedTimeOut: 2500, enableHtml: true });
          if (this.admission.event.areGatesOpen()) {
            this.viewTickets();
          } else {
            this.activeModal.close();
          }
          this._userAdmissionService.loadAdmission().subscribe();
        },
        (error) => {
          this._messageService.toast.error(error.error);
          this.isLoading = false;
        }
      );

    });
  }

  viewTickets() {
    this.activeModal.close(this.admission.event);
    const modalRef = this._modalService.open(TicketModalComponent, { centered: true });
    modalRef.componentInstance.eventTicket = this.admission;
  }

  close() {
    this.activeModal.dismiss(false);
  }

}
