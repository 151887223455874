import { IDeserializable } from '@app/data/models/deserializable.interface';
import { Activity } from '@app/data/models/activity.model';
import { TicketPrice } from '@app/data/models/ticket-price.model';
import { Agency } from '@app/data/models/agency.model';
import { ITypeSeason } from '@app/data/models/domain.interface';
import * as moment from 'moment';
import { Sponsor } from '@app/data/models/sponsor.model';
import { ConsumerGatePass } from '@app/data/models/passes/consumer-gate-pass.model';
import * as _ from 'lodash';
import { CartItemProduct } from '../cart/cart-item.model';
import { GatePassType, GatePassTypes } from './gate-pass-type.model';

export class GatePass implements IDeserializable, CartItemProduct {

  public id: number;
  public uuid: string;
  public name: string;
  public description: string;
  public terms: string;

  public gatePassType: GatePassType;

  public dateOnSale: Date;
  public dateOffSale: Date;
  public prices: TicketPrice[];

  public activity: Activity;
  public season: ITypeSeason;

  public agency: Agency;

  public analytics: any;
  public availableEventCount: number;
  public ticketQuantity: number;
  public exclusions: any[];

  public sponsor: Sponsor;

  public consumers: ConsumerGatePass[] = new Array<ConsumerGatePass>();

  public isPunchPass: boolean;
  public isFamilyPass: boolean;

  public rank: number;

  public get isSeasonPass(): boolean {
    return this.gatePassType.isType(GatePassTypes.season);
  }
  public get isBundle(): boolean {
    return this.gatePassType.isType(GatePassTypes.bundle);
  }
  public eventKeys: string[] = new Array<string>();

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);

    this.gatePassType = new GatePassType().deserialize(input.gatePassType);

    if (input.activity) {
      this.activity = new Activity().deserialize(input.activity);
    }

    if (input.school) {
      this.agency = new Agency().deserialize(input.school);
    }

    if (input.dateOnSale) {
      this.dateOnSale = moment(input.dateOnSale).toDate();
    }

    if (input.dateOffSale) {
      this.dateOffSale = moment(input.dateOffSale).toDate();
    }

    if (input.prices) {
      this.prices = _.orderBy(input.prices, ['ticketType.rank', 'priceAmount'])
        .map((price: any) => {
          const ticketPrice = new TicketPrice().deserialize(price);
          ticketPrice.overallAvailable = input.availableCount;
          ticketPrice.passTicket = true;
          return ticketPrice;
        });
    }
    if (input.consumers) {
      this.consumers = input.consumers.map((pass: any) => new ConsumerGatePass().deserialize(pass));
    }

    if (input.sponsor) {
      this.sponsor = new Sponsor().deserialize(input.sponsor);
    }

    // // we don't currenty have pass sponsors, show the default sponsor
    // this.sponsor = Sponsor.default();

    return this;

  }

  public getGATicketPrices(): TicketPrice[] {
    return this.prices.filter((price) => !price.isReservedPrice());
  }

  public getRESVTicketPrices(): TicketPrice[] {
    return this.prices.filter((price) => price.isReservedPrice());
  }

}
