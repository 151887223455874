import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '@app/core';
import { TicketsComponent } from './tickets.component';
import { TransferListingComponent } from '@app/tickets/transfers/listing.component';
import { TicketsListingComponent } from '@app/tickets/ticket/listing.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: '', component: TicketsComponent, canActivate: [AuthenticationGuard], data: { title: 'Tickets & Passes - Ticket Spicket' },
    children: [
      { path: '', component: TicketsListingComponent, data: { showSpinner: true, title: 'Tickets - Ticket Spicket' }},
      { path: 'transfers', component: TransferListingComponent, data: { title: 'Transfers - Ticket Spicket' }}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class TicketsRoutingModule { }
