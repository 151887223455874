import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Logger } from '../logger.service';
import { AuthenticationService } from './authentication.service';
import { environment } from '@env/environment';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor (
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }
    log.debug('Not authenticated, redirecting...');
    if (route.data?.['checkout']) {
      return true;
    }

    window.location.href = `${ environment.eventsUrl }/sign-in`;
    return false;
  }

}
