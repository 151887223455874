import { ReservedPricing } from './pricing.model';
import { environment } from '@env/environment.prod';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TicketPrice } from '../ticket-price.model';
import { IDeserializable } from '../deserializable.interface';

export class ReservedHoldToken implements IDeserializable {

  public holdToken: string = null;
  public expiresAt: string = null;
  public expiresInSeconds = 0;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  public isExpired(): boolean {
    if (this.holdToken !== null) {
      return moment().isAfter(moment(this.expiresAt));
    } else {
      return true;
    }
  }

}

export class ReservedConfiguration {

  public key: string;
  public holdOnSelect = true;
  public showLegend = true;
  public holdToken: string;
  public maxSelectedObjects: number = null;
  public numberOfPlacesToSelect: number = null;

  public eventKey: string;
  public seasonEventKeys: Array<string> = new Array<string>();

  public objectWithoutPricingSelectable = false;
  public objectWithoutCategorySelectable = false;

  public pricing: ReservedPricing[] = new Array<ReservedPricing>();
  public unavailableCategories: string[] = new Array<string>();
  public availableCategories: string[] = new Array<string>();

  constructor (holdToken: string) {
    this.key = environment.seatsio.publicKey;
    this.holdToken = holdToken;
  }

  // RJH - adjusted the pricing object so that it returns the simple category/price when there is only 1 price point
  public getPricing(): any[] {

    const prices = _.chain(this.pricing)
      .groupBy((value) => value.category.key)
      .filter((value) => value[0].category.key !== null && value[0].category.key !== undefined)
      .map((value) => {
        const ticketTypes = value.map((pricing) => ({
          ticketType: pricing.price.displayName,
          price: pricing.price.priceAmount
        }));

        if (ticketTypes.length > 1) {
          return {
            category: value[0].category.key,
            ticketTypes: ticketTypes
          };
        } else {
          return {
            category: value[0].category.key,
            price: ticketTypes[0].price
          };
        }

      })
      .value();

    return prices;

  }

  public getTicketPriceByCategoryKey(key: number, ticketType: any): TicketPrice {
    const tp: ReservedPricing = this.pricing.find((price) => price.category.key === key.toString() && (ticketType === undefined || price.price.displayName === ticketType));
    return tp.price;
  }

  public getTicketPriceByCategoryKey1(key: any): TicketPrice {
    const tp: ReservedPricing = this.pricing.find((price) => price.category.key === key);
    return tp.price;
  }

  public addUnavailableCategory(category: string) {
    this.unavailableCategories.push(category);
  }

  public setEvent (key: string) {
    this.eventKey = key;
  }

  public setSeasonEvent (key: string) {
    if (this.seasonEventKeys.findIndex((event) => event === key) < 0) {
      this.seasonEventKeys.push(key);
    }
  }

  public setSeasonEvents (keys: Array<string>) {
    keys.map((key) => this.setSeasonEvent(key));
  }

  public isSeason(): boolean {
    return this.seasonEventKeys.length > 0;
  }

}
