import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigureDropinService } from './configure-dropin.service';
import { CartService } from '@app/data/services/cart.service';
import { environment } from '@env/environment';
import { MobileService } from '@app/data/services/mobile.service';
import { SentryTransaction } from '@app/core/sentry.util';
import { LOADER_IMAGE } from './braintree.constants';

declare let braintree: any;

@Component({
  selector: 'ngx-braintree',
  templateUrl: './ngx-braintree.component.html',
  styleUrls: ['./ngx-braintree.component.scss']
})
export class NgxBraintreeComponent implements OnInit {
  @Output() paymentStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() payButtonStatus: EventEmitter<any> = new EventEmitter<any>();

  @Input() clientTokenURL: string;
  @Input() createPurchaseURL: string;
  @Input() chargeAmount: number;

  // Optional inputs
  @Input() buttonText = 'Buy'; // to configure the pay button text
  @Input() allowChoose = false;
  @Input() showCardholderName = false;
  @Input() enablePaypalCheckout = false;
  @Input() enablePaypalVault = false;
  @Input() currency: string;
  @Input() locale: string;
  @Input() enabledStyle: any;
  @Input() disabledStyle: any;
  @Input() hideLoader = false;

  clientToken: string;
  nonce: string;
  showDropinUI = true;
  errorMessage: string;

  showPayButton = false; // to display the pay button only after the dropin UI has rendered.
  clientTokenNotReceived = false; // to show the error, "Error! Client token not received."
  interval: any;
  instance: any;
  dropinConfig: any = {};
  enablePayButton = false;
  showLoader = true;
  loaderImage = LOADER_IMAGE;

  constructor(
    private service: CartService,
    private configureDropinService: ConfigureDropinService,
    private changeDetectorRef: ChangeDetectorRef,
    private mobileService: MobileService
  ) { }

  ngOnInit() {
    if (this.enablePaypalCheckout && this.enablePaypalVault) {
      this.errorMessage = 'Please make sure either Paypal Checkout or Paypal Vault is set to true. Both cannot be true at the same time.';
    } else if (this.enablePaypalCheckout && !this.currency) { // user should provide currency for paypal checkout.
      this.errorMessage = 'Please provide the currency for Paypal Checkout. ex: [currency]="\'USD\'"';
    } else {
      this.generateDropInUI();
    }
  }

  generateDropInUI() {
    this.service.getClientToken()
      .subscribe({
        next: (clientToken: string) => {
          if (!clientToken) {
            this.clientTokenNotReceived = true;
            this.showLoader = false;
          } else {
            this.clientToken = clientToken;
            this.clientTokenNotReceived = false;
            this.interval = setInterval(() => {
              this.createDropin();
            }, 0);
          }
        },
        error: () => {
          this.clientTokenNotReceived = true;
          this.showLoader = false;
          console.error('Client token not received. Please make sure your braintree server api is configured properly, running and accessible.');
        }
      });
  }

  createDropin() {
    if (typeof braintree !== 'undefined') {
      this.dropinConfig.authorization = this.clientToken;
      this.dropinConfig.container = '#dropin-container';

      if (this.showCardholderName) {
        this.configureDropinService.configureCardHolderName(this.dropinConfig);
      }
      if (this.enablePaypalCheckout) {
        this.configureDropinService.configurePaypalCheckout(this.dropinConfig, this.chargeAmount, this.currency);
      }
      if (this.enablePaypalVault) {
        this.configureDropinService.configurePaypalVault(this.dropinConfig);
      }
      if (this.locale) {
        this.configureDropinService.configureLocale(this.dropinConfig, this.locale);
      }

      if (!this.mobileService.isCapacitor) {
        this.dropinConfig.googlePay = {
          googlePayVersion: 2,
          environment: environment.production ? 'PRODUCTION' : 'TEST',
          merchantId: environment.googlePayMerchantId,
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPrice: this.chargeAmount.toFixed(2).toString(),
            currencyCode: 'USD'
          },
          allowedPaymentMethods: [{
            type: 'CARD',
            parameters: {
              // recommend collecting and passing billing address information with
              // all Google Pay transactions as a best practice.
              billingAddressRequired: true,
              billingAddressParameters: {
                format: 'FULL'
              }
            }
          }]
        };
        this.dropinConfig.applePay = {
          displayName: 'TicketSpicket.com',
          paymentRequest: {
            total: {
              label: 'TicketSpicket.com',
              amount: this.chargeAmount.toFixed(2).toString()
            },
            // recommend collecting billing address information, at minimum
            // billing postal code, and passing that billing postal code with all
            // Apple Pay transactions as a best practice.
            requiredBillingContactFields: ['postalAddress']
          }
        };
      }

      braintree.dropin.create(this.dropinConfig, (createErr: any, instance: any) => {
        if (createErr) {
          console.error(createErr.message);
          this.showLoader = false;
          return;
        }
        this.showPayButton = true;
        this.showLoader = false;
        this.instance = instance;
        if (this.instance.isPaymentMethodRequestable()) {
          this.enablePayButton = true;
          this.payButtonStatus.emit(this.enablePayButton);
        }
        this.instance.on('paymentMethodRequestable', (event: any) => {
          this.enablePayButton = true;
          this.payButtonStatus.emit(this.enablePayButton);
          this.changeDetectorRef.detectChanges();

          SentryTransaction('BrainTree', 'Payment Method Requestable', event);

        });
        this.instance.on('noPaymentMethodRequestable', (event: any) => {
          this.enablePayButton = false;
          this.payButtonStatus.emit(this.enablePayButton);
          this.changeDetectorRef.detectChanges();
          SentryTransaction('BrainTree', 'No Payment Method Requestable', event);
        });
      });
      clearInterval(this.interval);

      this.instance?.teardown((error) => {
        if (error) {
          console.error('An error occurred during teardown: ', error);
        }
      });
    }
  }

  pay(phoneNumber?: string, email?: string, firstName?: string, lastName?: string): void {

    this.enablePayButton = false;
    this.payButtonStatus.emit(this.enablePayButton);

    if (this.instance) {
      this.instance.requestPaymentMethod((err: string, payload: any) => {
        if (err) {
          console.error(err);
          this.errorMessage = err;
          this.enablePayButton = true;
          this.payButtonStatus.emit(this.enablePayButton);
          return;
        } else {
          this.errorMessage = null;
        }
        if (!this.allowChoose) {
          // process immediately after tokenization
          this.showDropinUI = false;
          this.showLoader = true;
          payload.phoneNumber = phoneNumber;
          payload.email = email;
          payload.firstName = firstName;
          payload.lastName = lastName;
          this.confirmPay(payload);
        } else if (this.instance.isPaymentMethodRequestable()) {
          this.service.nonce = payload.nonce;
          this.showDropinUI = false;
          this.showLoader = true;
          payload.phoneNumber = phoneNumber;
          payload.email = email;
          payload.firstName = firstName;
          payload.lastName = lastName;
          this.confirmPay(payload);
        }
      });
    }
  }

  confirmPay(paymentMethod: any): void {

    this.showDropinUI = false;
    this.enablePayButton = false;
    this.payButtonStatus.emit(this.enablePayButton);

    this.service.checkout(paymentMethod).subscribe(
      (status: any) => {
        this.paymentStatus.emit(status);
        this.showLoader = false;
      },
      (error) => {
        if (error.error?.status === 500) {
          this.errorMessage = "Error processing payment. Please wait a few minutes and try again.";
        } else {
          this.errorMessage = "Error processing payment. Please check your payment information and try again.";
        }
        this.showDropinUI = true;
        this.enablePayButton = true;
        this.payButtonStatus.emit(this.enablePayButton);
        this.generateDropInUI();
      }
    );
  }
}
