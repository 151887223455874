import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '@app/core';
import { HomeComponent } from './home.component';
import { AgencyGenericComponent } from '@app/agency/generic.component';
import { TicketRenewalComponent } from '@app/home/renewal/ticket-renewal.component';
import { SharedTicketsComponent } from '@app/home/shared/shared-tickets.component';
import { InvalidBundleComponent } from '@app/home/shared/invalid-bundle.component';
import { EventCompleteComponent } from '@app/home/shared/event-complete.component';
import { SharedComponent } from './shared/shared.component';
import { RedirectGuard } from '../app-redirect-guard.module';
import { environment } from '@env/environment';

const routes: Routes = [
  Route.withShell([
    {
      path: '',
      canActivate: [RedirectGuard],
      component: RedirectGuard,
      pathMatch: 'full',
      data: {
        externalUrl: `${ environment.eventsUrl }`
      }
    },
    {
      path: 'home',
      canActivate: [RedirectGuard],
      component: RedirectGuard,
      data: {
        externalUrl: `${ environment.eventsUrl }`
      }
    },
    { path: 'group/:nces-id', component: AgencyGenericComponent },
    { path: 'renewal', component: TicketRenewalComponent, data: { title: 'Ticket Renewals - Ticket Spicket' }},
    { path: 'shared', component: SharedComponent, data: { title: 'Shared Tickets' }, children: [
      { path: 'invalid', component: InvalidBundleComponent, data: { title: '404 - Invalid Link - Ticket Spicket' }},
      { path: 'complete', component: EventCompleteComponent, data: { title: 'Event Complete - Ticket Spicket' }},
      { path: ':share-uuid', component: SharedTicketsComponent, data: { title: 'Shared Tickets - Ticket Spicket' }},
    ]},

  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule { }
