import { Component, OnInit, AfterContentChecked, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Agency } from '@app/data/models/agency.model';
import { AgencyService } from '@app/data/services/agency.service';
import { Event } from '@app/data/models/event.model';
import { CartService } from '@app/data/services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpModalComponent } from '@app/agency/help/help-modal.component';
import { TicketSelectionModalComponent } from '@app/agency/events/select-tickets.component';
import { ReservedTicketSelectionModalComponent } from '@app/agency/events/select-res-tickets.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MobileService } from '@app/data/services/mobile.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { UserService } from '@app/data/services/user.service';
import { UserAdmissionService } from '@app/data/services/user-admission.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TicketBottomSheet } from '@app/shared/ticket-spicket/bottom-sheet/ticket-bottom-sheet/ticket-bottom-sheet.component';
import { SpinnerService } from '@app/shared/ticket-spicket/spinner.service';
import { EventRegistrationModalComponent } from './show-registration.component';

@Component({
  selector: 'app-agency-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class AgencyEventComponent implements OnInit, AfterContentChecked {
  public eventId: string;
  public agency: Agency;
  public event: Event;
  public labelString = '';
  @ViewChild('sponsorImage', { static: false }) sponsorImage: ElementRef;

  constructor(
    private _route: ActivatedRoute,
    public _agencyService: AgencyService,
    private _userService: UserService,
    public _cart: CartService,
    private _sanitizer: DomSanitizer,
    private _userAdmissionService: UserAdmissionService,
    private bottomSheet: MatBottomSheet,
    public _modal: NgbModal,
    public mobileService: MobileService,
    public angulartics2: Angulartics2GoogleAnalytics,
    private _spinner: SpinnerService
  ) {
    this._spinner.setMessage('Loading Event Details');
  }

  ngOnInit() {
    this.agency = this._agencyService.agency;
    this._route.params
      .pipe(
        map(params => params['event-id']),
        switchMap((uuid: string) => this._agencyService.getEvent(this.agency.uuid, uuid))
      )
      .subscribe(event => {
        this.event = event;
        // Track Event Ad Load - ELH
        if (this.event.sponsor.uuid) {
          this.labelString = this.event.sponsor.uuid + ' - ' + 'Ad ID' + ' - ' + this.event.uuid;
        } else {
          this.labelString = 'Default Ticket Spicket Ad';
        }
        this.trackEvent('Load');
        this._spinner.hide();
      });
  }

  ngAfterContentChecked() {
  }

  // Track Event Ad Click - ELH
  public sponsorLinkClicked(url: string) {
    this.trackEvent('Click');
    if (this.mobileService.isCapacitor) {
      this.mobileService.openURL();
    } else {
      window.open(url, '_blank');
    }
  }

  public trackEvent(type: string) {
    this.angulartics2.eventTrack(type, {
      category: 'Digital Ad - Pass Placement 1',
      label: this.labelString,
      value: 1
    });
  }

  public isHomeEvent(): boolean {
    return this.event.isHome(this.agency);
  }

  public homeIndicator(): string {
    return this.isHomeEvent() ? 'H' : 'A';
  }

  public getLogo(): string {
    let logo = this.event.agency.settings?.logoURI;

    if (this.event.type.code === Event.type_head) {
      if (this.isHomeEvent()) {
        logo = this.event.opponent.settings?.logoURI;
      }
    }

    return logo;
  }

  public getDescription() {
    return this._sanitizer.bypassSecurityTrustHtml(this.event.description);
  }

  /**
   * Returns true if the user already has tickets to this event
   */
  public hasTickets() {
    if (this._userService.user.uuid) {
      return this._userAdmissionService.hasEventTickets(this.event);
    } else {
      return false;
    }
  }

  /**
   * returns true if the user has an pass that is eligible for this event
   */
  public hasPasses() {
    if (this._userService.user.uuid) {
      return this._userAdmissionService.hasEventPasses(this.event);
    } else {
      return false;
    }
  }

  /**
   * Opens the bottom sheet with a list of options for the user admission object
   */
  public showTicketActions() {

    const bottomSheetRef = this.bottomSheet.open(TicketBottomSheet, {
      data: this._userAdmissionService.getEventAdmission(this.event),
    });
    bottomSheetRef.afterDismissed().subscribe(() => {
    });

  }

  public buyTickets() {
    if (this.event.isRegistrationEvent()) {
      this.openRegistration();
    } else {
      const modalRef = this._modal.open(TicketSelectionModalComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.event = this.event;
    }

  }

  public openRegistration() {
    const modalRef = this._modal.open(EventRegistrationModalComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.event = this.event;
  }

  public buyReservedTickets() {
    const modalRef = this._modal.open(ReservedTicketSelectionModalComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.ticketPrices = this.event.getRESVTicketPrices();
  }

  public showHelp(allowSelfCheckin: boolean) {
    const modalRef = this._modal.open(HelpModalComponent);
    modalRef.componentInstance.digitalOnly = allowSelfCheckin;
  }

  public hasCartItem(): boolean {
    return this._cart.hasItem(this.event);
  }

  getCoverArtStyle() {
    if (this.event.coverArtURI) {
      const innerHTML = `<style>
                    .view.coverArt.custom {
                        background: #3A3A3A !important;
                    }

                    .view.coverArt.custom .customCoverArtUnderlay,
                    .view.coverArt.custom .customCoverArt {
                        background-image:url('${this.event.coverArtURI}') !important;
                        background-position:center center;
                        background-size:cover;
                    }
                </style>`;

      return this._sanitizer.bypassSecurityTrustHtml(innerHTML);
    }

    return '';
  }

}
