import { Component, OnInit } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { AgencyService } from '@app/data/services/agency.service';
import { CartService } from '@app/data/services/cart.service';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
import { Agency } from '@app/data/models/agency.model';
import { RenewalTicketHolder } from '@app/data/models/renewal/ticket-holder.model';
import { RenewalTicketHolderSeat } from '@app/data/models/renewal/ticket-holder-seat.model';
import * as _ from 'lodash';
import { ReservedSeat } from '@app/data/models/reserved/seat.model';
import { TicketPrice } from '@app/data/models/ticket-price.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RenewalService } from '@app/data/services/renewal.service';

@Component({
  selector: 'app-agency-season-ticket-renewal',
  templateUrl: './season-ticket-renewal.component.html',
  styleUrls: ['./season-ticket-renewal.component.scss']
})
export class AgencySeasonTicketRenewalComponent implements OnInit {

  public isLoading = true;
  public gatePassUUID: string;
  public accessKey: string;

  public pass: GatePass = new GatePass();
  public product: GatePass = new GatePass();

  public agency: Agency = new Agency();

  public ticketHolder: RenewalTicketHolder = new RenewalTicketHolder();

  public selectedSeats: RenewalTicketHolderSeat[] = new Array<RenewalTicketHolderSeat>();

  public formChannel: FormGroup;
  public error: any;

  constructor (
    private route: ActivatedRoute,
    public cart: CartService,
    public _agencyService: AgencyService,
    public _renewalService: RenewalService,
    private _router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.formChannel = this.formBuilder.group({
      accessKey: ['', Validators.required]
    });
  }

  ngOnInit() {

    this.agency = this._agencyService.agency;
    this.accessKey = null;

    this.route.params.pipe (
      map((params) => this.gatePassUUID = params['pass-id']),
      switchMap(() => this._agencyService.getSeasonTicket(this.gatePassUUID)),
    ).subscribe((pass) => {
      this.pass = pass;
      this.product = pass;

      this.route.queryParams.pipe (
        map((params) => this.accessKey = params['key']),
      ).subscribe((accessKey) => {
        this.ticketHolder = new RenewalTicketHolder();
        if (!_.isEmpty(accessKey)) {
          this.formChannel.get('accessKey').setValue(accessKey);
          if (this.accessKey !== null) {
            this._getTicketHolder();
          }
        }
      });

    }, (error) => {
      console.error(error);
    });

    this.isLoading = false;


  }

  private _getTicketHolder() {

    this._renewalService.findTicketHolder(this.pass.uuid, this.accessKey).subscribe(
      (ticketHolder) =>  this.ticketHolder = ticketHolder,
      (error) => {
        this.error = error.error;
      }
    );

  }

  public findTicketHolder() {

    this.isLoading = true;
    this.accessKey = this.formChannel.get('accessKey').value;

    try {
      this._router.navigate([], {
        queryParams: { key: this.accessKey },
        skipLocationChange: false,
        queryParamsHandling: 'merge' // === if you need to keep queryParams
      });
    } catch (error) {
      this.error = error;
    }

    this.isLoading = false;


  }


  public getAvailableSeats() {
    return this.ticketHolder.seats.filter((seat) => seat.consumerPaymentId === null);
  }

  public getPurchasedSeats() {
    return this.ticketHolder.seats.filter((seat) => seat.consumerPaymentId != null);
  }


  /**
   * returns the index number for the given ticket in the selectedTickets collection
   *
   */
  private _findSeat(seat: RenewalTicketHolderSeat): number {
    return this.selectedSeats.findIndex((selectedSeat) => selectedSeat.seatKey === seat.seatKey);
  }

  public isSelected(seat: RenewalTicketHolderSeat): boolean {
    return this._findSeat(seat) >= 0;
  }

  public toggleSeat(seat: RenewalTicketHolderSeat) {

    // first, check to see if the tickets are in the selected collection
    const index = this._findSeat(seat);

    if (index >= 0) {
      // if the ticket was found, remove it
      this.selectedSeats.splice(index, 1);
    } else {
      this.selectedSeats.push(seat);
    }

  }

  public getTotalAmount(): number {
    return _.sumBy(this.selectedSeats, 'amountPrice');
  }

  public getTicketPriceByPrice(price: number): TicketPrice {
    const tp: TicketPrice = this.pass.prices.find((ticketPrice) => ticketPrice.priceAmount === price);
    return tp;
  }

  public checkout() {
    this.selectedSeats.map((seat) => {
      this.cart.addSeatRenewal (
        this.pass,
        new ReservedSeat().deserialize(seat.seatKey),
        this.getTicketPriceByPrice(seat.amountPrice),
        null
      );
    });
    this._router.navigate([this.cart.cartRoute]);
  }

  // public removeSeat(seat: ReservedSeat) {
  //     this.cart.removeReservedSeat(this.pass, seat);
  //     this.removeCartSeat(seat);
  // }

  // public removeCartSeat(seat: ReservedSeat) {
  //     let seats: Array<string> = new Array<string>();
  //     seats.push(seat.key);
  //     this.chart.deselectObjects(seats);
  // }

}

