<div class="card text-left border-0" style="overflow:hidden;" [ngStyle]="{'background-color': agency.settings?.secondaryColor}">
    <ts-agency-cover [agency]="agency" [for-card]="true">
    </ts-agency-cover>

    <a *ngIf="agency.settings?.logoURI" class="btn-floating btn-action logo" mdbRippleRadius [ngStyle]="{'background-image': 'url(' + agency.settings?.logoURI + ')'}">
    </a>
    <a *ngIf="!agency.settings?.logoURI" class="btn-floating btn-action logo default z-depth-1 rounded-circle text-center" mdbRippleRadius [ngStyle]="{'background-color': agency.settings?.primaryColor, 'color': agency.settings?.primaryText}">
        {{ agency.name | limitTo: 1 }}
    </a>
    <div class="clearfix"></div>
    <div class="card-body px-2 pt-2" [ngStyle]="{'color': agency.settings?.secondaryText}">
        <div class="name">{{ agency.name }}</div>
        <div class="cardfooter pb-2 location">
            <i class="fa fa-map-marker mr-2 ml-1" aria-hidden="true" *ngIf="agency.primaryAddress"></i><small>{{ agency.primaryAddress?.cityState }} {{ agency.primaryAddress?.zipcode }}</small>
            <div class="float-right" mdbRippleRadius>
                <div class="d-inline-block mr-2">Select</div><i class="fa fa-arrow-right"></i>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
