import { Component, Input } from '@angular/core';
import { GatePass } from '@app/data/models/passes/gate-pass.model';
@Component({
  selector: 'ts-pass-header-list-item',
  templateUrl: './pass-header-list-item.component.html',
  styleUrls: ['./pass-header-list-item.component.scss']
})

export class PassHeaderListItemComponent {
  @Input() pass: GatePass;
  @Input() showIcon: boolean;
  @Input() showPrices: boolean;

  constructor() {}

  public getIcon(): string {

    const icon = 'allAccess';

    // TODO: Not doing anything currently
    // switch (this.pass.gatePassType.value) {
    //     case GatePassTypes.access: {
    //         'allAccess';
    //         break;
    //     }
    //     case 'Season': {
    //         this.pass.activity.typeSeason.iconClassName;
    //         break;
    //     }
    //     case 'Activity': {
    //         this.pass.activity.typeActivity.iconClassName;
    //         break;
    //     }
    //     default:
    //         'allAccess';
    // }

    return icon;
  }

}

