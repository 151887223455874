import { Component } from '@angular/core';

@Component({
  selector: 'ts-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent {
  constructor() {}
}
