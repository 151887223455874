<div *ngIf="ticket tappable">
    <div class="border-0">
        <div class="px-3" style="position:relative;min-height:200px;">
            <div class="row my-4 align-items-center">
                <div class="col-6 pr-0">
                    <div *ngIf="!_mobileService.isCapacitor" class="sponsorLogo my-4">
                        <a *ngIf="!ticket.event.sponsor?.id" class="d-block text-center" target="_blank" href="http://www.ticketspicket.com">
                            <img [src]="ticket.event.sponsor?.logoURI" alt="{{ ticket.event.sponsor?.name }}" width="100%" style="margin:0 auto;">
                        </a>
                        <a *ngIf="ticket.event.sponsor?.id" class="d-block text-center" target="_blank" href="{{ ticket.event.sponsor?.website }}">
                            <img [src]="ticket.event.sponsor?.logoURI" alt="{{ ticket.event.sponsor?.name }}" width="100%" style="margin:0 auto;">
                        </a>
                    </div>
                    <div *ngIf="_mobileService.isCapacitor" class="sponsorLogo my-4">
                        <a *ngIf="!ticket.event.sponsor?.id" class="d-block text-center" (click)="_mobileService.openURL('http://www.ticketspicket.com')">
                            <img [src]="ticket.event.sponsor?.logoURI" alt="{{ ticket.event.sponsor?.name }}" width="100%" style="margin:0 auto;">
                        </a>
                        <a *ngIf="ticket.event.sponsor?.id" class="d-block text-center" (click)="_mobileService.openURL(ticket.event.sponsor?.website)">
                            <img [src]="ticket.event.sponsor?.logoURI" alt="{{ ticket.event.sponsor?.name }}" width="100%" style="margin:0 auto;">
                        </a>
                    </div>
                </div>
                <div [hidden]="ticket.event.allowSelfCheckin" class="col-6 text-center qrcode">
                    <qrcode [qrdata]="ticket.qrCode" [size]="100" [level]="'M'"></qrcode>
                </div>
                <div class="col-6" [hidden]="!ticket.event.allowSelfCheckin" >
                    <div class="manual-check-in text-center d-inline-block mx-auto" [ngClass]="{ 'check-in-complete':ticket.dateRedeemed, 'checking-in': checkingin }">
                        <div class="mb-2" *ngIf="!gatesOpen()">
                            <div class="check-in-message text-uppercase">
                                <strong>GATES ARE NOT<br />CURRENTLY OPEN</strong>
                            </div>
                            <button type="button" class="check-in-button btn btn-dark rounded-circle text-uppercase my-1" style="height:100px;width:100px;" [disabled]="!gatesOpen()">
                                <i class="fa fa-ban fa-3x"></i><br />
                            </button>
                            <div class="text-uppercase text-center font-sm">
                                <strong>Gates Open: {{ ticket.event.dateGates | date: 'h:mm a' }}</strong>
                            </div>
                        </div>
                        <div class="mb-2" *ngIf="gatesOpen()">
                            <div class="check-in-message text-uppercase">
                                <strong>TAP &amp; HOLD</strong>
                            </div>
                            <div class="checking-in-message text-uppercase">
                                <span *ngIf="!validating"><strong>KEEP HOLDING!</strong></span>
                                <span *ngIf="validating"><strong>VALIDATING...</strong></span>
                            </div>
                            <div class="checked-in-message text-uppercase">
                                <strong>YOU'RE ALL SET!</strong>
                            </div>
                            <button type="button" class="check-in-button btn btn-success rounded-circle text-uppercase" style="height:100px;width:100px;" [disabled]="!gatesOpen() || errorMessage" *ngIf="gatesOpen() && _mobileService.isCapacitor" (touchstart)="startCheckingIn(ticket)" (touchend)="stopCheckingIn(ticket)">
                                <span class="button-message"><i class="fa fa-hand-o-up"></i><br />CHECK IN</span>
                                <span class="button-message-hold"><i class="fa fa-2x fa-circle-o-notch fa-spin"></i></span>
                            </button>
                            <button type="button" class="check-in-button btn btn-success rounded-circle text-uppercase" style="height:100px;width:100px;" [disabled]="!gatesOpen() || errorMessage" *ngIf="gatesOpen() && !_mobileService.isCapacitor" (touchstart)="startCheckingIn(ticket, 't')" (touchend)="stopCheckingIn(ticket, 't')" (press)="startCheckingIn(ticket, 'p')" (pressup)="stopCheckingIn(ticket, 'p')" (mousedown)="startCheckingIn(ticket, 'p')" (mouseup)="stopCheckingIn(ticket, 'p')">
                                <span class="button-message"><i class="fa fa-hand-o-up"></i><br />CHECK IN</span>
                                <span class="button-message-hold"><i class="fa fa-2x fa-circle-o-notch fa-spin"></i></span>
                            </button>
                        </div>
                        <div class="checked-in mx-auto">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger text-uppercase text-center font-weight-bold" *ngIf="errorMessage">
                <i class="fa fa-ban mr-2"></i>{{errorMessage}}
            </div>
            <div *ngIf="!ticket.dateRedeemed">
                Total Number Checking In: <strong>{{ totalCheckingIn }}</strong>
            </div>
            <div *ngIf="ticket.dateRedeemed" class="checked-in">
                Checked in: {{ ticket.dateRedeemed | date: 'MM/dd/yyyy @ h:mm a'}}
            </div>
        </div>
    </div>
    <div style="background-color:#3a3a3a;">
        <div class="stub-top" [ngClass]="{ 'mb-3':ticket.dateRedeemed }"></div>
        <div class="stub-bottom mb-3" [ngClass]="{ 'redeemed': ticket.dateRedeemed }"></div>
    </div>
    <div class="border-0">
        <div class="p-2 text-center">
            <h5 class="mt-2 mb-0 text-uppercase">Ticket Holder</h5>
            <div class="text-muted">
                {{ user.nameFirst }} {{ user.nameLast }}
            </div>
        </div>
        <div class="p-3 text-center">
            <div *ngIf="ticket.levels.length > 0">
                <h5 class="mt-2 mb-0 text-uppercase">General Admission Tickets</h5>
                <div class="p-2">
                    <div class="seat-selection border rounded bg-white" *ngFor="let level of ticket.levels">
                        <div class="seat-icon mr-2 rounded" [ngStyle]="{'background-color': ticket.event.agency.settings?.primaryColor}">
                            GA
                        </div>
                        <div class="details">
                            <div class="float-right text-right">
                                x  <strong>{{level.count}}</strong> 
                            </div>
                            <div class="float-left text-left">
                                <strong>Type:</strong>  {{ level.level.title }}
                            </div>
                            <div style="clear:both;"></div>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="ticket.seats.length > 0">
                <h5 class="mt-2 mb-0 text-uppercase">Reserved Seats Tickets</h5>
                <div class="p-2 text-left">
                    <div class="seat-selection border rounded bg-white" *ngFor="let seat of ticket.seats">
                        <div class="seat-icon mr-2 rounded" [ngStyle]="{'background-color': ticket.event.agency.settings?.primaryColor}">
                            {{seat.section}}
                        </div>
                        <div class="details">
                            <div class="float-right text-right">
                                <strong>Seat:</strong> {{seat.number}}
                            </div>
                            <div class="float-left text-left">
                                <strong>Row:</strong> {{seat.row}}
                            </div>
                            <div style="clear:both;"></div>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
