<header>
  <nav class="navbar navbar-expand-md navbar-dark text-uppercase mb-0" style="background-color:#00488E">
    <div class="header-margins">
      <div class="agencyLogo pointer d-none d-lg-inline-block float-left" (click)="goToAgency()" *ngIf="_agencyService.agency.settings?.logoURI"></div>
      <div class="agencyLogo pointer logo default d-none d-lg-inline-block float-left" (click)="goToAgency()" *ngIf="isAgencyPage && !_agencyService.agency.settings?.logoURI">{{ _agencyService.agencyName | limitTo: 1 }}</div>
      <button class="navbar-toggler ml-auto float-right" type="button" aria-controls="navbar-menu"
              aria-label="Toggle navigation" (click)="toggleMenu()" [attr.aria-expanded]="!menuHidden">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
        <div class="spacer d-md-none d-lg-block" *ngIf="isAgencyPage" style="padding-left: 112px;">
        </div>
        <div class="navbar-nav ml-auto navbar-mobile-margin-right">
          <a class="nav-item nav-link" href="{{eventsUrl}}" routerLinkActive="active">
            <i class="fa fa-search mr-1"></i>
            <span>Search</span>
          </a>
          <a class="nav-item nav-link" href="{{eventsUrl}}/tickets" routerLinkActive="active">
            <i class="fas fa-ticket-alt mr-1" data-fa-transform="rotate-45"></i>
            <span>My Tickets</span>
          </a>
          <a class="nav-item nav-link" href="{{eventsUrl}}/passes" routerLinkActive="active">
            <i class="fa fa-tag mr-1"></i>
            <span>My Passes</span>
          </a>
          <a class="nav-item nav-link nav-push-right" href="{{eventsUrl}}/sign-in?redirect_url={{redirectUrl}}" routerLinkActive="active" *ngIf="!(_clerkService.isAuthenticated$  | async)">
            <i class="fas fa-user mr-1"></i>
            <span>Log in / Sign Up</span>
          </a>
          <!-- Account Menu Dropdown -->
          <div class="nav-item nav-push-right" ngbDropdown placement="bottom-right" *ngIf="(_clerkService.isAuthenticated$ | async)" >
            <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
                <i class="fas fa-user mr-1"></i>
                My Account
            </a>
            <div ngbDropdownMenu aria-labelledby="user-dropdown" class="font-sm dropdown-container">
                <h6 class="dropdown-header" *ngIf="userEmail">
                    <b>{{ userEmail }}</b>
                </h6>
                <a class="dropdown-item" href="{{eventsUrl}}/account">
                    <i class="fas fa-user fa-fw mr-1"></i>
                    Manage Account
                </a>
                <a class="dropdown-item" href="{{eventsUrl}}/order-history">
                    <i class="fa fa-share-alt fa-fw mr-1"></i>
                    Order History
                </a>
                <a class="dropdown-item" href (click)="logout()">
                    <i class="fa fa-sign-out fa-fw mr-1"></i>
                    Log Out
                </a>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </nav>
</header>
