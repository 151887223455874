import { IDeserializable } from '@app/data/models/deserializable.interface';

export class Address implements IDeserializable {

  id?: number;
  name: string = null;
  line1: string = null;
  line2?: string = null;
  line3?: string = null;
  city: string = null;
  state: string = null;
  zipcode: string = null;
  cityState?: string;
  formatted?: string;
  lat?: number;
  lng?: number;
  isPrimary?: Boolean = false;
  version: string;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  get mapString() {
    return `https://www.google.com/maps/dir/?api=1&destination=${this._replaceAll(this.formatted, ' ', '+')}`;
  }

  private _escapeRegExp(str: string) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }

  private _replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(this._escapeRegExp(find), 'g'), replace);
  }


}
