import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '@app/core';
import { CartComponent } from './cart.component';
import { CheckoutComponent } from '@app/cart/checkout.component';
import { ConfirmationComponent } from '@app/cart/confirmation.component';
import { CartSummaryComponent } from '@app/cart/summary.component';

const routes: Routes = [
  { path: '', component: CartComponent, data: { title: 'Cart' },
    children: [
      { path: '', component: CartSummaryComponent, data: { title: 'Cart Summary - Ticket Spicket' }},
      {
        path: 'checkout', component: CheckoutComponent, canActivate: [AuthenticationGuard],
        data: {
          title: 'Checkout - Ticket Spicket',
          checkout: true
        }
      },
      {
        path: 'confirmation', component: ConfirmationComponent, canActivate: [AuthenticationGuard],
        data: {
          title: 'Confirmation - Ticket Spicket',
          checkout: true
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CartRoutingModule { }
