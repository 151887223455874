import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '@app/shared/ticket-spicket/message.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ConsumerGatePass } from '@app/data/models/passes/consumer-gate-pass.model';
import { PassService } from '@app/data/services/pass.service';

@Component({
  selector: 'app-pass-assign-modal',
  templateUrl: './pass-assign.component.html',
  styleUrls: ['./pass-assign.component.scss']
})
export class PassAssignModalComponent implements OnInit {

  @Input() consumer: ConsumerGatePass;
  public assignForm: FormGroup = new FormGroup({
    holderName: new FormControl('', Validators.required),
    remember: new FormControl(true)
  });

  constructor(
    public activeModal: NgbActiveModal,
    private _passService: PassService,
    private _messageService: MessageService
  ) {
  }
  isPassNameValid = true;
  checkPassHolderName() {
    const value = (this.assignForm.get('holderName').value).trim();
    this.isPassNameValid = Boolean(value.length);
  }
  ngOnInit() {
    this.assignForm.patchValue(this.consumer);
  }

  public close() {
    this.activeModal.dismiss(false);
  }

  assign() {
    this.consumer.holderName = (this.assignForm.get('holderName').value).trim();
    this._passService.configurePass(this.consumer).subscribe(
      () => {
        this._messageService.toast.success('The pass has been updated!', 'Success');
        this.activeModal.dismiss(false);
      },
      (error) => this._messageService.toast.error(error.message, 'Error!'),
      () => { }
    );
  }
}
