import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Event } from '@app/data/models/event.model';
import { AgencyService } from './agency.service';
import { EventStoreChannel } from '../models/events/event-store-channel.model';

@Injectable({
  providedIn: 'root'
})
export class AgencyEventService {

  constructor(
    private _http: HttpClient,
    private _agencyService: AgencyService
  ) { }

  public getEventBaseUrl(eventUUID: string): string {
    return `fans/agencies/${this._agencyService.agency.uuid}/events/${eventUUID}`;
  }

  /**
   * Returns the list of Events for a given Agency
   *
   * @param uuid : the UUID for a single Agency
   * @param eventUUID: the UUID for a given event
   * @returns Observable array of Event objects
   */
  getAgencyEvent(eventUUID: string): Observable<Event> {

    const url = this.getEventBaseUrl(eventUUID);

    return this._http.get<Event>(url).pipe(
      map((event) => new Event().deserialize(event))
    );

  }

  /**
   * Returns the list of Events for a given Agency
   *
   * @param uuid : the UUID for a single Agency
   * @param eventUUID: the UUID for a given event
   * @returns Observable array of Event objects
   */
  getAgencyEventChannel(eventUUID: string, channelCode: string): Observable<EventStoreChannel> {

    const url = `${this.getEventBaseUrl(eventUUID)}/channels/${channelCode}`;

    return this._http.get<EventStoreChannel>(url).pipe(
      map((channel) => new EventStoreChannel().deserialize(channel))
    );

  }

}
