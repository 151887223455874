import { ReservedSeat } from '../reserved/seat.model';
import { CartItem, CartItemSummary } from './cart-item.model';
import { GatePass } from '../passes/gate-pass.model';
import { CartItemTypes } from './cart-item-types.enum';
import { TicketPrice } from '../ticket-price.model';
import { ConsumerGatePassHolder } from '../passes/consumer-gate-pass.model';

export class CartItemPass extends CartItem {


  constructor (pass: GatePass, price: TicketPrice, selectedQty: number = 0, members: Array<ConsumerGatePassHolder> = new Array<ConsumerGatePassHolder>()) {

    super();

    this.itemType = CartItemTypes.pass;
    this.product = pass;
    // this.ticketType = type;
    this.ticketPrice = price;
    this.price = price.priceAmount;
    this.selectedQty = selectedQty;
    this.members = members;

  }

}

export class CartItemPassReserved extends CartItem {

  constructor (pass: GatePass, seat: ReservedSeat, price: TicketPrice) {

    super();

    this.itemType = CartItemTypes.pass;
    this.product = pass;
    this.seat = seat;
    this.ticketPrice = price;
    this.price = price.priceAmount;

  }

}

export class CartItemPassSummary extends CartItemSummary {

  public override product: GatePass;

  constructor(pass: GatePass, items: CartItemPass[]) {

    super(pass, items);

  }

}
