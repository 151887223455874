import { IDeserializable } from '@app/data/models/deserializable.interface';

export enum ReservedSeatObjectType {
  individualSeat = 'Seat',
  generalAdmission = 'GeneralAdmissionArea'
}

export class ReservedSeatCategory implements IDeserializable {

  public key: string;
  public label: string;
  public color: string;
  public accessible: string;

  constructor() {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

}

export class ReservedSeat implements IDeserializable {

  public id: string;
  public key: string;
  public labels: any;
  public section: string;
  public row: string;
  public number: string;
  public price: number;
  public category: ReservedSeatCategory = new ReservedSeatCategory();
  public objectType: ReservedSeatObjectType;

  constructor() {}

  deserialize(input: any) {

    this.objectType = input.objectType;

    // this is null when the seatnumber comes from the ticket
    if (this.objectType === null || this.objectType === undefined) {
      this.objectType = ReservedSeatObjectType.individualSeat;
      this.id = input;
      this.key = input;
      this.labels = input;
    } else {
      this.id = input.id;
      this.key = input.id;
      this.labels = input.id;
    }

    if (this.objectType === ReservedSeatObjectType.individualSeat) {

      const split = this.id.split('-');
      this.section  = split[0];
      this.row = split[1];
      this.number = split[2];
    }

    if (this.objectType === ReservedSeatObjectType.generalAdmission) {
      this.section = 'GA';
      this.row = null;
      this.number = input.id;
    }

    return this;

  }

  public serialize(): any {
    return {
      objectType: this.objectType,
      id: this.id,
      key: this.key
    };
  }

  // public serialize(): any {
  //     return {
  //         key: this.key
  //     }
  // }

}
